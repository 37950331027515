import { FC, useEffect } from "react";
import { Input } from "../../../../common/components/Input/Input";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { TariffFormValues } from "../../../../common/models/forms/TariffFormValues";
import { CustomSelect } from "../../../../common/components/Select/CustomSelect";
import { useGetVehicleCategoriesQuery } from "../../../../services/vehicle/vehiclesApi";
import { useGetCitiesQuery } from "../../../../services/appApi";
import { QueryLayout } from "../../../../common/components/Layout/QueryLayout";
import { SubTitle } from "../../../../common/components/Title/SubTitle";
import { InformationBlock } from "../../../../common/components/InformationBlock/InformationBlock";
import { List } from "../../../../common/components/List/List";
import { Switch } from "../../../../common/components/Checkbox/Switch";
import { IVehicleType } from "common/models/vehicles/IVehicleType";
import { VehicleTypeFormValues } from "common/models/forms/VehicleTypeFormValues";

export const TariffsFormInputs: FC = () => {
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isFetching: isCategoriesFetching,
    error: categoriesError,
  } = useGetVehicleCategoriesQuery();
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isFetching: isCitiesFetching,
    error: citiesError,
  } = useGetCitiesQuery();

  const {
    register,
    control,
    resetField,
    formState: { errors },
  } = useFormContext<VehicleTypeFormValues>();

  const vehicleCategory = useWatch({
    control,
    name: "vehicle_category_id",
  });

  // const currentOptions = useWatch({
  //   control,
  //   name: "options",
  // });

  // const {
  //   data: options,
  //   isLoading: isOptionsLoading,
  //   isFetching: isOptionsFetching,
  //   error: optionsError,
  // } = useGetVehicleOptionsQuery(+vehicleCategory, { skip: !vehicleCategory });

  useEffect(() => {
    resetField("options");
  }, [vehicleCategory]);

  const cityOptions = cities?.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const categoryOptions = categories?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  return (
    <QueryLayout
      isLoading={isCategoriesLoading || isCitiesLoading}
      isFetching={isCategoriesFetching || isCitiesFetching}
      error={categoriesError || citiesError}
    >
      <SubTitle>Основные данные</SubTitle>
      <Controller
        control={control}
        name="city_id"
        rules={{ required: true }}
        render={(props) => {
          return (
            <CustomSelect
              className="w-full"
              label="Город *"
              reference={props.field.ref}
              options={cityOptions}
              placeholder="Выберите город..."
              invalid={!!errors.city_id}
              value={cityOptions?.find((c) => c.value === props.field.value)}
              onChange={(val) => props.field.onChange(val?.value)}
              isSearchable={false}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="vehicle_category_id"
        rules={{ required: true }}
        render={(props) => {
          return (
            <CustomSelect
              className="w-full"
              label="Тип техники *"
              reference={props.field.ref}
              options={categoryOptions}
              placeholder="Выберите тип..."
              invalid={!!errors.vehicle_category_id}
              value={categoryOptions?.find(
                (c) => c.value === props.field.value
              )}
              onChange={(val) => {
                props.field.onChange(val?.value);
              }}
              isSearchable={false}
            />
          );
        }}
      />
      <Input
        {...register(`name`, { required: true })}
        className="w-full"
        label="Название тарифа *"
        invalid={!!errors.name}
        placeholder="Название"
      />
      <Input
        {...register(`working_shift_price`, { required: true })}
        className="w-full"
        label="Цена смены *"
        type="number"
        invalid={!!errors.working_shift_price}
        placeholder="Цена"
      />
      <Input
        {...register(`working_shift_hours`, { required: true })}
        className="w-full"
        label="Кол-во часов смены *"
        type="number"
        invalid={!!errors.working_shift_hours}
        placeholder="Часы"
      />
      <Input
        {...register(`vehicle_delivery_hours`, { required: true })}
        className="w-full"
        label="Кол-во часов подачи *"
        type="number"
        invalid={!!errors.vehicle_delivery_hours}
        placeholder="Часы"
      />
      <Input
        {...register(`vehicle_delivery_price`, { required: true })}
        className="w-full"
        label="Стоимость пробега вне радиуса охвата за 1 км (за радиус) *"
        type="number"
        invalid={!!errors.vehicle_delivery_price}
        placeholder="Стоимость"
      />
      <Input
        {...register(`working_shift_overtime_price`, { required: true })}
        className="w-full"
        label="Цена одного часа за переработку *"
        type="number"
        invalid={!!errors.working_shift_overtime_price}
        placeholder="Цена"
      />
      {/* <SubTitle>Дополнительное оборудование</SubTitle>
      <QueryLayout
        isLoading={isOptionsLoading}
        isFetching={isOptionsFetching}
        error={optionsError}
      >
        {options ? (
          <List className="mb-[30px] gap-[15px]">
            {options.map((option, i) => (
              <li key={option.id + vehicleCategory}>
                <label className="flex items-center justify-between text-sm font-semibold">
                  {option.name}
                  <Switch
                    {...register(`options.${i}`)}
                    value={+option.id}
                    checked={
                      !!currentOptions?.some((opt) => +opt === option.id)
                    }
                    className={errors?.options?.[i] ? "error" : ""}
                  />
                </label>
                {!!currentOptions?.some((opt) => +opt === option.id) && (
                  <div className="grid grid-cols-2 gap-[10px]">
                    <Input
                      {...register(`options.${i}.mileagePrice`, {
                        required: true,
                      })}
                      className="w-full"
                      label="Цена смены 1 часа *"
                      placeholder="Цена смены 1 часа"
                    />
                    <Input
                      {...register(`options.${i}.processingPrice`, {
                        required: true,
                      })}
                      className="w-full"
                      label="Цена переработки 1 часа *"
                      placeholder="Цена переработки 1 часа"
                    />
                  </div>
                )}
              </li>
            ))}
          </List>
        ) : (
          <InformationBlock>
            Для выбора дополнительного оборудования, необходимо выбрать тип
            техники
          </InformationBlock>
        )}
      </QueryLayout> */}
    </QueryLayout>
  );
};
