import { IListProps } from "common/models/components/ui/IListProps";
import { FC, memo } from "react";

export const List: FC<IListProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <>
      {props.label && (
        <h4 className="mb-[12px] text-md font-semibold">{props.label}</h4>
      )}
      <ul {...props} className={`grid gap-[15px] ${userClasses}`}>
        {props.children}
      </ul>
    </>
  );
});
