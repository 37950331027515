import { useSortBy, useTable, useExpanded } from "react-table";
import { Table } from "./Table";
import { ITableProps } from "../../models/table/ITableProps";

const CustomDropdownTable = <T extends object>({
  data,
  columns,
}: ITableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable<T>({ columns, data }, useSortBy, useExpanded);

  return (
    <table className="table text-gray" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="px-[10px] font-semibold text-black"
                key={column.id}
                style={{ width: `${100 / headerGroup.headers.length}%` }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              <td
                colSpan={visibleColumns.length}
                className="py-[5px]"
                style={{ width: `${100 / rows.length}%` }}
              >
                <Table isSubTable data={[row.original]} columns={columns} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const DropdownTable = CustomDropdownTable;
