import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";
import { IVehicleChassisMark } from "common/models/vehicles/IVehicleChassisMark";
import {
  useGetChassisMarksQuery,
  useDeleteChassisMarkMutation,
} from "services/vehicle/chassisApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const ChassisMarksTable: FC = () => {
  const {
    data: marks,
    isLoading,
    error,
    isFetching,
  } = useGetChassisMarksQuery();

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {marks?.length ? (
        <Table data={marks} columns={columns} isLoading={isFetching} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

const columns: Column<IVehicleChassisMark>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    id: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[10px]">Действия</span>
    ),
    Cell: ({ row }: any) => (
      <Actions id={row.original.id} onDelete={useDeleteChassisMarkMutation} />
    ),
  },
];
