import { FC } from "react";
import {
  getQueryDateParam,
  timeFormatter,
} from "../../../../../common/utils/helpers";
import { Column } from "react-table";
import { Table } from "../../../../../common/components/Table/Table";
import { useGetIndividualCustomersQuery } from "services/admin/adminUserApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { IIndividual } from "common/models/user/IIndividual";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const IndividualTable: FC<{
  query?: string;
  dateFrom?: string;
  dateTo?: string;
}> = ({ query, dateFrom, dateTo }) => {
  const {
    data: users,
    isLoading,
    isFetching,
    error,
  } = useGetIndividualCustomersQuery({
    query,
    date_from: getQueryDateParam(dateFrom),
    date_to: getQueryDateParam(dateTo),
  });

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {users?.length ? (
        <Table data={users} columns={columns} isLoading={isFetching} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

const columns: Column<IIndividual>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "ФИО",
    accessor: "first_name",
    Cell: ({ row }: any) => (
      <span>
        {`${row.original.last_name} ${row.values.first_name} ${
          row.original.middle_name ? row.original.middle_name : ""
        }`}
      </span>
    ),
  },
  {
    Header: "Дата регистрации",
    accessor: "created_at",
    Cell: ({ row }: any) => (
      <>
        {timeFormatter({
          time: new Date(row.values.created_at),
          mode: "dd-MM-yyyy",
        })}
      </>
    ),
  },
  {
    Header: "Завершённые заказы",
    accessor: "finished_orders_count",
  },
  {
    Header: "Сумма, руб.",
    accessor: "orders_sum",
  },
  {
    Header: "Приоритетная форма оплаты",
    accessor: "priority_payment",
  },
  {
    Header: "Приоритетная платформа",
    accessor: "priority_platform",
  },
  {
    Header: "Отменённые заказы",
    accessor: "canceled_orders_count",
  },
  {
    Header: "Споры",
    accessor: "disputes_count",
  },
  {
    Header: "Бонусы",
    accessor: "bonuses",
    Cell: ({ row }: any) => (
      <div className="grid font-semibold">
        <span className="text-primary">{`${row.values.bonuses} бонусов`}</span>
        <span>{`(${row.values.bonuses / 2} руб.)`}</span>
      </div>
    ),
  },
];
