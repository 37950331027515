import { IBank } from "./../common/models/commonModels/IBank";
import { ICompany } from "../common/models/companies/ICompany";
import { EditCompanyValues } from "../common/models/forms/EditCompanyValues";
import { api } from "./api";

const companiesApi = api
  .enhanceEndpoints({ addTagTypes: ["Company"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCompanyById: builder.query<ICompany, number>({
        query(id) {
          return {
            url: `/companies/${id}`,
          };
        },
        providesTags: (result) =>
          result ? [{ type: "Company", id: result.id }] : [],
        transformResponse: (result: { company: ICompany }) => result.company,
      }),
      getCompanies: builder.query<ICompany[], Undefinable<string>>({
        query(query) {
          return {
            url: `/companies`,
            params: { query },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Company", id } as const)),
                { type: "Company", id: "LIST" },
                "Auth",
              ]
            : [{ type: "Company", id: "LIST" }],
        transformResponse: (result: { companies: ICompany[] }) =>
          result.companies,
      }),
      updateCompanyById: builder.mutation<ICompany, EditCompanyValues>({
        query({ id, ...company }) {
          return {
            url: `/companies/${id}`,
            method: "PUT",
            body: company,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Company", id: arg.id }, "Account"] : [],
        transformResponse: (result: { company: ICompany }) => result.company,
      }),
      createCompany: builder.mutation<ICompany, EditCompanyValues>({
        query(company) {
          return {
            url: `/companies`,
            method: "POST",
            body: company,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Company", id: "LIST" }, "Account"] : [],
        transformResponse: (result: { company: ICompany }) => result.company,
      }),
      deleteCompany: builder.mutation<ICompany, number>({
        query(id) {
          return {
            url: `/companies/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, id) =>
          !error?.status ? [{ type: "Company", id }, "Auth", "Account"] : [],
      }),
      getCompanyCategories: builder.query<{ id: number; name: string }[], void>(
        {
          query(id) {
            return {
              url: `/company_categories`,
            };
          },
          transformResponse: (result: {
            items: { id: number; name: string }[];
          }) => result.items,
        }
      ),
      searchBank: builder.mutation<
        IBank[],
        { field: "kpp" | "bik"; query: string }
      >({
        query(body) {
          return {
            url: `/banks/search`,
            method: "POST",
            body,
          };
        },
        transformResponse: (result: { banks: IBank[] }) => result.banks,
      }),
      searchCompany: builder.mutation<
        ICompany[],
        { field: "inn" | "name" | "ogrn"; query: string }
      >({
        query(body) {
          return {
            url: `/companies/search`,
            method: "POST",
            body,
          };
        },
        transformResponse: (result: { companies: ICompany[] }) =>
          result.companies,
      }),
    }),
  });

export const {
  useGetCompanyByIdQuery,
  useSearchCompanyMutation,
  useSearchBankMutation,
  useGetCompaniesQuery,
  useUpdateCompanyByIdMutation,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompanyCategoriesQuery,
} = companiesApi;
