import { IInputColorPalette } from "common/models/components/input/IInputColorPalit";
import { MaskedInputProps } from "common/models/components/input/IInputProps";
import { FC, forwardRef } from "react";
import InputMask from "react-input-mask";

export const MaskedInput: FC<MaskedInputProps> = forwardRef<
  InputMask,
  MaskedInputProps
>((props, ref) => {
  const { valid, invalid, className } = props;

  let currentColorPalette = "";

  if (valid) {
    currentColorPalette = colorPalette.valid;
  } else if (invalid) {
    currentColorPalette = colorPalette.invalid;
  } else {
    currentColorPalette = colorPalette.normal;
  }

  return (
    <div>
      {props.label && (
        <span
          className={`mb-[12px] block ${
            invalid ? "text-red" : "text-gray"
          } text-sm font-semibold`}
        >
          {props.label}
        </span>
      )}
      <InputMask
        {...props}
        ref={ref}
        className={`flex items-center justify-center rounded border-[2px] bg-lightWhiteGray 
      px-[20px] py-[15px] text-[13px] leading-[16px] tracking-wider 
      outline-none transition placeholder:text-[13px] 
      ${currentColorPalette}
      ${className}`}
      />
    </div>
  );
});

const colorPalette: IInputColorPalette = {
  normal: "border-lightWhiteGray text-black placeholder:text-lightGray",
  invalid: "border-red text-red placeholder:text-red",
  valid: "border-primary text-primary placeholder:text-primary",
};
