import { ICustomError } from "./../common/models/api/ICustomError";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from "@reduxjs/toolkit/query/react";
import { fetchBaseAuthQuery } from "../app/fetchBaseAuthQuery";

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["Auth", "User", "Company", "Employee", "Order", "Account"],
  baseQuery: fetchBaseAuthQuery as BaseQueryFn<
    string | FetchArgs,
    unknown,
    ICustomError
  >,
  endpoints: () => ({}),
});
