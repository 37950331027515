import { getFilterParameters } from "common/utils/helpers";
import { api } from "../api";
import { ITableVehicle } from "common/models/vehicles/ITableVehicle";

interface IQueryParams {
  date_from?: string;
  date_to?: string;
  query?: string;
  vehicle_type_id?: number[];
}

const adminVehicleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getManipulators: build.query<ITableVehicle[], Undefinable<IQueryParams>>({
      query(params) {
        return {
          url: `/admin/vehicles/manipulators${getFilterParameters([
            { paramName: "vehicle_type_id[]", params: params?.vehicle_type_id },
          ])}`,
          method: "GET",
          params: {
            date_from: params?.date_from,
            date_to: params?.date_to,
            query: params?.query,
          },
        };
      },
      transformResponse: (result: { vehicles: ITableVehicle[] }) =>
        result.vehicles,
    }),
    getCrans: build.query<ITableVehicle[], Undefinable<IQueryParams>>({
      query(params) {
        return {
          url: `/admin/vehicles/cranes${getFilterParameters([
            { paramName: "vehicle_type_id[]", params: params?.vehicle_type_id },
          ])}`,
          method: "GET",
          params: {
            date_from: params?.date_from,
            date_to: params?.date_to,
            query: params?.query,
          },
        };
      },
      transformResponse: (result: { vehicles: ITableVehicle[] }) =>
        result.vehicles,
    }),
    getAerialPlatforms: build.query<ITableVehicle[], Undefinable<IQueryParams>>(
      {
        query(params) {
          return {
            url: `/admin/vehicles/autotowers${getFilterParameters([
              {
                paramName: "vehicle_type_id[]",
                params: params?.vehicle_type_id,
              },
            ])}`,
            method: "GET",
            params: {
              date_from: params?.date_from,
              date_to: params?.date_to,
              query: params?.query,
            },
          };
        },
        transformResponse: (result: { vehicles: ITableVehicle[] }) =>
          result.vehicles,
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetAerialPlatformsQuery,
  useGetCransQuery,
  useGetManipulatorsQuery,
} = adminVehicleApi;
