import { FC } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Authorization } from "../features/auth/Authorization";
import { useAppSelector } from "../app/hooks";
import { SvgBackgroundLogo } from "assets/logo/SvgBackgroundLogo";
import { SvgLogo } from "assets/logo/SvgLogo";

export const AuthorizationPage: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userId = useAppSelector((state) => state.user.current?.id);
  const userName = useAppSelector((state) => state.user.current?.first_name);

  let title = "";

  switch (pathname) {
    case "/auth":
      title = "Войти";
      break;
    case "/auth/registration":
    case "/auth/registration/dop":
    case "/auth/onboarding":
      title = "Регистрация";
      break;
    case "/auth/passwordRecover":
      title = "Восстановление пароля";
      break;
  }

  if (userId && !userName && pathname !== "/auth/registration/dop")
    navigate("/auth/registration/dop");

  return (
    <section className="flex min-h-screen items-center justify-center bg-[#F6F2EC]">
      <div className="z-10 m-[32px] w-fit min-w-[415px] rounded bg-white p-[32px]">
        <div className="h-full w-full">
          <div className="mb-[30px] flex flex-col items-center">
            <SvgLogo className="mb-[25px] h-[45px] w-[45px] text-black" />
            <h2 className="text-lg font-semibold">{title}</h2>
          </div>
          <div className="flex flex-col">
            <Routes>
              <Route path="*" element={<Authorization />} />
            </Routes>
          </div>
        </div>
      </div>
      <SvgBackgroundLogo className="absolute bottom-0 right-0 h-[430px] w-[400px]" />
    </section>
  );
};
