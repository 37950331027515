import { api } from "../api";
import { IVehicleChassisMark } from "../../common/models/vehicles/IVehicleChassisMark";
import { IApiMessage } from "common/models/api/IApiMessage";
import { IVehicleChassisModel } from "common/models/vehicles/IVehicleChassisModel";
import { getFilterParameters } from "common/utils/helpers";

const chassiApi = api
  .enhanceEndpoints({ addTagTypes: ["ChassisMark", "ChassisModel"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getChassisMarks: build.query<IVehicleChassisMark[], void>({
        query() {
          return {
            url: `/chassis_marks`,
          };
        },
        transformResponse: (result: { chassisMarks: IVehicleChassisMark[] }) =>
          result.chassisMarks,
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  ({ id }) => ({ type: "ChassisMark", id } as const)
                ),
                { type: "ChassisMark", id: "LIST" },
              ]
            : [{ type: "ChassisMark", id: "LIST" }],
      }),
      getChassisMarkById: build.query<IVehicleChassisMark, number>({
        query(id) {
          return {
            url: `/chassis_marks/${id}`,
          };
        },
        transformResponse: (result: { chassisMark: IVehicleChassisMark }) =>
          result.chassisMark,
        providesTags: (result) =>
          result ? [{ type: "ChassisMark", id: result?.id } as const] : [],
      }),
      createChassisMark: build.mutation<IVehicleChassisMark, { name: string }>({
        query(body) {
          return {
            url: `/chassis_marks`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisMark", id: "LIST" }] : [],
        transformResponse: (result: { chassisMark: IVehicleChassisMark }) =>
          result.chassisMark,
      }),
      updateChassisMark: build.mutation<
        IVehicleChassisMark,
        IVehicleChassisMark
      >({
        query({ id, ...body }) {
          return {
            url: `/chassis_marks/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisMark", id: arg.id }] : [],
        transformResponse: (result: { chassisMark: IVehicleChassisMark }) =>
          result.chassisMark,
      }),
      deleteChassisMark: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/chassis_marks/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisMark", id: arg.id }] : [],
      }),
      getChassisModels: build.query<IVehicleChassisModel[], number[]>({
        query(markId) {
          return {
            url: `/chassis_models${getFilterParameters([
              { paramName: "mark_id[]", params: markId },
            ])}`,
          };
        },
        transformResponse: (result: {
          chassisModels: IVehicleChassisModel[];
        }) => result.chassisModels,
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  ({ id }) => ({ type: "ChassisModel", id } as const)
                ),
                { type: "ChassisModel", id: "LIST" },
              ]
            : [{ type: "ChassisModel", id: "LIST" }],
      }),
      getChassisModelById: build.query<IVehicleChassisModel, number>({
        query(id) {
          return {
            url: `/chassis_models/${id}`,
          };
        },
        providesTags: (result) =>
          result ? [{ type: "ChassisModel", id: result?.id } as const] : [],
      }),
      createChassisModel: build.mutation<
        IVehicleChassisModel,
        { chassis_mark_id: number; name: string }
      >({
        query(body) {
          return {
            url: `/chassis_models`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisModel", id: "LIST" }] : [],
        transformResponse: (result: { chassisModel: IVehicleChassisModel }) =>
          result.chassisModel,
      }),
      updateChassisModel: build.mutation<
        IVehicleChassisModel,
        { id: number; name: string }
      >({
        query({ id, ...body }) {
          return {
            url: `/chassis_models/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisModel", id: arg.id }] : [],
        transformResponse: (result: { chassisModel: IVehicleChassisModel }) =>
          result.chassisModel,
      }),
      deleteChassisModel: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/chassis_models/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "ChassisModel", id: arg.id }] : [],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetChassisMarksQuery,
  useGetChassisModelsQuery,
  useGetChassisModelByIdQuery,
  useGetChassisMarkByIdQuery,
  useCreateChassisMarkMutation,
  useCreateChassisModelMutation,
  useUpdateChassisMarkMutation,
  useUpdateChassisModelMutation,
  useDeleteChassisMarkMutation,
  useDeleteChassisModelMutation,
} = chassiApi;
