import { FC, useMemo } from "react";
import { ORDER_STATUS } from "common/utils/consts";
import { ManagerSelection } from "./ManagerSelection";
import { OrderGoing } from "./OrderGoing";
import { OrderFinished } from "./OrderFinished";
import { SearchCompany } from "./SearchCompany";
import { IOrder } from "common/models/order/IOrder";
import { PaymentError } from "./PaymentError";
import { Title } from "common/components/Title/Title";
import { Searching } from "../Searching/Searching";

export const OrderMessage: FC<{ order: IOrder }> = ({ order }) => {
  const isDriverSearching = order.status.id === ORDER_STATUS.DRIVER_CHOOSING;
  const isManagerSelected =
    order.status.id === ORDER_STATUS.PROCESSING && order.vehicle_type;
  const isManagerSelecting =
    order.status.id === ORDER_STATUS.PROCESSING && !order.vehicle_type;
  const isCompanySearching =
    order.status.id === ORDER_STATUS.IMPLEMENTING_COMPANY_SELECTION;
  const isOrderGoing = [ORDER_STATUS.GOING, ORDER_STATUS.WAITING].includes(
    order.status.id
  );
  const isCompleted = order.status.id === ORDER_STATUS.FINISHED;
  const isPaymentError = order.status.id === ORDER_STATUS.PAYMENT_ERROR;
  const isPaymentWaiting = order.status.id === ORDER_STATUS.PAYMENT_WAITING;

  const orderCreatedAt = useMemo(
    () => (order?.created_at ? new Date(order?.created_at) : new Date()),
    [order?.created_at]
  );

  const orderConfirmedByClientAt = useMemo(
    () => (order?.confirmed_at ? new Date(order?.confirmed_at) : new Date()),
    [order?.confirmed_at]
  );

  const onPointAt = order?.on_point_at
    ? new Date(order?.on_point_at)
    : new Date();

  const orderAttachedByCompanyAt = useMemo(
    () =>
      order?.attached_by_executor_at
        ? new Date(order?.attached_by_executor_at)
        : new Date(),
    [order?.attached_by_executor_at]
  );

  return (
    <div className="mt-auto h-fit rounded bg-white">
      <div className="w-full pt-[20px] pb-[5px]">
        <i className="mx-auto block h-[3px] w-[40px] rounded bg-black" />
      </div>
      <div className="h-full pt-[15px]">
        {isManagerSelecting && (
          <Searching
            orderId={order.id}
            dateAt={orderCreatedAt}
            title="Менеджер подбирает для вас тип техники"
          />
        )}
        {isCompanySearching && (
          <SearchCompany
            startedAt={orderConfirmedByClientAt}
            currentRadiusId={order.search_radius.id}
            orderId={order.id}
          />
        )}
        {isPaymentError && (
          <PaymentError orderId={order.id} price={order.price} />
        )}
        {isPaymentWaiting && (
          <Title className="text-center text-primary">Ожидание оплаты...</Title>
        )}
        {isCompleted && (
          <OrderFinished
            orderId={order.id}
            price={order?.price}
            rate={order.rate}
          />
        )}
        {isOrderGoing && <OrderGoing onPointAt={onPointAt} order={order} />}
        {isDriverSearching && (
          <Searching
            orderId={order.id}
            dateAt={orderAttachedByCompanyAt}
            title="Поиск водителей"
          />
        )}
        {isManagerSelected && (
          <ManagerSelection
            orderId={order.id}
            price={order.price}
            vehicleTypeName={order.vehicle_type?.name}
            vehicleCategoryIconPath={order.vehicle_category.icon.path}
          />
        )}
      </div>
    </div>
  );
};
