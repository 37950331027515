import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgStar: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01536 8.16931L7.95518 9.44746C8.20314 9.61084 8.511 9.36785 8.43742 9.06683L7.87696 6.77393C7.86118 6.71007 7.86305 6.64287 7.88236 6.58006C7.90166 6.51726 7.93762 6.46139 7.9861 6.4189L9.72561 4.91316C9.95418 4.71531 9.8362 4.32081 9.54255 4.30099L7.27086 4.14766C7.20967 4.14311 7.15099 4.12058 7.10163 4.08269C7.05228 4.0448 7.01429 3.99311 6.99209 3.93363L6.14485 1.71471C6.12182 1.65159 6.08087 1.59724 6.02744 1.55889C5.97401 1.52054 5.91062 1.5 5.8457 1.5C5.78078 1.5 5.7174 1.52054 5.66397 1.55889C5.61054 1.59724 5.56959 1.65159 5.54656 1.71471L4.69931 3.93363C4.67711 3.99311 4.63913 4.0448 4.58977 4.08269C4.54042 4.12058 4.48173 4.14311 4.42055 4.14766L2.14886 4.30099C1.8552 4.32081 1.73723 4.71531 1.96579 4.91316L3.70531 6.4189C3.75378 6.46139 3.78974 6.51726 3.80905 6.58006C3.82836 6.64287 3.83023 6.71007 3.81444 6.77393L3.29468 8.90034C3.20639 9.26156 3.57582 9.55315 3.87337 9.3571L5.67605 8.16931C5.72675 8.13574 5.7856 8.11791 5.8457 8.11791C5.90581 8.11791 5.96466 8.13574 6.01536 8.16931Z"
        stroke="currentColor"
        strokeWidth=".6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
