import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import {
  useDeleteChassisModelMutation,
  useGetChassisModelsQuery,
} from "services/vehicle/chassisApi";
import { IVehicleChassisModel } from "common/models/vehicles/IVehicleChassisModel";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const ChassisModelsTable: FC<{ chassisMarkId: number[] }> = ({
  chassisMarkId,
}) => {
  const {
    data: models,
    isLoading,
    error,
  } = useGetChassisModelsQuery(chassisMarkId);

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {models?.length ? (
        <Table data={models} columns={columns} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

const columns: Column<IVehicleChassisModel>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    Header: "Марка шасси",
    accessor: "chassis_model",
    Cell: ({ row }: any) => <>{row.values.chassis_model.name}</>,
  },
  {
    id: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[10px]">Действия</span>
    ),
    Cell: ({ row }: any) => (
      <Actions id={row.original.id} onDelete={useDeleteChassisModelMutation} />
    ),
  },
];
