import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IChooseVehiclePageState {
  activeOrderId?: number;
  vehicleAssignment: {
    assignedCityId?: number;
    assignedVehicleCategoryId?: number;
    assignedVehicleTypeId?: number;
  };
}

const initialState: IChooseVehiclePageState = {
  vehicleAssignment: {},
};

const chooseVehicleSlice = createSlice({
  name: "chooseVehicle",
  initialState,
  reducers: {
    setActiveOrderId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeOrderId === action.payload)
        state.activeOrderId = undefined;
      else state.activeOrderId = action.payload;
    },
    setAssignmentCityId(state, action: PayloadAction<number>) {
      state.vehicleAssignment.assignedCityId = action.payload;
    },
    setAssignedVehicleCategoryId(
      state,
      action: PayloadAction<Undefinable<number>>
    ) {
      state.vehicleAssignment.assignedVehicleCategoryId = action.payload;
    },
    setAssignmentVehicleTypeId(
      state,
      action: PayloadAction<Undefinable<number>>
    ) {
      state.vehicleAssignment.assignedVehicleTypeId = action.payload;
    },
    resetAssignment(state) {
      state = initialState;
    },
  },
});

export const {
  resetAssignment,
  setActiveOrderId,
  setAssignedVehicleCategoryId,
  setAssignmentCityId,
  setAssignmentVehicleTypeId,
} = chooseVehicleSlice.actions;

export const chooseVehicleActions = chooseVehicleSlice.actions;

export const chooseVehicleReducer = chooseVehicleSlice.reducer;
