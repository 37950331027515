import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { OrderItemFullDetails } from "../Order/OrderItemFullDetails";
import { Title } from "../Title/Title";
import { useGlobalModalContext } from "./GlobalModal";

export const OrderDetailsModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { orderId } = modalProps;

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <div className="relative h-[90vh] min-w-[531px] overflow-y-scroll rounded bg-white p-[15px]">
      <div className="mb-[25px] flex items-center justify-between">
        <Title>Детали заказа</Title>
        <button
          className="transition hover:text-primary focus:text-primary active:text-black"
          onClick={handleModalToggle}
        >
          <SvgPlus className="h-[30px] w-[30px] rotate-45" />
        </button>
      </div>

      <OrderItemFullDetails orderId={orderId} />
    </div>
  );
};
