import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { KmuModelEdit } from "./KmuModelEdit";
import { KmuModelAdd } from "./KmuModelAdd";
import { KmuModelsTable } from "../../../tables/vehicles/KmuModelsTable";

export const KmuModelPage: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  return (
    <div className=" px-[5px]">
      <Routes>
        <Route
          path="/add"
          element={<KmuModelAdd vehicleCategoryId={vehicleCategoryId} />}
        />
        <Route path="/:id" element={<KmuModelEdit />} />
        <Route
          path="/"
          element={<KmuModelsTable vehicleCategoryId={vehicleCategoryId} />}
        />
      </Routes>
    </div>
  );
};
