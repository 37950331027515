import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { OnboardingButtons } from "./Onboardingbutton";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { useGetOnboardingQuery } from "services/appApi";

import "swiper/css";
import "swiper/css/pagination";

export const Onboarding: FC<{ applicationId: number }> = ({
  applicationId,
}) => {
  const {
    data: onboarding,
    isLoading: isOnboardingLoading,
    error: onboardingError,
  } = useGetOnboardingQuery(applicationId);

  return (
    <QueryLayout isLoading={isOnboardingLoading} error={onboardingError}>
      <div className="flex w-[980px] flex-col items-center px-[10px]">
        <div className="w-full overflow-hidden">
          <Swiper
            spaceBetween={15}
            modules={[Pagination]}
            centeredSlides
            pagination={{ clickable: true }}
            slidesPerView={3}
          >
            {onboarding?.map((slide, i) => (
              <SwiperSlide className="w-[300px]" key={"image_" + slide.id}>
                {({ isActive }) => (
                  <>
                    <p
                      className={`mb-[35px] text-center text-lightGray transition ${
                        isActive ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      {slide.title}
                    </p>
                    <div
                      className={`m-auto overflow-hidden rounded transition ${
                        isActive ? "scale-105" : "scale-100"
                      }`}
                    >
                      <img
                        className="h-[300px] w-full object-cover"
                        src={slide.image.path}
                        alt={slide.image.filename}
                      />
                    </div>
                  </>
                )}
              </SwiperSlide>
            ))}
            <div className="mx-auto mt-[70px] w-fit">
              <OnboardingButtons />
            </div>
          </Swiper>
        </div>
      </div>
    </QueryLayout>
  );
};

const orderPhotos = [
  {
    id: 1,
    title: "Просто",
    content: "Слайд с описанием №1",
    created_at: "2022-09-08T11:52:01.000000Z",
    image: {
      id: 1,
      filename: "unsplash_hoS3dzgpHzw",
      path: "https://manipulators.zk-digital.site/storage/onboarding_slides/ohyaQYeAnYzlfd7LojPeg4yT7jeRhBnbYBlY1Sw2.jpg",
    },
    application: {
      id: 1,
      name: "Приложение заказчика",
    },
  },
  {
    id: 1,
    title: "Просто",
    content: "Слайд с описанием №1",
    created_at: "2022-09-08T11:52:01.000000Z",
    image: {
      id: 1,
      filename: "unsplash_hoS3dzgpHzw",
      path: "https://manipulators.zk-digital.site/storage/onboarding_slides/ohyaQYeAnYzlfd7LojPeg4yT7jeRhBnbYBlY1Sw2.jpg",
    },
    application: {
      id: 1,
      name: "Приложение заказчика",
    },
  },
  {
    id: 1,
    title: "Просто",
    content: "Слайд с описанием №1",
    created_at: "2022-09-08T11:52:01.000000Z",
    image: {
      id: 1,
      filename: "unsplash_hoS3dzgpHzw",
      path: "https://manipulators.zk-digital.site/storage/onboarding_slides/ohyaQYeAnYzlfd7LojPeg4yT7jeRhBnbYBlY1Sw2.jpg",
    },
    application: {
      id: 1,
      name: "Приложение заказчика",
    },
  },
  {
    id: 1,
    title: "Просто",
    content: "Слайд с описанием №1",
    created_at: "2022-09-08T11:52:01.000000Z",
    image: {
      id: 1,
      filename: "unsplash_hoS3dzgpHzw",
      path: "https://manipulators.zk-digital.site/storage/onboarding_slides/ohyaQYeAnYzlfd7LojPeg4yT7jeRhBnbYBlY1Sw2.jpg",
    },
    application: {
      id: 1,
      name: "Приложение заказчика",
    },
  },
  {
    id: 1,
    title: "Просто",
    content: "Слайд с описанием №1",
    created_at: "2022-09-08T11:52:01.000000Z",
    image: {
      id: 1,
      filename: "unsplash_hoS3dzgpHzw",
      path: "https://manipulators.zk-digital.site/storage/onboarding_slides/ohyaQYeAnYzlfd7LojPeg4yT7jeRhBnbYBlY1Sw2.jpg",
    },
    application: {
      id: 1,
      name: "Приложение заказчика",
    },
  },
];
