import { IHeadingProps } from "common/models/components/ui/IHeadingProps";
import { FC, memo } from "react";

export const Title: FC<IHeadingProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <h5 {...props} className={`text-md font-semibold ${userClasses}`}>
      {props.children}
    </h5>
  );
});
