import ErrorBoundary from "common/components/ErrorBoundary/ErrorBoundary";
import { FC } from "react";

export const withErrorBoundary = (Component: FC<any>) => (props: any) => {
  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};
