import { FC } from "react";
import { Column } from "react-table";
import { useGetImplementerCompaniesQuery } from "services/admin/adminUserApi";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { Table } from "common/components/Table/Table";
import { getQueryDateParam, timeFormatter } from "common/utils/helpers";
import { IImplementer } from "common/models/user/IImplementer";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const CompanyTable: FC<{
  query?: string;
  dateFrom?: string;
  dateTo?: string;
}> = ({ query, dateFrom, dateTo }) => {
  const {
    data: users,
    isLoading,
    isFetching,
    error,
  } = useGetImplementerCompaniesQuery({
    query,
    date_from: getQueryDateParam(dateFrom),
    date_to: getQueryDateParam(dateTo),
  });

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {users?.length ? (
        <Table data={users} columns={columns} isLoading={isFetching} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

const columns: Column<IImplementer>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название организации",
    accessor: "company",
    Cell: ({ row }: any) => <span>{row.original.company.name}</span>,
  },
  {
    Header: "ФИО основателя",
    accessor: "first_name",
    Cell: ({ row }: any) => (
      <span>
        {`${row.original.last_name} ${row.values.first_name} ${
          row.original.middle_name ? row.original.middle_name : ""
        }`}
      </span>
    ),
  },
  {
    Header: "Сумма, руб.",
    accessor: "orders_sum",
    Cell: ({ row }: any) => <>{row.original.orders_sum?.toFixed(2)}</>,
  },
  {
    Header: "Завершённые заказы",
    accessor: "finished_orders_count",
  },
  {
    Header: "Баланс, руб.",
    accessor: "company",
    id: "balance",
    Cell: ({ row }: any) => (
      <span
        className={`${
          row.original.company.balance > 0 ? "text-primary" : "text-red"
        }`}
      >
        {row.original.company.balance?.toFixed(2)}
      </span>
    ),
  },
  {
    Header: "Выведено, руб.",
    accessor: "company",
    id: "payouts",
    Cell: ({ row }: any) => <>{row.original.company.payouts?.toFixed(2)}</>,
  },
  {
    Header: "Техника",
    accessor: "company",
    id: "vehicles_count",
    Cell: ({ row }: any) => <>{row.original.company.vehicles_count}</>,
  },
  {
    Header: "Сотрудники",
    accessor: "company",
    id: "employees_count",
    Cell: ({ row }: any) => <>{row.original.company.employees_count}</>,
  },
  {
    Header: "Споры",
    accessor: "disputes_count",
  },
  {
    Header: "Рейтинг",
    accessor: "rate",
  },
  {
    Header: "Дата регистрации",
    accessor: "created_at",
    Cell: ({ row }: any) => (
      <>
        {timeFormatter({
          time: new Date(row.values.created_at),
          mode: "dd-MM-yyyy",
        })}
      </>
    ),
  },
];
