import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IInvoiceState {
  invoiceStatus: number;
  currentInvoice?: {
    invoiceId: number;
    accountId: number;
    companyId: number;
  };
  invoiceDateFrom?: string;
  invoiceDateTo?: string;
}

const initialState: IInvoiceState = {
  invoiceStatus: 1,
};

const invoiceSlice = createSlice({
  name: "platform_manager_invoice",
  initialState,
  reducers: {
    setAvtiveInvoiceId(
      state,
      action: PayloadAction<
        | {
            invoiceId: number;
            accountId: number;
            companyId: number;
          }
        | undefined
      >
    ) {
      if (state.currentInvoice?.invoiceId === action.payload?.invoiceId) {
        state.currentInvoice = undefined;
      } else state.currentInvoice = action.payload;
    },
    setInvoiceStatus(state, action: PayloadAction<number>) {
      state.invoiceStatus = action.payload;
    },
    setInvoiceDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.invoiceDateFrom = action.payload;
    },
    setInvoiceDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.invoiceDateTo = action.payload;
    },
  },
});

export const {
  setAvtiveInvoiceId,
  setInvoiceStatus,
  setInvoiceDateFrom,
  setInvoiceDateTo,
} = invoiceSlice.actions;

export const invoiceReducer = invoiceSlice.reducer;
