import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface EmployeesState {
  activeEmployee?: number;
  isAddingEmployee: boolean;
  searchText: string;
}

const initialState: EmployeesState = {
  isAddingEmployee: false,
  searchText: "",
};

const employeesSlice = createSlice({
  name: "customer_employees",
  initialState,
  reducers: {
    setActiveEmployee(state, action: PayloadAction<number>) {
      if (state.activeEmployee === action.payload)
        state.activeEmployee = undefined;
      else state.activeEmployee = action.payload;

      state.isAddingEmployee = false;
    },
    setEmployeeSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setIsAddingEmployee(state, action: PayloadAction<boolean>) {
      state.isAddingEmployee = action.payload;
      state.activeEmployee = undefined;
    },
  },
});

export const { setEmployeeSearchText, setActiveEmployee, setIsAddingEmployee } =
  employeesSlice.actions;

export const employeeReducer = employeesSlice.reducer;
