import { IApiMessage } from "./../common/models/api/IApiMessage";
import { IImage } from "../common/models/commonModels/IImage";
import { api } from "./api";

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadSignleImage: build.mutation<IImage, FormData>({
      query(data) {
        return {
          contentType: "multipart/form-data",
          url: `/files`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: { file: IImage }) => result.file,
    }),
    uploadImages: build.mutation<IImage[], FormData>({
      query(data) {
        return {
          contentType: "multipart/form-data",
          url: `/files`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteImage: build.mutation<IApiMessage, number>({
      query(id) {
        return {
          url: `/files/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteImageMutation,
  useUploadSignleImageMutation,
  useUploadImagesMutation,
} = userApi;
