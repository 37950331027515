import { ILongLatAddress } from "./../../../common/models/commonModels/ILongLatAddress";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface MapState {
  longitude?: number;
  latitude?: number;
  route?: ILongLatAddress[];
}

const initialState: MapState = {};

const mapSlice = createSlice({
  name: "implementer_map",
  initialState,
  reducers: {
    setActiveAddress(state, action: PayloadAction<ILongLatAddress>) {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setRoute(state, action: PayloadAction<ILongLatAddress[]>) {
      state.route = action.payload;
    },
  },
});

export const { setActiveAddress, setRoute } = mapSlice.actions;

export const mapReducer = mapSlice.reducer;
