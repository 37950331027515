import { IOnboarding } from "./../common/models/dictionaries/IOnboarding";
import { IFaq } from "./../common/models/dictionaries/IFaq";
import { ITerm } from "./../common/models/dictionaries/ITerm";
import { IRules } from "./../common/models/dictionaries/IRules";
import { IAboutService } from "./../common/models/dictionaries/IAboutService";
import { IDeveloprtReport } from "./../common/models/commonModels/IDeveloprtReport";
import { api } from "./api";
import { ICity } from "../common/models/dictionaries/ICity";
import { IStatus } from "../common/models/commonModels/IStatus";

const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.query<ICity[], void>({
      query() {
        return {
          url: `/cities`,
        };
      },
      transformResponse: (result: { items: ICity[] }) => result.items,
    }),
    getSearchRad: build.query<IStatus[], void>({
      query() {
        return {
          url: `/search_radii`,
        };
      },
      transformResponse: (result: { items: IStatus[] }) => result.items,
    }),
    getAboutUs: build.query<IAboutService, void>({
      query() {
        return {
          url: `/about`,
        };
      },
      transformResponse: (result: { about: IAboutService }) => result.about,
    }),
    getUseRules: build.query<IRules[], number>({
      query(applicationId) {
        return {
          url: `/use_rules?application_id=${applicationId}`,
        };
      },
      transformResponse: (result: { rules: IRules[] }) => result.rules,
    }),
    getTerms: build.query<ITerm[], number>({
      query(applicationId) {
        return {
          url: `/terms?application_id=${applicationId}`,
        };
      },
      transformResponse: (result: { terms: ITerm[] }) => result.terms,
    }),
    getFaqs: build.query<IFaq[], number>({
      query(applicationId) {
        return {
          url: `/faqs?application_id=${applicationId}`,
        };
      },
      transformResponse: (result: { faqs: IFaq[] }) => result.faqs,
    }),
    getOnboarding: build.query<IOnboarding[], number>({
      query(applicationId) {
        return {
          url: `/onboarding_slides?application_id=${applicationId}`,
        };
      },
      transformResponse: (result: { slides: IOnboarding[] }) => result.slides,
    }),
    createReport: build.mutation<
      IDeveloprtReport,
      { description: string; comment: string }
    >({
      query(body) {
        return {
          url: `/developer_reports`,
          method: "POST",
          body,
        };
      },
      transformResponse: (result: { developer_report: IDeveloprtReport }) =>
        result.developer_report,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOnboardingQuery,
  useGetCitiesQuery,
  useGetSearchRadQuery,
  useCreateReportMutation,
  useGetAboutUsQuery,
  useGetFaqsQuery,
  useGetTermsQuery,
  useGetUseRulesQuery,
} = appApi;
