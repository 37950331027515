import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgPencil: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 26.9998H6C5.73478 26.9998 5.48043 26.8945 5.29289 26.707C5.10536 26.5194 5 26.2651 5 25.9998V20.4141C5 20.2827 5.02587 20.1527 5.07612 20.0314C5.12638 19.9101 5.20004 19.7998 5.29289 19.707L20.2929 4.70695C20.4804 4.51942 20.7348 4.41406 21 4.41406C21.2652 4.41406 21.5196 4.51942 21.7071 4.70695L27.2929 10.2927C27.4804 10.4803 27.5858 10.7346 27.5858 10.9998C27.5858 11.2651 27.4804 11.5194 27.2929 11.707L12 26.9998Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8L24 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
