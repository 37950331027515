import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { IVehicleOption } from "common/models/vehicles/IVehicleOption";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import {
  useDeleteVehicleOptionMutation,
  useGetVehicleOptionsQuery,
} from "services/vehicle/optionsApi";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const OptionsTable: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  const {
    data: options,
    isLoading,
    error,
  } = useGetVehicleOptionsQuery(vehicleCategoryId);

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {options?.length ? (
        <Table data={options} columns={columns} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

const columns: Column<IVehicleOption>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    Header: "Цена",
    accessor: "price",
  },
  {
    id: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[8px]">Действия</span>
    ),
    Cell: ({ row }: any) => (
      <Actions id={row.original.id} onDelete={useDeleteVehicleOptionMutation} />
    ),
  },
];
