import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ChassisModelState {
  filters: {
    chassisMarkId: number[];
  };
}

const initialState: ChassisModelState = {
  filters: {
    chassisMarkId: [],
  },
};

const chassisModelSlice = createSlice({
  name: "administrator_chassis_model",
  initialState,
  reducers: {
    setFilterChassisMarkId(state, action: PayloadAction<number>) {
      let currentVehicleTypes = state.filters.chassisMarkId;
      if (currentVehicleTypes.includes(action.payload)) {
        state.filters.chassisMarkId = currentVehicleTypes.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.chassisMarkId = [action.payload, ...currentVehicleTypes];
      }
    },
    resetFilter(state) {
      state.filters = {
        chassisMarkId: [],
      };
    },
  },
});

export const { setFilterChassisMarkId, resetFilter } =
  chassisModelSlice.actions;

export const chassisModelReducer = chassisModelSlice.reducer;
