import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { SvgEye } from "../../../assets/icons/SvgEye";
import { SvgPencil } from "../../../assets/icons/SvgPencil";
import { SvgTrash } from "../../../assets/icons/SvgTrash";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { getErrorMessage } from "common/utils/helpers";

export const Actions: FC<{
  id: number;
  onDelete: UseMutation<
    MutationDefinition<
      {
        id: number;
      },
      any,
      any,
      any
    >
  >;
}> = ({ id, onDelete }) => {
  const [deleteAction, { isLoading, error }] = onDelete();

  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (error) {
      showErrorModal(getErrorMessage(error)?.data.message);
    }
  }, [error]);

  const showErrorModal = (erorrText?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Ошибка при удалении",
      subTitle: erorrText || "При удалении ошибка, повторите попытку позже",
    });
  };

  const showConfirmationModal = () => {
    showModal(MODAL_TYPES.CONFIRM_MODAL, {
      subTitle: "Вы уверены что хотите удалить данный элемент?",
      confirmText: "Успешно удалено!",
      onConfirm: () => handleOnDelete(id),
    });
  };

  const handleOnDelete = (id: number) => {
    deleteAction({ id });
  };

  return (
    <div className="ml-auto grid w-fit grid-cols-2 gap-[10px]">
      {/* <button className="flex justify-center transition hover:text-primary focus:text-primary active:text-black">
        <SvgEye className="h-[15px] w-[15px]" />
      </button> */}
      <Link
        to={`${id}`}
        className="flex justify-center transition hover:text-primary focus:text-primary active:text-black"
      >
        <SvgPencil className="h-[15px] w-[15px]" />
      </Link>
      <button
        className="flex justify-center transition hover:text-red focus:text-red active:text-black"
        onClick={showConfirmationModal}
        disabled={isLoading}
      >
        <SvgTrash className="h-[15px] w-[15px]" />
      </button>
    </div>
  );
};
