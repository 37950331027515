import { IDivProps } from "common/models/components/ui/IDivProps";
import { Component } from "react";
import { Fallback } from "./Fallback";

interface State {
  error?: object;
}

class ErrorBoundary extends Component<IDivProps, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error: error };
  }

  componentDidCatch(error: Error) {}

  render() {
    if (this.state.error) {
      return <Fallback />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
