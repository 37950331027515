import { FC, memo } from "react";
import { SvgRatingStar } from "../../../assets/icons/SvgRatingStar";
import { IAvatarProps } from "common/models/components/ui/IAvatarProps";

export const Avatar: FC<IAvatarProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div className={`relative h-[32px] w-[32px] ${userClasses}`}>
      {props.primary && (
        <SvgRatingStar
          className="absolute top-[-2px] right-[-3px] h-[11px] w-[11px]"
          alt="raiting star"
        />
      )}
      <div className="h-full w-full">
        <img
          className="h-full w-full rounded-[50%] object-cover text-graySecond"
          src={
            props.src ||
            "https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg"
          }
          alt="user"
        />
      </div>
    </div>
  );
});
