import { FC, useEffect, useState } from "react";
import { MAX_ORDER_WAITING_COMPANY_TIME } from "common/utils/consts";
import { CompanyNotFound } from "./CompanyNotFound";
import { Searching } from "../Searching/Searching";

export const SearchCompany: FC<{
  startedAt: Date;
  currentRadiusId: number;
  orderId: number;
}> = ({ startedAt, currentRadiusId, orderId }) => {
  const [timePassed, setTimePassed] = useState<number>(
    (+new Date() - +startedAt) / 1000
  );

  const isRichMaxTime = timePassed > MAX_ORDER_WAITING_COMPANY_TIME;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed((+new Date() - +startedAt) / 1000);
      if (isRichMaxTime) {
        clearInterval(interval);
      }
    }, 800);
    return () => clearInterval(interval);
  }, [startedAt]);

  if (isRichMaxTime)
    return (
      <CompanyNotFound currentRadiusId={currentRadiusId} orderId={orderId} />
    );

  return (
    <Searching
      orderId={orderId}
      dateAt={startedAt}
      title="Поиск компании-исполнителя"
    />
  );
};
