import { ICustomError } from "./../models/api/ICustomError";
import { IVehicleType } from "./../models/vehicles/IVehicleType";
import { IManipulatorCharacteristics } from "../models/commonModels/IManipulatorCharacteristics";

export function getQueryDateParam(
  date: Undefinable<string>
): Undefinable<string> {
  return date
    ? timeFormatter({ time: new Date(date), mode: "yyyy-MM-dd" })
    : undefined;
}

export function getPropValueByString<T>(path: any, obj: T, separator = ".") {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: any, curr: T) => prev?.[curr], obj);
}

export function setPropValueByString<T>(path: any, obj: T, value: any) {
  const properties = Array.isArray(path) ? path : path.split(".");
  return properties.reduce((prev: any, curr: T) => {
    if (typeof prev === "object") {
      prev[curr] = value;
    }
    return prev?.[curr];
  }, obj);
}

export const phoneStrTransform = (phone: string) => phone.replaceAll(/\D/g, "");
interface ITimeFormatterProps {
  time: Date;
  mode?:
    | "dd-Mth"
    | "dd-Mth-yyyy"
    | "dd-Mth-yyyy hh:mm"
    | "dd-Mth hh:mm:ss"
    | "hh:mm"
    | "dd-Mth hh:mm"
    | "dd-MM-yyyy hh:mm"
    | "dd-MM-yyyy"
    | "dd-MM-yyyy hh:mm:ss"
    | "yyyy-MM-dd hh:mm:ss"
    | "yyyy-MM-dd"
    | "yyyy.MM.dd"
    | "mm:ss";
}

function isFetchBaseQueryError(error: unknown): error is ICustomError {
  return typeof error === "object" && error != null && "status" in error;
}

export function getErrorMessage(errors: unknown | unknown[]) {
  if (Array.isArray(errors)) {
    for (let i = 0; i < errors.length; i++) {
      if (isFetchBaseQueryError(errors[i])) {
        return errors[i] as ICustomError;
      }
    }
  } else {
    if (isFetchBaseQueryError(errors)) {
      return errors as ICustomError;
    }
  }
}

export function getHours(initialTime: number) {
  const hourse = Math.floor(initialTime / 60 / 60);
  const minutes = Math.floor((initialTime - hourse * 60 * 60) / 60);
  const seconds = Math.floor(initialTime - hourse * 60 * 60 - minutes * 60);
  return `${hourse < 10 ? `0${hourse}` : hourse}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

export function getNoOptionsMessage({
  minLength = 0,
  string,
  isLoading = false,
}: {
  minLength?: number;
  string: string;
  isLoading?: boolean;
}): string {
  return `${
    isLoading
      ? "Загрузка..."
      : string?.length < minLength
      ? `Введите минимум ${minLength} символа`
      : "Нет подходящих опций"
  }`;
}

export const getSuitableVehicleTypes = (
  types: IVehicleType[],
  currentCharacteristics: IManipulatorCharacteristics
) => {
  return types?.filter((type) => {
    return (
      (type.arrow_carrying_capacity === null ||
        type.arrow_carrying_capacity >= currentCharacteristics.boomCapacity) &&
      (type.board_carrying_capacity === null ||
        type.board_carrying_capacity >=
          currentCharacteristics.sideLoadCapacity) &&
      (type.lifting_height === null ||
        type.lifting_height >= currentCharacteristics.boomLength)
    );
  });
};

export const timeFormatter = ({ time, mode }: ITimeFormatterProps) => {
  const currentDate = new Date(time);
  const currentDay =
    currentDate.getDate() < 10
      ? `0${currentDate.getDate()}`
      : currentDate.getDate();
  const currentMonthStr = currentDate.toLocaleString("ru", { month: "short" });
  const currentMonth =
    currentDate.getMonth() + 1 < 10
      ? `0${currentDate.getMonth() + 1}`
      : currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const currentTime = currentDay + " " + currentMonth;
  const currentHHMMTime = time.toTimeString().split(" ")[0].slice(0, 5);
  const currentMMSSTime = time.toTimeString().split(" ")[0].slice(3, 8);
  const currentHHMMSSTime = time.toTimeString().split(" ")[0].slice(0, 8);

  switch (mode) {
    case "mm:ss":
      return `${currentMMSSTime}`;
    case "hh:mm":
      return `${currentHHMMTime}`;
    case "dd-Mth":
      return `${currentTime}`;
    case "dd-Mth-yyyy":
      return `${currentDay} ${currentMonthStr} ${currentYear}`;
    case "dd-Mth hh:mm":
      return `${currentDay} ${currentMonthStr}, ${currentHHMMTime}`;
    case "dd-Mth hh:mm:ss":
      return `${currentDay} ${currentMonthStr}, ${currentHHMMSSTime}`;
    case "dd-MM-yyyy":
      return `${currentDay}.${currentMonth}.${currentYear}`;
    case "dd-MM-yyyy hh:mm":
      return `${currentDay}.${currentMonth}.${currentYear} ${currentHHMMTime}`;
    case "dd-MM-yyyy hh:mm:ss":
      return `${currentDay}-${currentMonth}-${currentYear} ${currentHHMMTime}`;
    case "yyyy-MM-dd hh:mm:ss":
      return `${currentYear}-${currentMonth}-${currentDay} ${currentHHMMTime}`;
    case "yyyy-MM-dd":
      return `${currentYear}-${currentMonth}-${currentDay}`;
    case "yyyy.MM.dd":
      return `${currentYear}.${currentMonth}.${currentDay}`;
    case "dd-Mth-yyyy hh:mm":
    default:
      return `${currentDay} ${currentMonthStr} ${currentYear} ${currentHHMMTime}`;
  }
};

interface IParams {
  paramName: string;
  params?: any[];
}

export function getFilterParameters(paramArr: IParams[]) {
  let paramsStr = "?";

  if (paramArr.some((el) => el?.params?.length)) {
    for (let i = 0; i < paramArr.length; i++) {
      const currentParam = paramArr[i].params;
      if (currentParam?.length) {
        paramsStr += currentParam.reduce(
          (acc, curr, j) =>
            (acc += `${paramArr[i].paramName}=${curr}${
              j === currentParam.length || i === paramArr.length ? "" : "&"
            }`),
          ""
        );
      }
    }
    return paramsStr;
  }

  return "";
}

type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;

export type DotNestedKeys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<
          DotNestedKeys<T[K]>
        >}`;
      }[Exclude<keyof T, symbol>]
    : ""
) extends infer D
  ? Extract<D, string>
  : never;

export function getTableData<Data, TableData extends object>(
  originalData: Data[],
  nesting: DotNestedKeys<Data>[],
  stackable: DotNestedKeys<Data>[],
  initialRow: TableData
): TableData[] {
  const arrOfUnicData = nesting.map((prop) => [
    ...new Set(originalData.map((data) => getPropValueByString(prop, data))),
  ]);

  let data: any[] = [];

  for (let i = 0; i < arrOfUnicData.length; i++) {
    for (let j = 0; j < arrOfUnicData[i].length; j++) {
      let row = Object.create(initialRow);

      setPropValueByString(nesting[i], row, arrOfUnicData[i][j]);

      stackable.forEach((stack) => {
        setPropValueByString(
          stack,
          row,
          originalData.reduce((acc, order) => {
            let condition = true;

            for (let temp = 0; temp < nesting.length; temp++) {
              if (temp <= i) {
                if (
                  getPropValueByString(nesting[i], order) !==
                  arrOfUnicData[i][j]
                ) {
                  condition = false;
                }
              }
            }
            return condition
              ? (acc += +getPropValueByString(stack, order))
              : acc;
          }, 0)
        );
      });

      const filtredOriginalData = originalData.filter(
        (data) => getPropValueByString(nesting[i], data) === arrOfUnicData[i][j]
      );

      row.subRows = getTableData(
        filtredOriginalData,
        nesting.slice(i + 1),
        stackable,
        row
      );

      if (i === 0) {
        data.push(row);
      }
    }
  }

  return data;
}
