import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ChassisMarkEdit } from "./ChassisMarkEdit";
import { ChassisMarkAdd } from "./ChassisMarkAdd";
import { ChassisMarksTable } from "../../../tables/vehicles/ChassisMarksTable";

export const ChassisMarkPage: FC = () => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route path="/" element={<ChassisMarksTable />} />
        <Route path="/add" element={<ChassisMarkAdd />} />
        <Route path="/:id" element={<ChassisMarkEdit />} />
      </Routes>
    </div>
  );
};
