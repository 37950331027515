import { FC, memo } from "react";
import { getErrorMessage } from "../../utils/helpers";
import { Error } from "../Message/Error/Error";
import { Spinner } from "../Spinner/Spinner";
import { IQueryLayoutProps } from "common/models/components/IQueryLayoutProps";

export const QueryLayout: FC<IQueryLayoutProps> = memo(
  ({ children, isLoading, isFetching, error, className, skeleton }) => {
    if (isLoading || isFetching) {
      if (skeleton) {
        return skeleton;
      } else {
        return <Spinner className={className} />;
      }
    }

    if (error)
      return (
        <Error className={className}>
          {getErrorMessage(error)?.data?.message}
        </Error>
      );

    return <>{children}</>;
  }
);
