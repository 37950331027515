import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ICancelOrderState {
  activeOrderId?: number;
  cancelOrdersDateFrom?: string;
  cancelOrdersDateTo?: string;
}

const initialState: ICancelOrderState = {};

const cancelOrderSlice = createSlice({
  name: "cancelOrder",
  initialState,
  reducers: {
    setAvtiveOrderId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeOrderId === action.payload) {
        state.activeOrderId = undefined;
      } else state.activeOrderId = action.payload;
    },
    setCancelOrdersDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.cancelOrdersDateFrom = action.payload;
    },
    setCancelOrdersDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.cancelOrdersDateTo = action.payload;
    },
  },
});

export const {
  setAvtiveOrderId,
  setCancelOrdersDateFrom,
  setCancelOrdersDateTo,
} = cancelOrderSlice.actions;

export const cancelOrderReducer = cancelOrderSlice.reducer;
