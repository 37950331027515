import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface VehiclesState {
  filters: {
    vehicleTypeId: number[];
    vehicleCategoryId: number;
    searchText?: string;
    dateFrom?: string;
    dateTo?: string;
  };
}

const initialState: VehiclesState = {
  filters: {
    vehicleTypeId: [],
    vehicleCategoryId: 1,
  },
};

const vehcilesSlice = createSlice({
  name: "administrator_vehicles",
  initialState,
  reducers: {
    setFilterVehicleType(state, action: PayloadAction<number>) {
      let currentVehicleTypes = state.filters.vehicleTypeId;
      if (currentVehicleTypes.includes(action.payload)) {
        state.filters.vehicleTypeId = currentVehicleTypes.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.vehicleTypeId = [action.payload, ...currentVehicleTypes];
      }
    },
    setVehicleCategoryId(state, action: PayloadAction<number>) {
      state.filters.vehicleCategoryId = action.payload;
      state.filters.vehicleTypeId = [];
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },
    setDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateFrom = action.payload;
    },
    setDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateTo = action.payload;
    },
    resetFilter(state) {
      state.filters = {
        vehicleTypeId: [],
        vehicleCategoryId: 1,
      };
    },
  },
});

export const {
  setFilterVehicleType,
  setVehicleCategoryId,
  setSearchText,
  setDateFrom,
  setDateTo,
  resetFilter,
} = vehcilesSlice.actions;

export const vehiclesReducer = vehcilesSlice.reducer;
