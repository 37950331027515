import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ICancelOrderState {
  activeCompanyId?: number;
}

const initialState: ICancelOrderState = {};

const companyModerationSlice = createSlice({
  name: "registrationModeration",
  initialState,
  reducers: {
    setAvtiveCompanyId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeCompanyId === action.payload) {
        state.activeCompanyId = undefined;
      } else state.activeCompanyId = action.payload;
    },
  },
});

export const { setAvtiveCompanyId } = companyModerationSlice.actions;

export const companyModerationReducer = companyModerationSlice.reducer;
