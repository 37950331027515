import { FC } from "react";
import manipulatorImage from "../../../assets/images/manipulatorIcon.png";
import { IVehicleListItemProps } from "common/models/components/vehicle/IVehicleListItemProps";

export const VehicleListItem: FC<IVehicleListItemProps> = ({
  name,
  registrationNumber,
  companyName,
  desc,
  img,
  mode,
}) => {
  if (mode === "short") {
    return (
      <div className="grid w-full grid-cols-[50px,1fr]">
        <img
          className="mr-[15px] h-[21px] w-[24px] bg-no-repeat"
          src={img || manipulatorImage}
          alt="Vehicle profile"
        />
        <span className="pr-[10px] text-base font-semibold text-gray">
          {name}
        </span>
      </div>
    );
  }

  if (mode === "medium") {
    return (
      <div className="grid w-full grid-cols-[35px,1fr] items-center">
        <img
          className="mr-[15px] h-[21px] w-[24px] bg-no-repeat"
          src={img || manipulatorImage}
          alt="Vehicle profile"
        />
        <div className="flex flex-col text-left">
          <div className="mb-[2px] flex">
            <div className="mr-[10px] flex h-[23px] min-w-[60px] items-center justify-center rounded border border-black py-[4px] px-[7px] text-xs font-medium uppercase leading-[12px] text-black">
              {registrationNumber}
            </div>
            <span className="mb-[5px] text-base font-semibold text-graySecond">
              {name}
            </span>
          </div>
          <span className="text-sm font-medium text-gray">{desc}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 grid-rows-1 items-center justify-between">
        <div className="mb-[5px] flex text-left lg:mb-0">
          <img
            className="mr-[15px] h-[32px] w-[32px] bg-no-repeat object-contain"
            src={img || manipulatorImage}
            alt="Vehicle profile"
          />
          <div>
            <div className="mb-[5px] text-base font-semibold text-graySecond">
              {name}
            </div>
            <div className="w-fit rounded border border-black py-[4px] px-[7px] text-xs font-medium uppercase leading-[12px] text-black">
              {registrationNumber}
            </div>
          </div>
        </div>
        <div className="flex flex-col text-right">
          <div className="mb-[5px] max-h-[80px] overflow-hidden break-words text-base font-semibold text-primary">
            {companyName}
          </div>
          <div className="text-sm font-medium text-gray">{desc}</div>
        </div>
      </div>
    </div>
  );
};
