import { customerCompanyManagerReducer } from "./../features/customerCompanyManager/customerCompanyManagerReducer";
import { individualReducer } from "./../features/individual/individualReducer";
import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { userReducer } from "../features/user/userSlice";
import { implementerCompanyManagerReducer } from "../features/implementerCompanyManager/implementerCompanyManagerReducer";
import { platformManagerReducer } from "../features/platformManager/platformManagerReducer";
import { api } from "../services/api";
import { administratorReducer } from "../features/administrator/administratorReducer";
import { orderingReducer } from "features/ordering/orderingSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const rootReducer = combineReducers({
  implementerCompanyManager: implementerCompanyManagerReducer,
  customerCompanyManager: customerCompanyManagerReducer,
  platformManager: platformManagerReducer,
  administrator: administratorReducer,
  individual: individualReducer,
  ordering: orderingReducer,
  user: userReducer,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
