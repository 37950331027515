import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { KmuModelPage } from "../../kmuModels/KmuModelPage";
import { OptionsPage } from "../../options/OptionsPage";

export const AerialPlatformPage: FC = () => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route
          path="/kmu-models/*"
          element={<KmuModelPage vehicleCategoryId={2} />}
        />
        <Route
          path="/options/*"
          element={<OptionsPage vehicleCategoryId={2} />}
        />
      </Routes>
    </div>
  );
};
