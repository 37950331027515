import { FC, memo } from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Login/Login";
import { PasswordRecover } from "./PasswordRecover/PasswordRecover";
import { Registration } from "./Registration/Registration";
import { RegistrationDop } from "./RegistrationDop/RegistrationDop";
import { Onboarding } from "./Onboarding/Onboarding";
import { APPLICATIONS } from "common/utils/consts";

export const Authorization: FC = memo(() => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/registration/dop" element={<RegistrationDop />} />
      <Route path="/passwordRecover" element={<PasswordRecover />} />
      <Route
        path="/onboarding"
        element={<Onboarding applicationId={APPLICATIONS.CUSTOMER} />}
      />
    </Routes>
  );
});
