import { FC } from "react";
import { timeFormatter } from "../../../../common/utils/helpers";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";

export const AttendanceTable: FC = () => {
  return <Table data={data} columns={columns} />;
};

const columns: Column<IIndividualData>[] = [
  {
    Header: "Дата",
    accessor: "date",
    Cell: ({ row }: any) => (
      <>{timeFormatter({ time: row.values.date, mode: "dd-MM-yyyy" })}</>
    ),
  },
  {
    Header: "Посещаемость сайта",
    accessor: "attendanceAmount",
  },

  {
    Header: "Кол-во звонков",
    accessor: "callAmount",
  },
  {
    Header: "Кол-во регистраций",
    accessor: "registrationAmount",
  },
  {
    Header: "Кол-во закзаов",
    accessor: "ordersAmount",
  },
  {
    Header: "Источник",
    accessor: "source",
  },
];

interface IIndividualData {
  date: Date;
  attendanceAmount: number;
  callAmount: number;
  registrationAmount: number;
  ordersAmount: number;
  source: string;
}

const data: IIndividualData[] = [
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
  {
    date: new Date(),
    attendanceAmount: 23534,
    callAmount: 234,
    registrationAmount: 64,
    ordersAmount: 1187,
    source: "Web",
  },
];
