import { api } from "../api";
import {
  ICreateVehicleKmuModel,
  IVehicleKmuModel,
} from "../../common/models/vehicles/IVehicleKmuModel";
import { IApiMessage } from "common/models/api/IApiMessage";
import { IVehicleKmuMark } from "common/models/vehicles/IVehicleKmuMark";

const kmuApi = api
  .enhanceEndpoints({ addTagTypes: ["KmuModels", "KmuMarks"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getKmuModels: build.query<
        IVehicleKmuModel[],
        { query?: string; vehicleCategoryId: number }
      >({
        query({ query, vehicleCategoryId }) {
          return {
            url: `/kmu_models`,
            params: { query, vehicle_category_id: vehicleCategoryId },
          };
        },
        transformResponse: (result: { kmuModels: IVehicleKmuModel[] }) =>
          result.kmuModels,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "KmuModels", id } as const)),
                { type: "KmuModels", id: "LIST" },
              ]
            : [{ type: "KmuModels", id: "LIST" }],
      }),
      getKmuMarks: build.query<IVehicleKmuMark[], void>({
        query() {
          return {
            url: `/kmu_brands`,
          };
        },
        transformResponse: (result: { kmuBrands: IVehicleKmuMark[] }) =>
          result.kmuBrands,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "KmuMarks", id } as const)),
                { type: "KmuMarks", id: "LIST" },
              ]
            : [{ type: "KmuMarks", id: "LIST" }],
      }),
      getKmuMarkById: build.query<IVehicleKmuMark, number>({
        query(id) {
          return {
            url: `/kmu_brands/${id}`,
          };
        },
        transformResponse: (result: { kmuBrand: IVehicleKmuMark }) =>
          result.kmuBrand,
        providesTags: (result) =>
          result ? [{ type: "KmuMarks", id: result?.id } as const] : [],
      }),
      getKmuModelById: build.query<IVehicleKmuModel, number>({
        query(id) {
          return {
            url: `/kmu_models/${id}`,
          };
        },
        transformResponse: (result: { kmuModel: IVehicleKmuModel }) =>
          result.kmuModel,
        providesTags: (result) =>
          result ? [{ type: "KmuModels", id: result?.id } as const] : [],
      }),
      updateKmuMark: build.mutation<IVehicleKmuMark, IVehicleKmuMark>({
        query({ id, ...body }) {
          return {
            url: `/kmu_brands/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuMarks", id: arg.id }] : [],
      }),
      updateKmuModel: build.mutation<IVehicleKmuModel, ICreateVehicleKmuModel>({
        query({ id, ...body }) {
          return {
            url: `/kmu_models/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuModels", id: arg.id }] : [],
      }),
      createKmuMark: build.mutation<IVehicleKmuMark, { name: string }>({
        query(body) {
          return {
            url: `/kmu_brands`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuMarks", id: "LIST" }] : [],
      }),
      createKmuModel: build.mutation<IVehicleKmuModel, ICreateVehicleKmuModel>({
        query(body) {
          return {
            url: `/kmu_models`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuModels", id: "LIST" }] : [],
      }),
      deleteKmuModel: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/kmu_models/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuModels", id: arg.id }] : [],
      }),
      deleteKmuMark: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/kmu_brands/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "KmuMarks", id: arg.id }] : [],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetKmuModelsQuery,
  useDeleteKmuModelMutation,
  useCreateKmuMarkMutation,
  useCreateKmuModelMutation,
  useDeleteKmuMarkMutation,
  useGetKmuMarkByIdQuery,
  useGetKmuModelByIdQuery,
  useGetKmuMarksQuery,
  useUpdateKmuMarkMutation,
  useUpdateKmuModelMutation,
} = kmuApi;
