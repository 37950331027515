import { FC } from "react";
import { IPhoto } from "common/models/commonModels/IPhoto";
import { Slider } from "../Slider/Slider";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { SubTitle } from "../Title/SubTitle";
import { TextArea } from "../TextArea/TextArea";

export const OrderDescription: FC<{
  description: string;
  photos: IPhoto[];
}> = ({ description, photos }) => {
  return (
    <div>
      <div className="mb-[12px]">
        {photos.length ? (
          <Slider className="multiple" expandable images={photos} />
        ) : (
          <NotFoundTitle />
        )}
      </div>
      <SubTitle className="mb-[12px]">Комментарий</SubTitle>
      {description ? (
        <TextArea
          className="min-h-[170px] w-full resize-none"
          placeholder="Комментарий"
          readOnly
          value={description}
        />
      ) : (
        <NotFoundTitle />
      )}
    </div>
  );
};
