import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgError: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="23.5" stroke="#F2735F" strokeWidth="3" />
      <path
        d="M32.0786 18.4395L18.4395 32.0786"
        stroke="#F2735F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0786 32.0786L18.4395 18.4395"
        stroke="#F2735F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
