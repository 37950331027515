import { vehicleModerationReducer } from "./vehicleModeration/vehicleModerationSlice";
import { companyModerationReducer } from "./companyModeration/companyModerationSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { cancelOrderReducer } from "./canceldOrder/cancelOrderSlice";
import { disputeReducer } from "./dispute/disputeSlice";
import { searchReducer } from "./search/searchSlice";
import { invoiceReducer } from "./invoice/invoiceSlice";
import { desktopReducer } from "./desktop/desktopSlice";
import { chooseVehicleReducer } from "./chooseVehicle/chooseVehicleSlice";
import { finishedOrderModerationReducer } from "./finishedOrderModeration/finishedOrderModerationSlice";

export const platformManagerReducer = combineReducers({
  search: searchReducer,
  desktop: desktopReducer,
  chooseVehicle: chooseVehicleReducer,
  dispute: disputeReducer,
  cancelOrder: cancelOrderReducer,
  invoice: invoiceReducer,
  companyModeration: companyModerationReducer,
  vehicleModeration: vehicleModerationReducer,
  finishedOrderModeration: finishedOrderModerationReducer,
});
