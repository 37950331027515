import { FC, memo } from "react";
import { IButtonProps } from "common/models/components/ui/IButtonProps";
import { SvgListDashes } from "assets/icons/SvgListDashes";

export const FilterButton: FC<IButtonProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <button
      className={`pointer-events-auto flex h-[35px] w-[35px] items-center justify-center rounded border border-transparent bg-lightWhiteGray shadow-md 
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black ${userClasses}`}
      {...props}
    >
      <SvgListDashes className="h-[24px] w-[24px]" />
    </button>
  );
});
