import { IHeadingProps } from "common/models/components/ui/IHeadingProps";
import { FC, memo } from "react";

export const SubTitle: FC<IHeadingProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <h4 {...props} className={`text-base font-semibold ${userClasses}`}>
      {props.children}
    </h4>
  );
});
