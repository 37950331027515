import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface OrdersState {
  chartData?: {
    date: string;
    value: number;
  }[];
  filters: {
    vehicleCategory: number[];
    period?: number;
    vehicleType: number[];
    dateFrom?: string;
    dateTo?: string;
  };
}

const initialState: OrdersState = {
  filters: {
    vehicleCategory: [],
    vehicleType: [],
  },
};

const ordersSlice = createSlice({
  name: "administrator_orders",
  initialState,
  reducers: {
    setChartData(
      state,
      action: PayloadAction<
        {
          date: string;
          value: number;
        }[]
      >
    ) {
      state.chartData = action.payload;
    },
    setFilterVehicleCategory(state, action: PayloadAction<number>) {
      let currentVehicleCategories = state.filters.vehicleCategory;
      if (currentVehicleCategories.includes(action.payload)) {
        state.filters.vehicleCategory = currentVehicleCategories.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.vehicleCategory = [
          action.payload,
          ...currentVehicleCategories,
        ];
      }
      state.filters.vehicleType = [];
    },
    setFilterVehicleType(state, action: PayloadAction<number>) {
      let currentVehicleTypes = state.filters.vehicleType;
      if (currentVehicleTypes.includes(action.payload)) {
        state.filters.vehicleType = currentVehicleTypes.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.vehicleType = [action.payload, ...currentVehicleTypes];
      }
    },
    setDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateFrom = action.payload;
      state.filters.period = undefined;
    },
    setDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateTo = action.payload;
      state.filters.period = undefined;
    },
    setFilterPeriod(state, action: PayloadAction<number>) {
      state.filters.period = action.payload;
      state.filters.dateTo = new Date().toISOString();
      state.filters.dateFrom = new Date(
        +new Date() - action.payload
      ).toISOString();
    },
    resetFilter(state) {
      state.filters = {
        vehicleCategory: [],
        vehicleType: [],
      };
    },
  },
});

export const orderFiltersSelector = (state: RootState) =>
  state.administrator.orders.filters;

export const {
  setFilterVehicleCategory,
  setFilterVehicleType,
  resetFilter,
  setFilterPeriod,
  setDateFrom,
  setDateTo,
  setChartData,
} = ordersSlice.actions;

export const orderReducer = ordersSlice.reducer;
