import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CompaniesState {
  activeCompanyId?: number;
  isAddingCompany: boolean;
  searchText: string;
}

const initialState: CompaniesState = {
  isAddingCompany: false,
  searchText: "",
};

const companiesSlice = createSlice({
  name: "individual_companies",
  initialState,
  reducers: {
    setActiveCompany(state, action: PayloadAction<number>) {
      if (state.activeCompanyId === action.payload)
        state.activeCompanyId = undefined;
      else state.activeCompanyId = action.payload;

      state.isAddingCompany = false;
    },
    setCompanySearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setIsAddingCompany(state, action: PayloadAction<boolean>) {
      state.isAddingCompany = action.payload;
      state.activeCompanyId = undefined;
    },
  },
});

export const { setActiveCompany, setCompanySearchText, setIsAddingCompany } =
  companiesSlice.actions;

export const companyReducer = companiesSlice.reducer;
