import { api } from "../api";
import { IApiMessage } from "common/models/api/IApiMessage";
import { IVehicleOption } from "common/models/vehicles/IVehicleOption";

const kmuApi = api
  .enhanceEndpoints({ addTagTypes: ["Options"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getVehicleOptions: build.query<IVehicleOption[], number>({
        query(vehicleCategoryId) {
          return {
            url: `/vehicle_options`,
            params: { vehicle_category_id: vehicleCategoryId },
          };
        },
        transformResponse: (result: { options: IVehicleOption[] }) =>
          result.options,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Options", id } as const)),
                { type: "Options", id: "LIST" },
              ]
            : [{ type: "Options", id: "LIST" }],
      }),
      getVehicleOptionById: build.query<IVehicleOption, number>({
        query(id) {
          return {
            url: `/vehicle_options/${id}`,
          };
        },
        transformResponse: (result: { option: IVehicleOption }) =>
          result.option,
        providesTags: (result) =>
          result ? [{ type: "Options", id: result?.id } as const] : [],
      }),
      updateVehicleOption: build.mutation<IVehicleOption, IVehicleOption>({
        query({ id, ...body }) {
          return {
            url: `/vehicle_options/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Options", id: arg.id }] : [],
      }),
      createVehicleOption: build.mutation<
        IVehicleOption,
        { name: string; price: number; vehicle_category_id: number }
      >({
        query(body) {
          return {
            url: `/vehicle_options`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Options", id: "LIST" }] : [],
      }),
      deleteVehicleOption: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/vehicle_options/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Options", id: arg.id }] : [],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetVehicleOptionsQuery,
  useGetVehicleOptionByIdQuery,
  useDeleteVehicleOptionMutation,
  useUpdateVehicleOptionMutation,
  useCreateVehicleOptionMutation,
} = kmuApi;
