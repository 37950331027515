import { FC, forwardRef, useState } from "react";
import { SvgPlus } from "../../../assets/icons/SvgPlus";
import { SvgTrash } from "../../../assets/icons/SvgTrash";
import { IPhotoInputProps } from "common/models/components/input/IPhotoInputProps";

export const PhotoInput: FC<IPhotoInputProps> = forwardRef<
  HTMLInputElement,
  IPhotoInputProps
>(({ className = "", placeholder, withoutPreview, ...props }, ref) => {
  const userClasses = className || "";

  const { invalid, defaultImages, onDelete } = props;

  const [previewImage, setPreviewImage] = useState<string[] | undefined>(
    undefined
  );

  const handleOnPhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setPreviewImage(undefined);
      return;
    }
    setPreviewImage(
      Array.from(event.target.files).map((file) => URL.createObjectURL(file))
    );
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div>
      {props.label && (
        <span
          className={`mb-[12px] block ${
            invalid ? "text-red" : "text-gray"
          } text-sm font-semibold`}
        >
          {props.label}
        </span>
      )}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(220px,1fr))] place-items-center gap-[15px]">
        {!withoutPreview &&
          defaultImages &&
          defaultImages.map((image) => (
            <div key={image.id} className="relative h-[222px] w-[230px]">
              <img
                className="h-full w-full rounded object-cover"
                src={image.path}
                alt="current"
              />
              {onDelete && (
                <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <button
                    disabled={props.disabled}
                    className="rounded bg-primary p-[8px] text-white transition
                      hover:bg-white hover:text-red focus:bg-white focus:text-red active:bg-primary active:text-white"
                    onClick={() => onDelete(image.id)}
                  >
                    <SvgTrash className="h-[30px] w-[30px]" />
                  </button>
                </div>
              )}
            </div>
          ))}
        {!withoutPreview &&
          previewImage &&
          previewImage.map((image) => (
            <div key={image} className="h-[222px] w-[230px]">
              <img
                className="h-full rounded object-cover"
                src={image}
                alt="current"
              />
            </div>
          ))}
        {!props.readOnly && (
          <div
            className={`relative w-fit rounded border-[2px] border-dashed bg-whiteGray transition focus-within:border-primary ${
              invalid ? "border-red" : "border-grablacky"
            }`}
          >
            <input
              ref={ref}
              type="file"
              {...props}
              disabled={props.readOnly}
              accept="image/*"
              onChange={handleOnPhotoChange}
              className={`h-[222px] w-[230px] cursor-pointer rounded bg-lightWhiteGray px-[20px] 
            py-[15px] text-[13px] leading-[16px] tracking-wider opacity-0 
            outline-none transition placeholder:text-[13px]
            ${userClasses}
          `}
            />
            <div className="pointer-events-none absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
              <SvgPlus className="h-[30px] w-[30px] text-primary" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
