import { mapReducer } from "./map/mapSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { requestReducer } from "./requests/requestsSlice";
import { employeeReducer } from "./employees/employeesSlice";
import { vehicleReducer } from "./vehicles/vehiclesSlice";
import { orderReducer } from "./orders/ordersSlice";
import { transactionReducer } from "./transactions/transactionsSlice";
import { companyReducer } from "./companies/companiesSlice";

export const implementerCompanyManagerReducer = combineReducers({
  requests: requestReducer,
  employees: employeeReducer,
  vehicles: vehicleReducer,
  orders: orderReducer,
  transactions: transactionReducer,
  companies: companyReducer,
  map: mapReducer,
});
