import { FC } from "react";

export interface ISkeletonTableProps extends React.HTMLProps<HTMLTableElement> {
  colCount: number;
  rowCount: number;
  withoutHeader?: boolean;
}

export const SkeletonTable: FC<ISkeletonTableProps> = ({
  colCount,
  rowCount,
  withoutHeader,
  ...props
}) => {
  const colsArr = Array.from(Array(colCount).keys());
  const rowsArr = Array.from(Array(rowCount).keys());

  return (
    <table {...props} className="table animate-pulse text-gray">
      {!withoutHeader && (
        <thead>
          {colsArr.map((col) => (
            <th className="h-[1px] text-left text-black" key={col}>
              <div className="flex h-full max-h-[55px] flex-col justify-between">
                <i className="h-[15px] min-h-[20px] w-[80px] rounded bg-lightWhiteGray pl-[10px] leading-[12px]" />
              </div>
            </th>
          ))}
        </thead>
      )}

      <tbody>
        {colsArr.map((row, i) => {
          return (
            <tr className={`h-[35px]`} key={row}>
              {rowsArr.map((col) => (
                <td className={` px-[10px] py-[10px]`} key={col}></td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
