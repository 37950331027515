import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IVehicleModerationState {
  activeVehicleId?: number;
  searchText: string;
}

const initialState: IVehicleModerationState = {
  searchText: "",
};

const vehicleModerationSlice = createSlice({
  name: "vehicle_moderation",
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setAvtiveVehicleId(state, action: PayloadAction<number>) {
      if (state.activeVehicleId === action.payload) {
        state.activeVehicleId = undefined;
      } else state.activeVehicleId = action.payload;
    },
  },
});

export const { setAvtiveVehicleId, setSearchText } =
  vehicleModerationSlice.actions;

export const vehicleModerationReducer = vehicleModerationSlice.reducer;
