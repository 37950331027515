import { FC } from "react";
import { Button } from "common/components/Button/Button";
import { useSwiper } from "swiper/react";
import { useNavigate } from "react-router-dom";

export const OnboardingButtons: FC = () => {
  const navigate = useNavigate();

  const swiper = useSwiper();

  return (
    <div className="grid w-[415px] grid-cols-2 gap-[15px]">
      <Button
        className="review-swiper-button-next"
        onClick={() => {
          if (!swiper.isEnd) {
            swiper.slideNext();
          } else {
            swiper.slideTo(0);
          }
        }}
      >
        Продолжить
      </Button>
      <Button mode="darkBorder" onClick={() => navigate("/")}>
        Завершить
      </Button>
    </div>
  );
};
