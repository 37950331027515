import { FC, memo } from "react";
import { Avatar } from "../Avatar/Avatar";
import { SvgRatingStar } from "../../../assets/icons/SvgRatingStar";
import { IEmployeeListItemProps } from "common/models/components/employee/IEmployeeListItemProps";

export const EmployeeListItem: FC<IEmployeeListItemProps> = memo(
  ({ name, rating, img, role }) => {
    return (
      <div className="grid grid-cols-[40px,1fr]">
        <Avatar src={img} alt="Driver profile" />
        <div className="flex items-center justify-between">
          <div className="flex flex-col text-left">
            <span className="text-base font-semibold text-graySecond">
              {name}
            </span>
            <div className="flex items-center text-sm font-semibold text-primary">
              {rating && (
                <>
                  <div>
                    <SvgRatingStar
                      className="mr-[5px] h-[12px] w-[12px]"
                      alt="raiting star"
                    />
                  </div>
                  <span className="mr-[5px]">{rating}</span>
                </>
              )}
              {role}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
