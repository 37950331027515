import { SvgLogo } from "assets/logo/SvgLogo";
import { IDivProps } from "common/models/components/ui/IDivProps";
import { FC, memo } from "react";

export const Fallback: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div
      {...props}
      className={`absolute top-0 bottom-0 left-0 right-0 z-10 flex h-full w-full flex-col items-center justify-center gap-[15px] bg-white p-[15px] text-center ${userClasses}`}
    >
      <SvgLogo className="mb-[30px] h-[200px] w-[200px] text-red" />
      <p className="text-xl font-bold">
        {props.children ? props.children : "Произошла ошибка"}
      </p>
    </div>
  );
});
