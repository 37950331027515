import { ISvgProps } from "common/models/components/ui/ISvgProps";
import { FC } from "react";

export const SvgBackgroundLogo: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 268 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.5337 198.456C73.8634 188.588 67.3657 178.111 59.7512 167.328C39.8518 139.049 130.109 150.34 130.008 191.335V191.336C130.008 193.675 130.008 196.218 130.109 198.659C132.546 208.526 137.724 217.987 145.44 225.718C168.385 248.605 205.443 248.605 228.286 225.718C251.13 202.728 251.13 165.599 228.286 142.711C220.773 135.183 211.636 130.097 201.991 127.554C201.686 137.421 190.315 145.458 176.406 145.458C162.294 145.458 150.821 137.218 150.821 127.147V44.3441V17.5907V3.04424C208.488 -7.53504 270.217 9.45284 314.889 54.1096C344.129 83.406 361.388 119.925 366.972 157.97H326.057C314.483 96.7319 264.43 49.1251 202.092 41.4958V75.675C224.834 78.8285 246.866 89.1026 264.43 106.701C307.173 149.527 307.173 219.106 264.43 261.931C221.687 304.757 152.242 304.757 109.499 261.931C91.6307 244.13 81.2749 221.649 78.3306 198.456H78.5337ZM99.5509 68.0455C63.6103 94.2903 40.259 136.811 40.259 184.723C40.259 193.471 41.0713 202.117 42.5942 210.459H1.78026C-5.9358 155.019 11.4253 96.7316 53.9652 54.1094C67.6714 40.2749 83.1035 29.187 99.5509 20.6422V68.0455ZM253.265 311.573C291.236 290.821 318.852 253.488 326.365 209.238H367.179C362.001 248.097 344.538 285.633 314.791 315.438C296.719 333.647 275.703 347.176 253.265 356.229V311.573ZM62.4915 261.729H16.7028C25.6372 281.158 38.0235 299.367 53.9632 315.439C94.4726 356.027 149.094 373.828 201.99 368.742V328.053C196.203 328.765 190.314 329.172 184.324 329.172C133.053 329.172 88.0763 302.215 62.4915 261.729Z"
        fill="#F3EEE6"
      />
    </svg>
  );
};
