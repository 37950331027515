import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { KmuMarkEdit } from "./KmuMarkEdit";
import { KmuMarkAdd } from "./KmuMarkAdd";
import { KmuMarksTable } from "features/administrator/tables/vehicles/KmuMarksTable";

export const KmuMarkPage: FC = () => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route path="/add" element={<KmuMarkAdd />} />
        <Route path="/:id" element={<KmuMarkEdit />} />
        <Route path="/" element={<KmuMarksTable />} />
      </Routes>
    </div>
  );
};
