import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface HomeState {
  activeOrderId?: number;
  isOrdering: boolean;
}

const initialState: HomeState = {
  isOrdering: true,
};

const homeSlice = createSlice({
  name: "individual_home",
  initialState,
  reducers: {
    setActiveOrderId(state, action: PayloadAction<Undefinable<number>>) {
      state.activeOrderId = action.payload;
    },
    setIsOrdering(state, action: PayloadAction<boolean>) {
      state.isOrdering = action.payload;
    },
  },
});

export const { setActiveOrderId, setIsOrdering } = homeSlice.actions;

export const homeReducer = homeSlice.reducer;
