import { FC, memo } from "react";
import { IDivProps } from "common/models/components/ui/IDivProps";
import { SvgSuccess } from "assets/icons/SvgSuccess";

export const Success: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div
      {...props}
      className={`flex flex-col items-center text-primary ${userClasses || ""}`}
    >
      <SvgSuccess className={`mb-[15px] h-[60px] w-[60px]`} />
      <p className="text-center text-base">{props.children || "Успешно!"}</p>
    </div>
  );
});
