import { FC, memo } from "react";
import { IColorPalette } from "../../models/commonModels/IColorPalette";
import { IIconButtonProps } from "common/models/components/ui/IButtonProps";

export const WideIconButton: FC<IIconButtonProps> = memo((props) => {
  const { icon } = props;

  const userClasses = props.className || "";

  return (
    <button
      {...props}
      className={`flex items-center rounded px-[20px] py-[15px] outline-none transition ${colorPalette.normal} ${colorPalette.hover} ${colorPalette.active} ${colorPalette.focus} ${colorPalette.disabled} ${userClasses}`}
    >
      <span className="w-full">{props.children}</span>
      {icon}
    </button>
  );
});

const colorPalette: IColorPalette = {
  normal: "border border-whiteGray",
  hover: "hover:bg-lightWhiteGray hover:text-black hover:border-lightWhiteGray",
  active: "active:bg-white active:text-black active:border-primary",
  focus: "focus:bg-lightWhiteGray focus:text-black focus:border-lightWhiteGray",
  disabled:
    "disabled:bg-lightGray disabled:text-white disabled:border-lightGray",
};
