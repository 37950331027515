import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SvgSignOut } from "../../../assets/icons/SvgSignOut";
import { Avatar } from "../../../common/components/Avatar/Avatar";
import { logout } from "../../user/userSlice";
import { useLocation } from "react-router-dom";

export const AdministratorHeader: FC = () => {
  const location = useLocation();

  const firstName = useAppSelector((state) => state.user.current?.first_name);
  const lastName = useAppSelector((state) => state.user.current?.last_name);
  const middleName = useAppSelector((state) => state.user.current?.middle_name);
  const companyLogotype = useAppSelector(
    (state) => state.user.current?.account.company?.logotype?.path
  );

  const fullName = lastName + " " + firstName + " " + (middleName || "");

  const dispatch = useAppDispatch();

  return (
    <header className="z-10 mb-[15px] flex items-center justify-between px-[20px] shadow-md">
      <div>
        <h1 className="text-lg font-semibold">Личный кабинет администратора</h1>
        <div className="text-base text-graySecond">{location.state}</div>
      </div>
      <div className="flex h-full items-center">
        <div className="relative mr-[12px] flex items-center">
          <Avatar src={companyLogotype} alt="company logo" />
        </div>
        <h4 className="text-left text-base font-semibold leading-4 text-lightGray">
          {fullName}
        </h4>
        <button
          className="h-full pl-[15px] transition hover:text-primary focus:text-primary active:text-gray"
          onClick={() => dispatch(logout())}
        >
          <SvgSignOut className="h-[20px] w-[20px]" />
        </button>
      </div>
    </header>
  );
};
