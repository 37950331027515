import { useExpanded, useFilters, useTable } from "react-table";
import { ITableProps } from "../../../common/models/table/ITableProps";
import { useMemo } from "react";
import { SkeletonTable } from "./SkeletonTable";

const CustomTable = <T extends object>({
  data,
  columns,
  isSubTable,
  isLoading,
}: ITableProps<T>) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    headerGroups,
    footerGroups,
    prepareRow,
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
    },
    useExpanded,
    useFilters
  );

  const haveFooter = footerGroups[0].headers.some(
    (header) => typeof header.Footer === "string"
  );

  return (
    <table
      className={`${isSubTable ? "sub-table" : "table"} text-gray`}
      {...getTableProps()}
    >
      {!isSubTable && (
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    className="h-[1px] text-left text-black"
                    key={column.id}
                    style={
                      isSubTable
                        ? { width: `${100 / headerGroup.headers.length}%` }
                        : undefined
                    }
                  >
                    <div className="flex h-full max-h-[55px] flex-col justify-between">
                      <div className="min-h-[25px] pl-[10px] leading-[12px]">
                        {column.render("Header")}
                      </div>
                      <div className="px-[2px]">
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
      )}
      {isLoading ? (
        <td colSpan={columns.length}>
          <SkeletonTable colCount={columns.length} rowCount={6} withoutHeader />
        </td>
      ) : (
        <>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`${
                    i === 0 ? "text-black" : !isSubTable ? "text-black" : ""
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={`px-[10px] ${
                          isSubTable ? "py-[5px]" : "py-[10px]"
                        }`}
                        {...cell.getCellProps()}
                        style={
                          isSubTable
                            ? { width: `${100 / row.cells.length}%` }
                            : undefined
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {haveFooter && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr
                  className="bg-[#FFF9F1] text-primary"
                  {...group.getFooterGroupProps()}
                >
                  {group.headers.map((column) => (
                    <td className="p-[10px]" {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </>
      )}
    </table>
  );
};

export const Table = CustomTable;
