import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";
import {
  useDeleteKmuMarkMutation,
  useGetKmuMarksQuery,
} from "services/vehicle/kmuApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { IVehicleKmuMark } from "common/models/vehicles/IVehicleKmuMark";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const KmuMarksTable: FC = () => {
  const { data: kmu, isLoading, error } = useGetKmuMarksQuery();

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {kmu?.length ? <Table data={kmu} columns={columns} /> : <NotFoundTitle />}
    </QueryLayout>
  );
};

const columns: Column<IVehicleKmuMark>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    id: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[8px]">Действия</span>
    ),
    Cell: ({ row }: any) => (
      <Actions id={row.original.id} onDelete={useDeleteKmuMarkMutation} />
    ),
  },
];
