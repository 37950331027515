import { FC } from "react";
import { Button } from "../../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { ChassisModelFormInputs } from "./ChassisModelFormInputs";
import { Error } from "common/components/Message/Error/Error";
import { getErrorMessage } from "common/utils/helpers";
import { Success } from "common/components/Message/Success/Success";
import { useCreateChassisModelMutation } from "services/vehicle/chassisApi";

export const ChassisModelAdd: FC = () => {
  const [create, { isLoading, error, isSuccess }] =
    useCreateChassisModelMutation();

  const methods = useForm<{
    name: string;
    chassis_mark_id: number;
  }>();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    create(data);
  });

  if (error) {
    return <Error>{getErrorMessage(error)?.data.message}</Error>;
  }

  if (isSuccess) {
    return <Success>Марка успешно создана!</Success>;
  }

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <ChassisModelFormInputs />
      </FormProvider>
      <Button className="w-fit" disabled={isLoading}>
        Сохранить
      </Button>
    </form>
  );
};
