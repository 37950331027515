import { MODAL_TYPES } from "../../utils/consts";
import { useGlobalModalContext } from "./GlobalModal";
import { useGetVehicleTypesQuery } from "../../../services/vehicle/vehicleTypesApi";
import { useUpdateOrderMutation } from "../../../services/orderApi";
import { getErrorMessage, getSuitableVehicleTypes } from "../../utils/helpers";
import { VehicleTypeSelection } from "../Vehicle/VehicleTypeSelection";
import { IManipulatorCharacteristics } from "../../models/commonModels/IManipulatorCharacteristics";

export const VehicleSelectionModal = () => {
  const { showModal, hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { orderId, vehicleCategoryId, cityId, onConfirm, confirmText } =
    modalProps;

  const [updateOrder, { isLoading: isOrderUpdateLoading }] =
    useUpdateOrderMutation();
  const { data: types } = useGetVehicleTypesQuery(vehicleCategoryId);

  const showErrorModal = (message?: string) => {
    showModal(MODAL_TYPES.ERROR_MODAL, {
      subTitle: message || "Подходящий тип техники отсутствует",
    });
  };

  const handleModalToggle = () => {
    hideModal();
  };

  const onSubmit = async (data: IManipulatorCharacteristics) => {
    if (types) {
      const suitableType = getSuitableVehicleTypes(types, data);

      if (suitableType?.length) {
        await updateOrder({
          orderId,
          vehicleTypeId: suitableType[0].id,
          cityId,
          options: data.optionalEquipment
            .filter((option) => option)
            .map((option) => +option.id),
        })
          .unwrap()
          .then(async () => {
            await onConfirm();
            handleModalToggle();
            showModal(MODAL_TYPES.SUCCESS_MODAL, { title: confirmText });
          })
          .catch((err) => {
            showErrorModal(getErrorMessage(err)?.data.message);
          });
      } else {
        showErrorModal();
      }
    } else {
      showErrorModal("Не удалось получить типы техники");
    }
  };

  return (
    <div className="min-w-[670px] rounded bg-white p-[35px]">
      <VehicleTypeSelection
        vehicleCategoryId={vehicleCategoryId}
        onSubmit={onSubmit}
        onClose={handleModalToggle}
        isDisabled={isOrderUpdateLoading}
      />
    </div>
  );
};
