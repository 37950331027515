import { api } from "../api";
import {
  ICreateVehicleKmuModel,
  IVehicleKmuModel,
} from "../../common/models/vehicles/IVehicleKmuModel";
import { IApiMessage } from "common/models/api/IApiMessage";
import { IVehicleKmuMark } from "common/models/vehicles/IVehicleKmuMark";
import { getFilterParameters } from "common/utils/helpers";
import { IVehicleType } from "common/models/vehicles/IVehicleType";
import { VehicleTypeFormValues } from "common/models/forms/VehicleTypeFormValues";

const vehicleTypesApi = api
  .enhanceEndpoints({ addTagTypes: ["Tariff"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getVehicleTypes: build.query<IVehicleType[], Undefinable<number[]>>({
        query(vehicleCategoryId) {
          return {
            url: `/vehicle_types${getFilterParameters([
              { paramName: "vehicle_category_id[]", params: vehicleCategoryId },
            ])}`,
          };
        },
        transformResponse: (result: { items: IVehicleType[] }) => result.items,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Tariff", id } as const)),
                { type: "Tariff", id: "LIST" },
              ]
            : [{ type: "Tariff", id: "LIST" }],
      }),
      getVehicleTypeApiById: build.query<IVehicleType, number>({
        query(id) {
          return {
            url: `/vehicle_types/${id}`,
          };
        },
        transformResponse: (result: { vehicleType: IVehicleType }) =>
          result.vehicleType,
        providesTags: (result) =>
          result ? [{ type: "Tariff", id: result?.id } as const] : [],
      }),
      updateVehicleType: build.mutation<IVehicleType, VehicleTypeFormValues>({
        query({ id, ...body }) {
          return {
            url: `/vehicle_types/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Tariff", id: arg.id }] : [],
      }),
      createVehicleType: build.mutation<IVehicleType, VehicleTypeFormValues>({
        query(body) {
          return {
            url: `/vehicle_types`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Tariff", id: "LIST" }] : [],
      }),
      deleteVehicleType: build.mutation<IApiMessage, { id: number }>({
        query({ id }) {
          return {
            url: `/vehicle_types/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Tariff", id: arg.id }] : [],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetVehicleTypesQuery,
  useCreateVehicleTypeMutation,
  useUpdateVehicleTypeMutation,
  useDeleteVehicleTypeMutation,
  useGetVehicleTypeApiByIdQuery,
} = vehicleTypesApi;
