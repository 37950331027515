import { IHeadingProps } from "common/models/components/ui/IHeadingProps";
import { FC, memo } from "react";

export const NotFoundTitle: FC<IHeadingProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <h5 {...props} className={`text-md ${userClasses}`}>
      {props.children || "Ничего не найдено"}
    </h5>
  );
});
