import { FC } from "react";
import { Input } from "../../../../../common/components/Input/Input";
import { useFormContext } from "react-hook-form";

export const OptionFormInputs: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{
    name: string;
    price: number;
  }>();

  return (
    <>
      <Input
        {...register(`name`, { required: true, minLength: 2 })}
        className="w-full"
        label="Название *"
        invalid={!!errors.name}
        placeholder="Название"
      />
      <Input
        {...register(`price`, { required: true })}
        className="w-full"
        label="Цена *"
        invalid={!!errors.price}
        placeholder="Цена"
      />
    </>
  );
};
