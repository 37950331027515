import { FC, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ChassisModelEdit } from "./ChassisModelEdit";
import { ChassisModelAdd } from "./ChassisModelAdd";
import { ChassisModelsTable } from "features/administrator/tables/vehicles/ChassisModelsTable";
import { AdminChassisMarksFilter } from "common/components/Filter/AdminChassisMarksFilter";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetFilter, setFilterChassisMarkId } from "./chassisModelSlice";
import { SvgListDashes } from "assets/icons/SvgListDashes";

export const ChassisModelPage: FC = () => {
  const filters = useAppSelector(
    (state) => state.administrator.chassisModel.filters
  );

  const dispatch = useAppDispatch();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const handleChassisMarkIdChange = (id: number) => {
    dispatch(setFilterChassisMarkId(id));
  };

  const handleResetFilter = () => {
    dispatch(resetFilter());
  };

  return (
    <div className="px-[5px]">
      {isFilterOpen && (
        <AdminChassisMarksFilter
          chassisMarkId={filters.chassisMarkId}
          setIsFilterOpen={setIsFilterOpen}
          handleChassisMarkIdChange={handleChassisMarkIdChange}
          handleFilterReset={handleResetFilter}
        />
      )}
      <div className="ml-auto w-[100px]">
        <button
          className="flex h-[35px] w-full items-center justify-center rounded border border-transparent bg-lightWhiteGray px-[8px] text-sm font-medium
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
          onClick={() => setIsFilterOpen((prev) => !prev)}
        >
          <SvgListDashes className="mr-[5px] h-[16px] w-[16px]" />
          Фильтр
        </button>
      </div>
      <Routes>
        <Route
          path="/"
          element={<ChassisModelsTable chassisMarkId={filters.chassisMarkId} />}
        />
        <Route path="/add" element={<ChassisModelAdd />} />
        <Route path="/:id" element={<ChassisModelEdit />} />
      </Routes>
    </div>
  );
};
