import { FC, memo, useEffect, useState } from "react";
import { CustomSelect } from "../Select/CustomSelect";
import { getNoOptionsMessage } from "common/utils/helpers";
import { ISearchInputProps } from "common/models/components/input/ISearchInputProps";

const createSearchTimer = (props: {
  queryString: string;
  field?: string;
  query: (props: any) => void;
}): NodeJS.Timeout => {
  return setTimeout(() => {
    if (props.queryString?.length >= 5) {
      props.query({ query: props.queryString, field: props.field });
    }
  }, 500);
};

export const SearchInput: FC<ISearchInputProps> = memo(
  ({
    queryField,
    onChange,
    data,
    inputValue,
    value,
    onSearch,
    isLoading = false,
    ...props
  }) => {
    const [isFocused, setIsFocused] = useState(false);

    const options = data?.map((item) => ({
      label: item.name,
      value: item[queryField],
    }));

    useEffect(() => {
      const timeout =
        isFocused && inputValue
          ? createSearchTimer({
              queryString: inputValue,
              field: queryField,
              query: onSearch,
            })
          : undefined;
      return () => clearTimeout(timeout);
    }, [inputValue]);

    return (
      <CustomSelect
        className="w-full"
        options={options}
        noOptionsMessage={() =>
          getNoOptionsMessage({
            minLength: 5,
            string: inputValue,
            isLoading: isLoading,
          })
        }
        onChange={(val) => {
          onChange(val.label);
        }}
        onFocus={() => {
          if (value?.length >= 5) {
            onSearch({
              query: value,
              field: queryField,
            });
          }
          setIsFocused(true);
        }}
        blurInputOnSelect={true}
        onBlur={() => setIsFocused(false)}
        inputValue={inputValue}
        value={value}
        {...props}
      />
    );
  }
);
