import {
  createElement,
  FC,
  forwardRef,
  useEffect,
  useState,
  memo,
} from "react";
import ReactDatePicker, {
  CalendarContainer,
  CalendarContainerProps,
} from "react-datepicker";
import { SvgCalendar } from "../../../assets/icons/SvgCalendar";
import { IconInput } from "../Input/IconInput";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { ICustomReactDatePickerProps } from "common/models/components/input/ICustomReactDatePickerProps";

const CustomInput = (
  props: React.HTMLProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <IconInput
      ref={ref}
      {...props}
      value={props.value ? `${props.value}` : props.value}
      icon={<SvgCalendar className="h-[20px] w-[20px] text-gray" />}
    />
  );
};

const MyContainer = ({ className, children }: CalendarContainerProps) => {
  return (
    <div className="datepicker w-fit rounded bg-white shadow-lg">
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    </div>
  );
};

export const DatePicker: FC<ICustomReactDatePickerProps> = memo(
  ({ onStartDateChange, onEndDateChange, ...props }) => {
    const [dateRange, setDateRange] = useState<
      [Nullable<Date>, Nullable<Date>]
    >([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
      onStartDateChange && onStartDateChange(startDate);
    }, [startDate]);

    useEffect(() => {
      onEndDateChange && onEndDateChange(endDate);
    }, [endDate]);

    return (
      <ReactDatePicker
        selectsRange={true}
        className="h-[45px] w-full tracking-normal"
        dateFormat="d.MM.yyyy"
        startDate={startDate}
        endDate={endDate}
        calendarContainer={MyContainer}
        onSelect={(update, event) => {
          if (props.onChange) {
            props.onChange(update, event);
          } else {
            if (dateRange[0]) {
              setDateRange([dateRange[0], update]);
            } else {
              setDateRange([update, null]);
            }
          }
        }}
        onChange={(update, event) => {
          if (update[0] === null && update[1] === null) {
            setDateRange(update);
          }
        }}
        locale={ru}
        customInput={createElement(forwardRef(CustomInput))}
        {...props}
      />
    );
  }
);
