import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgHeadset: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.182 16H24.182C23.6515 16 23.1428 16.2107 22.7678 16.5858C22.3927 16.9609 22.182 17.4696 22.182 18V23C22.182 23.5304 22.3927 24.0391 22.7678 24.4142C23.1428 24.7893 23.6515 25 24.182 25H26.182C26.7124 25 27.2211 24.7893 27.5962 24.4142C27.9713 24.0391 28.182 23.5304 28.182 23V16ZM28.182 16C28.182 14.4165 27.8686 12.8486 27.2598 11.3868C26.651 9.92497 25.759 8.59808 24.635 7.48262C23.5111 6.36715 22.1775 5.48517 20.7111 4.88751C19.2447 4.28986 17.6745 3.98834 16.091 4.00034C14.5075 3.98834 12.9373 4.28986 11.4709 4.88751C10.0045 5.48517 8.6709 6.36715 7.54695 7.48262C6.423 8.59808 5.53093 9.92497 4.92217 11.3868C4.31341 12.8486 4 14.4165 4 16V23C4 23.5304 4.21071 24.0391 4.58579 24.4142C4.96086 24.7893 5.46957 25 6 25H8C8.53043 25 9.03914 24.7893 9.41421 24.4142C9.78929 24.0391 10 23.5304 10 23V18C10 17.4696 9.78929 16.9609 9.41421 16.5858C9.03914 16.2107 8.53043 16 8 16H4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.182 23V26C28.182 27.0609 27.7605 28.0783 27.0104 28.8284C26.2603 29.5786 25.2428 30 24.182 30H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
