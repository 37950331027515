import { IUser } from "../../common/models/user/IUser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserObj {
  current?: IUser;
  location?: { latitude: number; longitude: number };
  isReady: boolean;
}

const initialState: IUserObj = {
  isReady: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IUser>) => {
      state.current = action.payload;
      if (action.payload.access_token) {
        localStorage.setItem("token", action.payload.access_token);
      }
      state.isReady = true;
    },
    logout: (state) => {
      state.current = undefined;
      localStorage.removeItem("token");
      state.isReady = true;
    },
    guestLogin: (state) => {
      state.isReady = true;
    },
    setLocation: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>
    ) => {
      state.location = action.payload;
    },
    removeCurrentCompany: (state) => {
      if (state.current?.account?.company) {
        state.current.account.company = undefined;
      }
    },
  },
});

export const { login, logout, guestLogin, removeCurrentCompany, setLocation } =
  userSlice.actions;

export const userReducer = userSlice.reducer;
