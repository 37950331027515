import { invoicesReducer } from "./invoices/invoicesSlice";
import { companyReducer } from "./companies/companiesSlice";
import { homeReducer } from "./home/homeSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { employeeReducer } from "./employees/employeesSlice";
import { transactionReducer } from "./transactions/transactionsSlice";
import { mapReducer } from "./map/mapSlice";
import { orderReducer } from "./orders/ordersSlice";
import { appealReducer } from "./appeal/appealSlice";

export const customerCompanyManagerReducer = combineReducers({
  orders: orderReducer,
  employees: employeeReducer,
  home: homeReducer,
  companies: companyReducer,
  invoices: invoicesReducer,
  transactions: transactionReducer,
  appeal: appealReducer,
  map: mapReducer,
});
