import { useEffect } from "react";
import { useGlobalModalContext } from "./GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { Spinner } from "../Spinner/Spinner";

export const PaymentModal = () => {
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { price, orderId } = modalProps;

  const handleModalToggle = () => {
    hideModal();
  };

  const handleShowErrorModal = () => {
    handleModalToggle();
    showModal(MODAL_TYPES.ERROR_MODAL, {
      title: "Оплата не прошла",
      subTitle: "Вам необходимо сменить форму оплаты",
    });
  };

  const handleShowSuccessModal = () => {
    handleModalToggle();
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Оплата успешно прошла",
      subTitle: "Вы можете посмотреть детали транзакции в профиле",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleShowSuccessModal();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative grid w-[320px] gap-[25px] overflow-y-scroll p-[15px] rounded bg-white">
      <Spinner className="h-[50px] w-[50px] text-primary" />
      <div className="text-center text-lg">Оплата заказа</div>
      <div className="text-center text-sm text-gray">Сумма заказа</div>
      <div className="text-center text-xl font-semibold">{`${price} руб.`}</div>
    </div>
  );
};
