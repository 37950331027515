import { companyReducer } from "./companies/companiesSlice";
import { bankCardsReducer } from "./bankCards/bankCardsSlice";
import { homeReducer } from "./home/homeSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { orderReducer } from "../individual/orders/ordersSlice";
import { mapReducer } from "./map/mapSlice";
import { transactionReducer } from "./transactions/transactionsSlice";
import { appealReducer } from "./appeal/appealSlice";

export const individualReducer = combineReducers({
  orders: orderReducer,
  home: homeReducer,
  appeal: appealReducer,
  bankCards: bankCardsReducer,
  companies: companyReducer,
  map: mapReducer,
  transactions: transactionReducer,
});
