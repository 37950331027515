import { useForm } from "react-hook-form";
import { Button } from "../Button/Button";
import { useGlobalModalContext } from "./GlobalModal";
import { TextArea } from "../TextArea/TextArea";
import { Cause } from "../Cause/Cause";
import { MODAL_TYPES } from "common/utils/consts";

export const OrderPauseModal = () => {
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { orderId } = modalProps;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ reason: string }>();

  const handleModalToggle = () => {
    hideModal();
  };

  const onSubmit = handleSubmit((data) => {
    handleModalToggle();
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: "Заказ приостанволен",
      subTitle:
        "Если таймер будет больше 7 часов то с вас будет списываться дополнительная сумма в размере 1000 рублей за 10 мин ",
    });
  });

  return (
    <div className="relative w-[320px] overflow-y-scroll p-[15px] rounded bg-white">
      <div className="mb-[25px] text-center text-lg">
        Приостановить выполнение заказа
      </div>
      <div className="mb-[25px] text-center text-sm text-gray">
        В случае завершения заказа с вами свяжется менеджер
      </div>
      <form onSubmit={onSubmit}>
        <div>
          <span className={`mb-[10px] block text-sm font-semibold text-gray`}>
            Причина
          </span>
          <ul className="mb-[5px] flex flex-wrap">
            <li>
              <Cause>Водитель пьян</Cause>
            </li>
            <li>
              <Cause>Плохие тормоза</Cause>
            </li>
            <li>
              <Cause>Прокуренный салон</Cause>
            </li>
          </ul>
        </div>
        <TextArea
          {...register("reason", { required: true })}
          invalid={!!errors.reason}
          className="mb-[25px] h-[125px] w-full resize-none"
          placeholder="Причина"
          label="По какой причине вы хотите приостановить выполнение заказа"
        />
        <Button className="w-full">Приостановить заказ</Button>
      </form>
    </div>
  );
};
