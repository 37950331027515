import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface OrdersState {
  filters: {
    searchText: string;
    dateFrom?: string;
    dateTo?: string;
  };
}

const initialState: OrdersState = {
  filters: {
    searchText: "",
  },
};

const usersSlice = createSlice({
  name: "administrator_users",
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },
    setDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateFrom = action.payload;
    },
    setDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.filters.dateTo = action.payload;
    },
  },
});

export const orderFiltersSelector = (state: RootState) =>
  state.administrator.orders.filters;

export const { setSearchText, setDateFrom, setDateTo } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
