import { FC, useState, useEffect } from "react";
import { SubTitle } from "../../../common/components/Title/SubTitle";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/components/Input/Input";
import { useGetVehicleTypeApiByIdQuery } from "services/vehicle/vehicleTypesApi";
import { VehicleTypeFormValues } from "common/models/forms/VehicleTypeFormValues";

export const VehiclePopup: FC = ({ row }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VehicleTypeFormValues>();

  const { data: type } = useGetVehicleTypeApiByIdQuery(
    row.original.vehicle_type.id,
    {
      skip: !row.original.vehicle_type.id,
    }
  );

  useEffect(() => {
    if (type) {
      reset({
        ...type,
        vehicle_category_id: type.category.id,
        city_id: type.city?.id || undefined,
        board_carrying_capacity: type.board_carrying_capacity || undefined,
        arrow_carrying_capacity: type.arrow_carrying_capacity || undefined,
        lifting_height: type.lifting_height || undefined,
      });
    }
  }, [type]);

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = handleSubmit((data) => {
    // update(data);
  });

  return (
    <div className="relative">
      {isOpen && (
        <form
          className="absolute top-[20px] left-0 z-10 grid w-[500px] gap-[15px] break-normal rounded bg-white p-[10px] shadow-lg"
          onSubmit={onSubmit}
        >
          <div className="grid gap-[10px]">
            <SubTitle>Основные данные</SubTitle>
            <Input
              {...register(`name`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Название тарифа *"
              invalid={!!errors.name}
              placeholder="Название тарифа"
            />
            <Input
              {...register(`price`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Цена смены *"
              invalid={!!errors.price}
              placeholder="Цена смены"
            />
            <Input
              {...register(`working_shift_hours`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Кол-во часов смены *"
              invalid={!!errors.working_shift_hours}
              placeholder="Кол-во часов смены"
            />
            <Input
              {...register(`vehicle_delivery_hours`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Кол-во часов подачи *"
              invalid={!!errors.vehicle_delivery_hours}
              placeholder="Кол-во часов подачи"
            />
            <Input
              {...register(`vehicle_delivery_price`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Стоимость пробега транспорта вне радиуса охвата за 1км (за радиус) *"
              invalid={!!errors.vehicle_delivery_price}
              placeholder="Стоимость пробега"
            />
            <Input
              {...register(`working_shift_overtime_price`, { required: true })}
              className="h-[25px] w-full px-[10px] text-[10px] placeholder:text-[10px]"
              label="Цена одного часа за переработку *"
              invalid={!!errors.working_shift_overtime_price}
              placeholder="Цена одного часа"
            />
          </div>
        </form>
      )}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center whitespace-nowrap text-primary"
      >
        {row.original.vehicle_type.name}
      </button>
    </div>
  );
};
