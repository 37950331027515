import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface OrdersState {
  isActiveOrders: boolean;
  filters: {
    vehicles: number[];
    period?: number[];
    timeAt?: string;
    timeTo?: string;
  };
  activeOrder?: number;
  vehicleAssignment: {
    activeOrderId?: number;
    assignedVehicleTypeId?: number;
    assignedVehicleId?: number;
    assignedVehicleCategoryId?: number;
    assignedDriverId?: number;
  };
}

const initialState: OrdersState = {
  isActiveOrders: true,
  filters: {
    vehicles: [],
    period: [1, 2, 3, 4, 5, 6],
  },
  vehicleAssignment: {},
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setActiveOrderId(state, action: PayloadAction<Undefinable<number>>) {
      state.activeOrder = action.payload;
      state.vehicleAssignment = {};
    },

    setAssigningOrderId(state, action: PayloadAction<number>) {
      if (state.vehicleAssignment.activeOrderId === action.payload)
        state.vehicleAssignment.activeOrderId = undefined;
      else state.vehicleAssignment.activeOrderId = action.payload;
    },
    setAssigningVehicleId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment.assignedVehicleId = action.payload;
    },
    setAssigningVehicleTypeId(
      state,
      action: PayloadAction<Undefinable<number>>
    ) {
      state.vehicleAssignment.assignedVehicleTypeId = action.payload;
    },
    setAssigningDriverId(state, action: PayloadAction<Undefinable<number>>) {
      state.vehicleAssignment.assignedDriverId = action.payload;
    },
    setAssigningVehicleCategoryId(
      state,
      action: PayloadAction<Undefinable<number>>
    ) {
      state.vehicleAssignment.assignedVehicleCategoryId = action.payload;
    },
    resetAssignment(state) {
      state.vehicleAssignment = {};
    },

    setIsActiveOrders(state, action: PayloadAction<boolean>) {
      state.isActiveOrders = action.payload;
    },

    setFilterVehicles(state, action: PayloadAction<number>) {
      let currentSelectedVehicles = state.filters.vehicles;

      if (currentSelectedVehicles.includes(action.payload)) {
        state.filters.vehicles = currentSelectedVehicles.filter(
          (el) => el !== action.payload
        );
      } else {
        state.filters.vehicles = [action.payload, ...currentSelectedVehicles];
      }
    },
    setFilterPeriod(state, action: PayloadAction<Undefinable<number[]>>) {
      state.filters.period = action.payload;
    },
    setFilterTimeAt(state, action: PayloadAction<Nullable<Date>>) {
      state.filters.timeAt = action.payload?.toLocaleDateString("sv");
    },
    setFilterTimeTo(state, action: PayloadAction<Nullable<Date>>) {
      state.filters.timeTo = action.payload?.toLocaleDateString("sv");
    },
    resetFilter(state) {
      state.filters = {
        vehicles: [],
        period: [1, 2, 3, 4, 5, 6],
        timeAt: undefined,
        timeTo: undefined,
      };
    },
  },
});

export const orderFiltersSelector = (state: RootState) =>
  state.customerCompanyManager.orders.filters;
export const orderVehicleAssignmentSelector = (state: RootState) =>
  state.customerCompanyManager.orders.vehicleAssignment;

export const {
  setActiveOrderId,
  setAssigningVehicleId,
  setAssigningDriverId,
  setAssigningOrderId,
  resetAssignment,

  setAssigningVehicleTypeId,
  setIsActiveOrders,
  setAssigningVehicleCategoryId,
  setFilterVehicles,
  setFilterPeriod,
  setFilterTimeAt,
  setFilterTimeTo,
  resetFilter,
} = ordersSlice.actions;

export const orderReducer = ordersSlice.reducer;
