import { FC, memo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { SvgClock } from "assets/icons/SvgClock";
import { SvgQuestionCircle } from "assets/icons/SvgQuestionCircle";
import { IDetailedCompanyProfileCardProps } from "common/models/components/profile/IDetailedCompanyProfileCardProps";
import { ProfileCard } from "./ProfileCard";
import { Title } from "../Title/Title";
import { COMPANY_CATEGORY } from "common/utils/consts";

export const CompanyProfileCard: FC<IDetailedCompanyProfileCardProps> = memo(
  ({ company, ...props }) => {
    const userClasses = props.className || "";

    const isCustomerCompany = company.category.id === COMPANY_CATEGORY.CUSTOMER;

    return (
      <div
        {...props}
        className={`rounded border border-whiteGray p-[12px] ${userClasses}`}
      >
        <ProfileCard
          className="mb-[10px]"
          name={company.name}
          subname={company.category.name}
          isShortMode
          balance={company.balance}
          avatarurl={company.logotype?.path}
          primary
        />

        <div className="flex justify-between border-t border-whiteGray py-[5px]">
          <div className="flex flex-col justify-center">
            <span className="mb-[4px] text-xs text-graySecond">
              Основной баланс
            </span>
            <Title>{`${company.balance} руб.`}</Title>
          </div>
          {isCustomerCompany && (
            <div className="flex flex-col justify-center">
              <span className="mb-[4px] text-xs text-graySecond">
                Остаток по кредиту
              </span>
              <Title>{`${company.credit_line} руб.`}</Title>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between border-t border-whiteGray pt-[5px] text-xs">
          <div className="flex items-center text-[8px] text-gray">
            <SvgClock className="mr-[5px] h-[10px] w-[10px]" />
            <time>{timeFormatter({ time: new Date(company.created_at) })}</time>
          </div>
          {isCustomerCompany && (
            <div className="flex items-center text-[8px] text-red">
              <SvgQuestionCircle className="mr-[5px] h-[10px] w-[10px]" />
              <div>Кредитная линия до {`${company.credit_line} руб.`}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
