import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgArrowInCircle: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 16.1719L21.5 16.1719"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 20.6719L21.5 16.1719L17 11.6719"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="31.25"
        y="31.4219"
        width="30.5"
        height="30.5"
        rx="15.25"
        transform="rotate(180 31.25 31.4219)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
