import { IInputColorPalette } from "common/models/components/input/IInputColorPalit";
import { IIconInputProps } from "common/models/components/input/IInputProps";
import { FC, forwardRef } from "react";

export const IconInput: FC<IIconInputProps> = forwardRef<
  HTMLInputElement,
  IIconInputProps
>(({ type = "text", className = "", placeholder, icon, ...props }, ref) => {
  const userClasses = className || "";

  const { valid, invalid } = props;

  let currentColorPalette = "";

  if (valid) {
    currentColorPalette = colorPalette.valid;
  } else if (invalid) {
    currentColorPalette = colorPalette.invalid;
  } else {
    currentColorPalette = colorPalette.normal;
  }

  return (
    <div className="relative flex items-center">
      {props.label && (
        <span
          className={`mb-[12px] block ${
            invalid ? "text-red" : "text-gray"
          } text-sm font-semibold`}
        >
          {props.label}
        </span>
      )}
      <input
        ref={ref}
        type={type}
        placeholder={placeholder}
        {...props}
        className={`flex items-center justify-center rounded border-[2px] bg-lightWhiteGray 
            py-[15px] pr-[20px] pl-[45px] text-[13px] leading-[16px] tracking-wider 
            outline-none transition placeholder:text-[13px]
            ${currentColorPalette}
            ${userClasses}
          `}
      />
      {icon && <div className="absolute left-[15px]">{icon}</div>}
    </div>
  );
});

const colorPalette: IInputColorPalette = {
  normal: "border-lightWhiteGray text-black placeholder:text-lightGray",
  invalid: "border-red text-red placeholder:text-red",
  valid: "border-primary text-primary placeholder:text-primary",
};
