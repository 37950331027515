import { FC, useState, memo } from "react";
import { SvgCaretUp } from "../../../assets/icons/SvgCaretUp";
import { Title } from "../Title/Title";
import { IDropdownProps } from "common/models/components/ui/IDropDownProps";

export const Dropdown: FC<IDropdownProps> = memo(
  ({ isOpen = false, title, ...props }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(isOpen);

    const userClasses = props.className || "";

    const handleOnClick = () => {
      setIsDropdownOpen((prev) => !prev);
    };

    return (
      <div {...props} className={`${userClasses}`}>
        <button
          onClick={handleOnClick}
          className={`flex w-full items-center justify-between ${
            isDropdownOpen ? "mb-[25px]" : ""
          }`}
        >
          <Title>{title}</Title>
          <SvgCaretUp
            className={`h-[22px] w-[16px] ${
              isDropdownOpen ? "" : "rotate-180"
            }`}
          />
        </button>
        {isDropdownOpen && props.children}
      </div>
    );
  }
);
