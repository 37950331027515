import { api } from "../api";
import { ICancelledOrder } from "common/models/order/ICancelledOrder";
import { IFinishedOrder } from "common/models/order/IFinishedOrder";
import { IOrder } from "common/models/order/IOrder";

interface IQueryParams {
  date_from?: string;
  date_to?: string;
}

const adminOrdersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCancelledOrders: build.query<
      ICancelledOrder[],
      Undefinable<IQueryParams>
    >({
      query(params) {
        return {
          url: `/admin/orders`,
          method: "GET",
          params: {
            ...params,
            order_status_id: 9,
          },
        };
      },
      transformResponse: (result: { orders: ICancelledOrder[] }) =>
        result.orders,
    }),
    getFinishedOrders: build.query<IFinishedOrder[], Undefinable<IQueryParams>>(
      {
        query(params) {
          return {
            url: `/admin/orders`,
            method: "GET",
            params: {
              ...params,
              order_status_id: 8,
            },
          };
        },
        transformResponse: (result: { orders: IFinishedOrder[] }) =>
          result.orders,
      }
    ),
    getOrdersVisor: build.query<IOrder[], Undefinable<IQueryParams>>({
      query(params) {
        return {
          url: `/admin/orders`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { orders: IOrder[] }) => result.orders,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCancelledOrdersQuery,
  useGetFinishedOrdersQuery,
  useGetOrdersVisorQuery,
} = adminOrdersApi;
