import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IDesktopState {
  activeAppealId?: number;
  appealStatus: number;
  appealsDateFrom?: string;
  appealsDateTo?: string;
  messageText: string;
}

const initialState: IDesktopState = {
  messageText: "",
  appealStatus: 1,
};

const desktopSlice = createSlice({
  name: "desktop",
  initialState,
  reducers: {
    setActiveAppealId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeAppealId === action.payload)
        state.activeAppealId = undefined;
      else state.activeAppealId = action.payload;
    },
    setAppealStatus(state, action: PayloadAction<number>) {
      state.appealStatus = action.payload;
    },
    setAppealsDateFrom(state, action: PayloadAction<Undefinable<string>>) {
      state.appealsDateFrom = action.payload;
    },
    setAppealsDateTo(state, action: PayloadAction<Undefinable<string>>) {
      state.appealsDateTo = action.payload;
    },
    setMessageText(state, action: PayloadAction<string>) {
      state.messageText = action.payload;
    },
  },
});

export const {
  setActiveAppealId,
  setAppealStatus,
  setAppealsDateFrom,
  setAppealsDateTo,
  setMessageText,
} = desktopSlice.actions;

export const desktopReducer = desktopSlice.reducer;
