import {
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { logout } from "../features/user/userSlice";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { API_URL } from "../common/utils/consts";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders(headers) {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("authorization", "Bearer " + token);
    }
    headers.set("accept", "application/json");
    return headers;
  },
});

export const fetchBaseAuthQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};
