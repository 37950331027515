import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IDisputeState {
  activeDisputeId?: number;
  disputeAccountId?: number;
  disputeStatus: number;
  disputesDateFrom?: Nullable<string>;
  disputesDateTo?: Nullable<string>;
}

const initialState: IDisputeState = {
  disputeStatus: 1,
};

const disputeSlice = createSlice({
  name: "dispute",
  initialState,
  reducers: {
    setDisputeStatus(state, action: PayloadAction<number>) {
      state.disputeStatus = action.payload;
    },
    setDisputeAccountId(state, action: PayloadAction<Undefinable<number>>) {
      state.disputeAccountId = action.payload;
    },
    setAvtiveDisputeId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeDisputeId === action.payload) {
        state.activeDisputeId = undefined;
      } else state.activeDisputeId = action.payload;
    },
    setDisputesDateFrom(state, action: PayloadAction<Nullable<string>>) {
      state.disputesDateFrom = action.payload;
    },
    setDisputesDateTo(state, action: PayloadAction<Nullable<string>>) {
      state.disputesDateTo = action.payload;
    },
  },
});

export const {
  setDisputeStatus,
  setDisputeAccountId,
  setAvtiveDisputeId,
  setDisputesDateFrom,
  setDisputesDateTo,
} = disputeSlice.actions;

export const disputeReducer = disputeSlice.reducer;
