import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { KmuModelPage } from "../../kmuModels/KmuModelPage";
import { OptionsPage } from "../../options/OptionsPage";

export const CransPage: FC = () => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route
          path="/kmu-models/*"
          element={<KmuModelPage vehicleCategoryId={3} />}
        />
        <Route
          path="/options/*"
          element={<OptionsPage vehicleCategoryId={3} />}
        />
      </Routes>
    </div>
  );
};
