import Select from "react-select";
import { FC, memo } from "react";
import { components } from "react-select";
import { SvgUsers } from "../../../assets/icons/SvgUsers";
import { ICustomSelectProps } from "common/models/components/input/ICustomSelectProps";
import { SvgCrosshair } from "assets/icons/SvgCrosshair";

export const CustomSelect: FC<ICustomSelectProps> = memo((props) => {
  return (
    <div className="h-fit">
      {props.label && (
        <span
          className={`mb-[12px] block ${
            props.invalid ? "text-red" : "text-gray"
          } text-sm font-semibold`}
        >
          {props.label}
        </span>
      )}
      <Select
        {...props}
        ref={props.reference}
        components={{
          IndicatorSeparator,
          ...props.components,
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            transition: "all .3s",
            borderWidth: 2,
            borderColor: props.invalid
              ? "#F2735F"
              : state.isFocused
              ? "#FFA321"
              : "transparent",
            backgroundColor: "#F9F9F9",
            outline: "transparent",
            fontSize: "13px",
            textAlign: "left",
            // letterSpacing: ".3px",
            padding: "5px 9px",
            cursor: "pointer",
            color: "#0D2339",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            transition: "all .3s",
            ":hover": {
              backgroundColor: state.isFocused ? "#FFA321" : "transparent",
              color: state.isFocused ? "#ffffff" : "#0D2339",
            },
          }),
          // menu: (baseStyles, state) => ({
          //   ...baseStyles,
          //   position: "static",
          // }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "5px",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: "#0D2339",
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            overflowY: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
            input: {
              opacity: "1 !important",
            },
          }),
        }}
      />
      {props.invalidMessage && (
        <span className={`mt-[5px] block text-sm text-red`}>
          {props.invalidMessage}
        </span>
      )}
    </div>
  );
});

const { SingleValue, NoOptionsMessage } = components;

export function UserTypeSingleValue(props: any) {
  const {
    data: { label },
  } = props;

  return (
    <SingleValue {...props}>
      <div className="flex items-center gap-2">
        <SvgUsers className="h-[20px] w-[20px]" />
        <span>{label}</span>
      </div>
    </SingleValue>
  );
}

export function RadiusSingleValue(props: any) {
  const {
    data: { label },
  } = props;

  return (
    <SingleValue {...props}>
      <div className="flex items-center gap-2">
        <SvgCrosshair className="h-[20px] w-[20px]" />
        <span className="font-semibold tracking-wide text-gray">
          Радиус {label} км
        </span>
      </div>
    </SingleValue>
  );
}

export function CustomNoOptionsMessage(props: typeof NoOptionsMessage) {
  return (
    <NoOptionsMessage {...props.arguments}>
      <p className="flex items-center gap-2">Нет подходящих вариантов</p>
    </NoOptionsMessage>
  );
}

const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  backgroundColor: "transparent",
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = () => {
  return <span style={indicatorSeparatorStyle} />;
};
