import { FC, memo } from "react";
import { SvgError } from "assets/icons/SvgError";
import { IDivProps } from "common/models/components/ui/IDivProps";

export const Error: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div
      {...props}
      className={`flex flex-col items-center text-red ${userClasses || ""}`}
    >
      <SvgError className={`mb-[15px] h-[60px] w-[60px]`} />
      <p className="text-center text-base">
        {props.children || "Возникла ошибка, повторите попытку позже"}
      </p>
    </div>
  );
});
