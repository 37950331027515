import { IAlertProps } from "common/models/components/ui/IAlertProps";
import { FC, memo } from "react";

export const Alert: FC<IAlertProps> = memo(
  ({ className, children, variant = "filled", mode = "warning", ...props }) => {
    const userClasses = className || "";

    const modeColor = modeColorPalette[mode];
    const variantColor = varianrColorPalette[variant];

    return (
      <div
        {...props}
        className={`text-center font-medium text-white ${userClasses}`}
      >
        <p
          className={`whitespace-nowrap rounded border py-[15px] ${modeColor} ${variantColor}`}
        >
          {children}
        </p>
      </div>
    );
  }
);

const modeColorPalette = {
  warning: "bg-primary border-primary text-white",
  info: "bg-lightGray border-lightGray text-lightGray",
  success: "bg-green-500 border-green-500 text-green-500 text-white",
  error: "bg-red border-red text-white",
};

const varianrColorPalette = {
  filled: "text-inherit",
  outlined: "bg-inherit text-black",
};
