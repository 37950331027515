import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "../../../../../common/components/Input/Input";

export const KmuMarkFormInputs: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{
    name: string;
  }>();

  return (
    <Input
      {...register(`name`, { required: true })}
      className="w-full"
      label="Название марки"
      invalid={!!errors.name}
      placeholder="Название"
    />
  );
};
