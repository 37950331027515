import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TransactionsState {
  activeCardlId?: number;
}

const initialState: TransactionsState = {};

const bankCardsSlice = createSlice({
  name: "bank_card",
  initialState,
  reducers: {
    setActiveBankCardId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeCardlId === action.payload)
        state.activeCardlId = undefined;
      else state.activeCardlId = action.payload;
    },
  },
});

export const { setActiveBankCardId } = bankCardsSlice.actions;

export const bankCardsReducer = bankCardsSlice.reducer;
