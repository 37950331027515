import { ISvgProps } from "common/models/components/ui/ISvgProps";
import { FC } from "react";

export const SvgLogo: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.0995 38.6484C59.7118 46.8216 54.6111 53.7171 47.5977 57.5501V65.7984C51.742 64.1262 55.6238 61.6273 58.9617 58.264C64.4562 52.7589 67.6817 45.8258 68.638 38.6484H61.0995Z"
        fill="currentColor"
      />
      <path
        d="M8.2544 34.119C8.2544 25.2694 12.5675 17.4156 19.2059 12.5681V3.8125C16.168 5.39077 13.3176 7.43875 10.786 9.99404C2.92867 17.8666 -0.278012 28.6326 1.14718 38.8725H8.68571C8.40442 37.3319 8.2544 35.7348 8.2544 34.119Z"
        fill="currentColor"
      />
      <path
        d="M12.3617 48.3418H3.9043C5.55452 51.9305 7.84233 55.2937 10.7865 58.2623C18.2688 65.7591 28.3576 69.0471 38.1277 68.1077V60.5921C37.0588 60.7237 35.9712 60.7988 34.8648 60.7988C25.3947 60.7988 17.0873 55.8198 12.3617 48.3418Z"
        fill="currentColor"
      />
      <path
        d="M11.8555 30.9063C13.2619 32.8979 14.4621 34.8332 15.3247 36.6557H15.2872C15.831 40.9396 17.7438 45.0919 21.0442 48.38C28.9391 56.2901 41.7658 56.2901 49.6606 48.38C57.5555 40.4699 57.5555 27.6183 49.6606 19.7082C46.4164 16.4577 42.3471 14.56 38.1466 13.9775V7.66448C49.6606 9.07365 58.9056 17.8668 61.0434 29.1777H68.6007C67.5693 22.1507 64.3814 15.4055 58.9806 9.9943C50.7295 1.74599 39.328 -1.39176 28.6765 0.562286V3.24909V8.19057V23.4847C28.6765 25.3448 30.7956 26.8667 33.4022 26.8667C35.9713 26.8667 38.0716 25.3824 38.1278 23.5599C39.9093 24.0296 41.597 24.969 42.9847 26.3594C47.204 30.5869 47.204 37.4449 42.9847 41.6911C38.7654 45.9186 31.9207 45.9186 27.6826 41.6911C26.2574 40.2632 25.3011 38.5158 24.851 36.6933C24.8323 36.2424 24.8323 35.7726 24.8323 35.3405C24.851 27.7686 8.17999 25.683 11.8555 30.9063Z"
        fill="currentColor"
      />
    </svg>
  );
};
