import { FC } from "react";
import { Input } from "../../../../common/components/Input/Input";
import { useFormContext } from "react-hook-form";

export const CounterAgentFormInputs: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{
    name: string;
    desc: string;
  }>();

  return (
    <>
      <Input
        {...register(`name`, { required: true })}
        className="w-full"
        label="Название *"
        invalid={!!errors.name}
        placeholder="Название"
      />
      <Input
        {...register(`desc`, { required: true })}
        className="w-full"
        label="Описание *"
        invalid={!!errors.desc}
        placeholder="Описание"
      />
    </>
  );
};
