import { IDivProps } from "common/models/components/ui/IDivProps";
import { FC, memo } from "react";

export const Container: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div
      {...props}
      className={`absolute top-0 bottom-0 left-0 right-0 z-10 grid h-full grid-cols-[375px,1fr] gap-[15px] bg-white p-[15px] ${userClasses}`}
    >
      {props.children}
    </div>
  );
});
