import { SvgError } from "../../../assets/icons/SvgError";
import { Button } from "../Button/Button";
import { useGlobalModalContext } from "./GlobalModal";

export const ErrorModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { subTitle, title } = modalProps;

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <div className="min-w-[325px] p-[25px] text-center rounded bg-white">
      <div className="mb-[25px] flex justify-center ">
        <SvgError className="h-[50px] w-[50px]" />
      </div>
      <p className="m-auto mb-[25px] w-[80%] text-lg">{title}</p>
      {subTitle && (
        <p className="m-auto mb-[25px] w-[80%] text-sm font-semibold text-lightGray">
          {subTitle}
        </p>
      )}
      <Button
        className="m-auto block text-base font-medium tracking-wider"
        onClick={handleModalToggle}
      >
        Продолжить
      </Button>
    </div>
  );
};
