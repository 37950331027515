import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TransactionsState {
  activeTransactionId?: number;
}

const initialState: TransactionsState = {};

const transactionsSlice = createSlice({
  name: "implementer_transactions",
  initialState,
  reducers: {
    setActiveTransactionId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeTransactionId === action.payload)
        state.activeTransactionId = undefined;
      else state.activeTransactionId = action.payload;
    },
  },
});

export const { setActiveTransactionId } = transactionsSlice.actions;

export const transactionReducer = transactionsSlice.reducer;
