import { combineReducers } from "@reduxjs/toolkit";
import { orderReducer } from "./orders/ordersSlice";
import { vehiclesReducer } from "./vehicles/vehiclesSlice";
import { chassisModelReducer } from "./directory/vehicles/chassisModels/chassisModelSlice";
import { usersReducer } from "./users/usersSlice";

export const administratorReducer = combineReducers({
  orders: orderReducer,
  vehicle: vehiclesReducer,
  chassisModel: chassisModelReducer,
  users: usersReducer,
});
