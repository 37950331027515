import { FC } from "react";
import { Input } from "../../../../../common/components/Input/Input";
import { Controller, useFormContext } from "react-hook-form";
import { CustomSelect } from "common/components/Select/CustomSelect";
import { useGetChassisMarksQuery } from "services/vehicle/chassisApi";

export const ChassisModelFormInputs: FC = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<{
    name: string;
    id: number;
    chassis_mark_id: number;
  }>();

  const { data: marks } = useGetChassisMarksQuery();

  const options = marks?.map((mark) => ({
    value: mark.id,
    label: mark.name,
  }));

  return (
    <>
      <Controller
        control={control}
        name="chassis_mark_id"
        rules={{ required: true }}
        render={(props) => {
          return (
            <CustomSelect
              className="w-full"
              label="Марка шасси *"
              reference={props.field.ref}
              options={options}
              placeholder="Выберите тип..."
              invalid={!!errors.chassis_mark_id}
              onChange={(val) => props.field.onChange(val?.value)}
              isSearchable={false}
              value={options?.find((c) => c.value === props.field.value)}
            />
          );
        }}
      />
      <Input
        {...register(`name`, { required: true, minLength: 2 })}
        className="w-full"
        label="Название *"
        invalid={!!errors.name}
        placeholder="Название"
      />
    </>
  );
};
