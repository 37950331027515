import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { TariffsTable } from "../../tables/tariffs/TariffsTable";
import { TariffsAdd } from "./TariffsAdd";
import { TariffsEdit } from "./TariffsEdit";

export const TariffsPage: FC = () => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route path="/" element={<TariffsTable />} />
        <Route path="/add" element={<TariffsAdd />} />
        <Route path="/:id" element={<TariffsEdit />} />
      </Routes>
    </div>
  );
};
