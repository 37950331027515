import { FC, useMemo } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";

export const PayoutsTable: FC = () => {
  return <Table data={data} columns={columns} />;
};

const columns: Column<IPayoutsData>[] = [
  {
    Header: "#",
    accessor: "id",
    Footer: "Итого",
  },
  {
    Header: "ФИО",
    accessor: "firstName",
    Cell: ({ row }: any) => (
      <span>
        {`${row.original.lastName} ${row.values.firstName} ${row.original.middleName}`}
      </span>
    ),
  },
  {
    Header: "Название организации",
    accessor: "company",
  },
  {
    Header: "Завершённые заказы",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.completedOrdersAmount + sum,
            0
          ),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "completedOrdersAmount",
  },
  {
    Header: "Сумма, руб.",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.sum + sum, 0).toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "sum",
  },
  {
    Header: "Выведено, руб.",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows
            .reduce((sum, row) => row.values.withdraw + sum, 0)
            .toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "withdraw",
  },
  {
    Header: "Баланс, руб.",
    accessor: "balance",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows
            .reduce((sum, row) => row.values.balance + sum, 0)
            .toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
    Cell: ({ row }: any) => (
      <span
        className={`${row.values.balance > 0 ? "text-primary" : "text-red"}`}
      >
        {row.values.balance.toFixed(2)}
      </span>
    ),
  },
];

interface IPayoutsData {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  company: string;
  completedOrdersAmount: number;
  sum: number;
  withdraw: number;
  balance: number;
}

const data: IPayoutsData[] = [
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    completedOrdersAmount: 15,
    sum: 534650,
    withdraw: 67890,
    balance: 452650,
  },
];
