import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgPhone: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5595 15.6025C12.5968 17.7232 14.3158 19.4344 16.4412 20.462C16.5967 20.5357 16.7687 20.5676 16.9403 20.5546C17.1119 20.5417 17.2771 20.4842 17.4198 20.388L20.5492 18.3012C20.6877 18.2089 20.8469 18.1526 21.0126 18.1374C21.1782 18.1221 21.3451 18.1485 21.498 18.214L27.3526 20.7231C27.5515 20.8076 27.7175 20.9545 27.8257 21.1415C27.9339 21.3286 27.9783 21.5457 27.9524 21.7602C27.7673 23.2082 27.0608 24.5391 25.9652 25.5038C24.8695 26.4684 23.4598 27.0005 22 27.0006C17.4913 27.0006 13.1673 25.2095 9.97919 22.0214C6.79107 18.8333 5 14.5093 5 10.0006C5.00008 8.54083 5.53224 7.13113 6.49685 6.03546C7.46146 4.93979 8.79237 4.23328 10.2404 4.04824C10.4549 4.02228 10.672 4.06673 10.8591 4.17491C11.0461 4.28309 11.193 4.44913 11.2775 4.64801L13.7888 10.5077C13.8537 10.6593 13.8802 10.8246 13.8658 10.9889C13.8514 11.1531 13.7967 11.3113 13.7064 11.4493L11.6268 14.6267C11.5322 14.7697 11.4762 14.9347 11.4644 15.1058C11.4526 15.2768 11.4854 15.4479 11.5595 15.6025V15.6025Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
