import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgSuccess: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="24.745"
        cy="24.745"
        r="23.245"
        stroke="#FFA321"
        strokeWidth="3"
      />
      <path
        d="M34.25 19.75L23.75 30.2495L18.5 25"
        stroke="#FFA321"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
