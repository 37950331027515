import { FC, useMemo } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";

export const CreditTable: FC = () => {
  return <Table data={data} columns={columns} />;
};

const columns: Column<IPayoutsData>[] = [
  {
    Header: "#",
    accessor: "id",
    Footer: "Итого",
  },
  {
    Header: "ФИО",
    accessor: "firstName",
    Cell: ({ row }: any) => (
      <span className="text-primary">
        {`${row.original.lastName} ${row.values.firstName} ${row.original.middleName}`}
      </span>
    ),
  },
  {
    Header: "Название организации",
    accessor: "company",
  },
  {
    Header: "Кредит, руб.",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.credit + sum, 0).toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "credit",
  },
  {
    Header: "Завершённые заказы",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.values.completedOrdersAmount + sum,
            0
          ),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "completedOrdersAmount",
  },
  {
    Header: "Сумма, руб.",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.sum + sum, 0).toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
    accessor: "sum",
  },
  {
    accessor: "paid",
    Header: "Оплачено, руб.",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.paid + sum, 0).toFixed(2),
        [info.rows]
      );

      return <>{total}</>;
    },
  },
  {
    Header: "Долг, руб.",
    accessor: "debt",
    Footer: (info) => {
      const total = useMemo(
        () =>
          info.rows.reduce((sum, row) => row.values.debt + sum, 0).toFixed(2),
        [info.rows]
      );

      return <span className="text-red">{total}</span>;
    },
    Cell: ({ row }: any) => (
      <span className="text-red">{row.values.debt.toFixed(2)}</span>
    ),
  },
];

interface IPayoutsData {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  company: string;
  credit: number;
  completedOrdersAmount: number;
  sum: number;
  paid: number;
  debt: number;
}

const data: IPayoutsData[] = [
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
  {
    id: 11233,
    firstName: "Андрей",
    lastName: "Марченков",
    middleName: "Николаевич",
    company: "ОО Рога и Копыта",
    credit: 145870,
    completedOrdersAmount: 15,
    sum: 534650,
    paid: 67890,
    debt: 77980,
  },
];
