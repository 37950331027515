import { ICreateEmployeeInputs } from "../common/models/api/ICreateEmployeeInputs";
import { IAccount } from "../common/models/user/IAccount";
import { IEmployee } from "../common/models/companies/employee/IEmployee";
import { IRole } from "../common/models/companies/employee/IRole";
import { IUserPermission } from "../common/models/companies/employee/IUserPermission";
import { api } from "./api";

export const employeeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyEmployees: builder.query<
      IAccount[],
      {
        companyId: number;
        roleId?: number;
        query?: string;
      }
    >({
      query({ companyId, roleId, query }) {
        return {
          url: `/companies/${companyId}/accounts`,
          params: {
            role_id: roleId,
            query,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Employee", id } as const)),
              { type: "Employee", id: "LIST" },
              "Auth",
            ]
          : [{ type: "Employee", id: "LIST" }],
      transformResponse: (result: { accounts: IAccount[] }) => result.accounts,
    }),
    getEmployeeById: builder.query<
      IAccount,
      { employeeId: number; companyId: number }
    >({
      query({ employeeId, companyId }) {
        return {
          url: `/companies/${companyId}/accounts/${employeeId}`,
        };
      },
      providesTags: (result, error, arg) => [
        { type: "Employee", id: arg.employeeId },
      ],
      transformResponse: (result: { account: IAccount }) => result.account,
    }),
    createEmployee: builder.mutation<IAccount, ICreateEmployeeInputs>({
      query({ company_id, ...body }) {
        return {
          url: `/companies/${company_id}/accounts`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error) =>
        !error?.status ? [{ type: "Employee", id: "LIST" }] : [],
      transformResponse: (result: { account: IAccount }) => result.account,
    }),
    updateEmployee: builder.mutation<
      IAccount,
      { employeeId: number; companyId: number; permissions: number[] }
    >({
      query({ companyId, employeeId, ...body }) {
        return {
          url: `/companies/${companyId}/accounts/${employeeId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Employee", id: arg.employeeId }] : [],
      transformResponse: (result: { account: IAccount }) => result.account,
    }),
    deleteEmployee: builder.mutation<
      IEmployee,
      { employeeId: number; companyId: number }
    >({
      query({ companyId, employeeId }) {
        return {
          url: `/companies/${companyId}/accounts/${employeeId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) =>
        !error?.status ? [{ type: "Employee", id: arg.employeeId }] : [],
    }),
    getEmployeeRoles: builder.query<IRole[], { applicationId?: number }>({
      query({ applicationId }) {
        return {
          url: `/roles`,
          params: { application_id: applicationId },
        };
      },
      transformResponse: (result: { roles: IRole[] }) => result.roles,
    }),
    getEmployeeRolePermissions: builder.query<
      IUserPermission[],
      { roleId: number; companyCategoryId: number }
    >({
      query({ roleId, companyCategoryId }) {
        return {
          url: `/permissions`,
          params: { role_id: roleId, company_category_id: companyCategoryId },
        };
      },
      transformResponse: (result: { permissions: IUserPermission[] }) =>
        result.permissions,
    }),
  }),
});

export const {
  useDeleteEmployeeMutation,
  useGetCompanyEmployeesQuery,
  useGetEmployeeByIdQuery,
  useGetEmployeeRolesQuery,
  useGetEmployeeRolePermissionsQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} = employeeApi;
