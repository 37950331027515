import { FC } from "react";
import { Button } from "../../../../common/components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { CounterAgentFormInputs } from "./CounterAgentFormInputs";

export const CounterAgentAdd: FC = () => {
  const methods = useForm<{
    name: string;
    desc: string;
  }>();

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {});

  return (
    <form className="grid gap-[15px]" onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <CounterAgentFormInputs />
      </FormProvider>
      <Button className="w-fit">Сохранить</Button>
    </form>
  );
};
