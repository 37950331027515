import { Swiper, SwiperSlide } from "swiper/react";
import { useGlobalModalContext } from "./GlobalModal";
import { IImage } from "common/models/commonModels/IImage";
import { SvgClose } from "assets/icons/SvgClose";

import "swiper/css";

export const ImagesSliderModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { images, activeImage } = modalProps;

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <div className="h-[80vh] w-full">
      <button
        autoFocus
        onClick={handleModalToggle}
        className="pointer-events-auto absolute top-[15px] right-[15px] flex h-[44px] w-[44px] items-center justify-center rounded border border-transparent bg-lightWhiteGray shadow-md
        outline-none transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary active:text-black"
      >
        <SvgClose className="h-[23px] w-[23px]" />
      </button>
      <div
        className="modal m-auto h-full cursor-pointer overflow-hidden"
        onClick={handleModalToggle}
      >
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          centeredSlides={true}
          initialSlide={activeImage}
          className="h-full"
        >
          {images.map((image: IImage | string, i: number) => (
            <SwiperSlide
              key={`slider_image_${i}`}
              className="m-auto cursor-default overflow-hidden rounded"
            >
              <img
                className="h-full w-full overflow-hidden rounded object-cover"
                src={typeof image === "object" ? image?.path : image}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                alt={typeof image === "object" ? image?.filename : "Картинка"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
