import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TransactionsState {
  invoiceStatus: number;
  activeInvoiceId?: number;
}

const initialState: TransactionsState = {
  invoiceStatus: 0,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setActiveInvoiceId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeInvoiceId === action.payload)
        state.activeInvoiceId = undefined;
      else state.activeInvoiceId = action.payload;
    },
    setInvoiceStatus(state, action: PayloadAction<number>) {
      state.invoiceStatus = action.payload;
    },
  },
});

export const { setActiveInvoiceId, setInvoiceStatus } = invoicesSlice.actions;

export const invoicesReducer = invoicesSlice.reducer;
