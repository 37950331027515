import Pusher from "pusher-js";
import { IApiMessage } from "./../common/models/api/IApiMessage";
import { IUserEditInputs } from "../common/models/api/IUserEditInputs";
import { IBankCard } from "./../common/models/bank/IBankCard";
import { IAccount } from "../common/models/user/IAccount";
import { IBonus } from "./../common/models/commonModels/IBonus";
import { IUser } from "../common/models/user/IUser";
import { api } from "./api";

const userApi = api
  .enhanceEndpoints({ addTagTypes: ["BankCard"] })
  .injectEndpoints({
    endpoints: (build) => ({
      updateUser: build.mutation<IUser, IUserEditInputs>({
        query(data) {
          return {
            url: `/users/${data.user_id}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Auth" },
          { type: "Order" },
        ],
        transformResponse: (result: { user: IUser }) => result.user,
      }),
      confirmInvite: build.mutation<IApiMessage, number>({
        query(accountId) {
          return {
            url: `/auth/user/accounts/${accountId}/confirm`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, id) =>
          !error?.status ? [{ type: "Account", id }] : [],
      }),
      rejectInvite: build.mutation<IApiMessage, number>({
        query(accountId) {
          return {
            url: `/auth/user/accounts/${accountId}/reject`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, id) =>
          !error?.status ? [{ type: "Account", id }, "Auth"] : [],
      }),
      getUserAccounts: build.query<IAccount[], string | void>({
        query(query) {
          return {
            url: `/auth/user/accounts`,
            params: { query },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Account", id } as const)),
                { type: "Account", id: "LIST" },
                "User",
                "Company",
              ]
            : [{ type: "Account", id: "LIST" }, "User", "Company"],
        transformResponse: (result: { accounts: IAccount[] }) =>
          result.accounts,
      }),
      getUserById: build.query<IUser, number>({
        query(id) {
          return {
            url: `/users/${id}`,
          };
        },
        providesTags: (result, error, arg) =>
          !error?.status ? [{ type: "User", id: arg }] : [],
        transformResponse: (result: { user: IUser }) => result.user,
      }),
      getUserBankCards: build.query<IBankCard[], number>({
        query() {
          return {
            url: `/bank_cards`,
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "BankCard", id } as const)),
                { type: "BankCard", id: "LIST" },
              ]
            : [{ type: "BankCard", id: "LIST" }],
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheEntryRemoved, cacheDataLoaded }
        ) {
          const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
            authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
            auth: {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          });
          await cacheDataLoaded;
          const channel = pusher.subscribe(`private-users.${arg}`);
          channel.bind(
            "App\\Events\\BankCardCreated",
            (data: { bankCard: IBankCard }) => {
              updateCachedData((draft) =>
                draft ? [...draft, data.bankCard] : [data.bankCard]
              );
            }
          );
          await cacheEntryRemoved;
          pusher.unsubscribe(`private-users.${arg}`);
        },
        transformResponse: (result: { bankCards: IBankCard[] }) =>
          result.bankCards,
      }),
      deleteBankCard: build.mutation<IApiMessage, number>({
        query(cardId) {
          return {
            url: `/bank_cards/${cardId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, id) =>
          !error?.status ? [{ type: "BankCard", id }] : [],
      }),
      addBankCard: build.mutation<
        {
          success: boolean;
          message: string;
          redirect_link: string;
        },
        void
      >({
        query() {
          return {
            url: `/bank_cards`,
            method: "POST",
          };
        },
      }),
      getAllUsers: build.query<IUser[], string>({
        query(query) {
          return {
            url: `/users/search`,
            params: {
              query,
            },
          };
        },
        providesTags: (result) =>
          result ? [{ type: "User", id: "LIST" }] : [],
        transformResponse: (result: { users: IUser[] }) => result.users,
      }),
      banUser: build.mutation<
        IUser,
        { isBanned: boolean; bannedTime: number; userId: number }
      >({
        query({ isBanned, bannedTime, userId }) {
          return {
            url: `/users/${userId}/banned`,
            method: "POST",
            body: {
              is_banned: isBanned,
              banned_time: bannedTime,
            },
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "User", id: arg.userId }] : [],
        transformResponse: (result: { user: IUser }) => result.user,
      }),
      giveBonuses: build.mutation<
        IBonus,
        { comment: string; value: number; userId: number }
      >({
        query({ comment, value, userId }) {
          return {
            url: `/bonuses`,
            method: "POST",
            body: {
              user_id: userId,
              comment,
              value,
            },
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "User", id: arg.userId }] : [],
        transformResponse: (result: { bonus: IBonus }) => result.bonus,
      }),
    }),
    overrideExisting: false,
  });

export const {
  useUpdateUserMutation,
  useDeleteBankCardMutation,
  useAddBankCardMutation,
  useGetUserBankCardsQuery,
  useConfirmInviteMutation,
  useRejectInviteMutation,
  useGetUserAccountsQuery,
  useGetUserByIdQuery,
  useBanUserMutation,
  useGiveBonusesMutation,
  useGetAllUsersQuery,
} = userApi;
