import { useState } from "react";
import { MODAL_TYPES } from "../../utils/consts";
import { Button } from "../Button/Button";
import { DatePicker } from "../DatePicker/DatePicker";
import { Tabs } from "../Tabs/Tabs";
import { useGlobalModalContext } from "./GlobalModal";
import { useBanUserMutation } from "../../../services/userApi";
import { getErrorMessage, timeFormatter } from "../../utils/helpers";

type BanType = "hour" | "day" | "range";
interface IBanProps {
  type: BanType;
  range?: [Nullable<Date>, Nullable<Date>];
}

const getBanTime = ({ type, range }: IBanProps): number => {
  switch (type) {
    case "hour":
      return 60;
    case "day":
      return 60 * 24;
    case "range":
      if (range) {
        if (range[0] && range[1]) {
          const t2 = range[0].getTime();
          const t1 = range[1].getTime();
          return (t1 - t2) / 1000 / 60;
        }
      }
      return 0;
    default:
      return 0;
  }
};

export const BanModal = () => {
  const { showModal, hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { userId, isBanned, unbannedAt, onConfirm } = modalProps;

  const [activeBanPeriod, setActiveBanPeriod] = useState<BanType>("hour");
  const [dateRange, setDateRange] = useState<[Nullable<Date>, Nullable<Date>]>([
    null,
    null,
  ]);

  const [ban, { isLoading }] = useBanUserMutation();

  const handleAppealsDateFromChange = (date: Nullable<Date>) => {
    setDateRange([date, dateRange[1]]);
  };

  const handleAppealsDateToChange = (date: Nullable<Date>) => {
    setDateRange([dateRange[0], date]);
  };

  const handleModalToggle = () => {
    hideModal();
  };

  const handleOnConfirm = async () => {
    await ban({
      userId,
      isBanned: !isBanned,
      bannedTime: isBanned
        ? 0
        : getBanTime({ type: activeBanPeriod, range: dateRange }),
    })
      .unwrap()
      .then(() => {
        handleModalToggle();
        showModal(MODAL_TYPES.SUCCESS_MODAL, {
          title: "Пользователь успешно заблокирован",
        });
        onConfirm();
      })
      .catch((err) => {
        handleModalToggle();
        showModal(MODAL_TYPES.ERROR_MODAL, {
          title: getErrorMessage(err)?.data.message,
          subTitle:
            "Ошибка при блокировке пользователя, повторите попытку позже",
        });
      });
  };

  return (
    <div className="min-w-[325px]  rounded bg-white p-[25px] text-center">
      <div className="mb-[25px] text-lg font-medium">
        {isBanned ? "Разблокировка" : "Блокировка"}
      </div>
      {!isBanned && (
        <>
          <Tabs
            className="mb-[25px]"
            tabs={[
              { value: "hour", label: "Час" },
              { value: "day", label: "Сутки" },
              { value: "range", label: "Выбрать" },
            ]}
            value={activeBanPeriod}
            onTabClick={(value) => setActiveBanPeriod(value)}
          />
          {activeBanPeriod === "range" && (
            <div className="mb-[25px]">
              <DatePicker
                isClearable={false}
                excludeDateIntervals={[
                  { start: new Date(0), end: new Date(Date.now() - 86400000) },
                ]}
                placeholderText="Введите промежуток дат"
                onStartDateChange={handleAppealsDateFromChange}
                onEndDateChange={handleAppealsDateToChange}
              />
            </div>
          )}
        </>
      )}
      <p className="m-auto mb-[25px] w-[80%] text-sm font-semibold text-lightGray">
        {isBanned ? (
          <>
            Пользователь заблокирован до
            <br />
            <time>{timeFormatter({ time: unbannedAt })}</time>
          </>
        ) : (
          "Пользователь будет заблокирован"
        )}
      </p>
      <div className="grid grid-cols-2 gap-[15px]">
        <Button
          className="text-base font-medium tracking-wider"
          mode="darkBorder"
          onClick={handleModalToggle}
        >
          Отмена
        </Button>
        <Button
          className="text-base font-medium tracking-wider"
          onClick={handleOnConfirm}
          disabled={(activeBanPeriod === "range" && !dateRange[1]) || isLoading}
        >
          Ок
        </Button>
      </div>
    </div>
  );
};
