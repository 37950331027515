import { IDivProps } from "common/models/components/ui/IDivProps";
import { FC, memo } from "react";

export const ShadowContainer: FC<IDivProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <div className={`py-[5px] px-[5px] ${userClasses}`}>
      <div className="overflow-hidden overflow-y-scroll rounded py-[20px] px-[15px] shadow">
        {props.children}
      </div>
    </div>
  );
});
