import { FC, memo } from "react";
import { QueryLayout } from "../Layout/QueryLayout";
import { WideIconButton } from "../Button/WideIconButton";
import { SvgPlus } from "assets/icons/SvgPlus";
import { List } from "../List/List";
import { CustomInput } from "../Input/CustomInput";
import { Input } from "../Input/Input";
import { ProfileCard } from "../Profile/ProfileCard";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { useGetCompaniesQuery } from "services/companiesApi";
import { ICompanyListProps } from "common/models/components/company/ICompanyListProps";

export const CompaniesList: FC<ICompanyListProps> = memo(
  ({ onItemClick, onSearch, onAdd, searchText, activeItemId }) => {
    const {
      data: companies,
      isLoading,
      isFetching,
      error,
    } = useGetCompaniesQuery(
      searchText.trim().length >= 3 ? searchText : undefined
    );

    const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value);
    };

    return (
      <>
        <div className="flex items-center justify-between">
          <h4 className="mb-[12px] text-md font-semibold">Мои компании</h4>
          {!!companies?.length && (
            <button
              className="text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
              onClick={onAdd}
            >
              <SvgPlus className="h-[20px] w-[20px]" />
            </button>
          )}
        </div>
        <div className="sticky top-0">
          <Input
            className="mb-[15px] mt-[5px] w-full"
            placeholder="Поиск"
            value={searchText}
            onChange={handleOnSearch}
          />
        </div>

        <QueryLayout isLoading={isLoading} error={error}>
          {companies?.length === 0 && searchText.length === 0 && (
            <WideIconButton
              className="w-full text-sm font-medium text-gray"
              icon={<SvgPlus className="h-[16px] w-[16px] text-primary" />}
              onClick={onAdd}
            >
              У вас ещё ни одной компании
            </WideIconButton>
          )}

          {companies?.length !== 0 ? (
            <List className={`mb-[15px] ${isFetching ? "animate-pulse" : ""}`}>
              {companies?.map(
                (company) =>
                  company && (
                    <li key={company.id}>
                      <CustomInput
                        type="radio"
                        className="py-[10px]"
                        checked={company.id === activeItemId}
                        onClick={() => onItemClick(company?.id || -1)}
                        innerelement={
                          <ProfileCard
                            isShortMode
                            name={company.name}
                            avatarurl={company.logotype?.path}
                            subname={company.category.name}
                          />
                        }
                      />
                    </li>
                  )
              )}
            </List>
          ) : (
            !!searchText.length && <NotFoundTitle />
          )}
        </QueryLayout>
      </>
    );
  }
);
