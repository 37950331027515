import { FC } from "react";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const SvgSpinner: FC<ISvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8007 31.2363C36.3455 28.1355 37.8088 24.2976 37.9825 20.3045C38.0018 19.8605 37.6397 19.5 37.1953 19.5V19.5C36.7509 19.5 36.3926 19.8605 36.3715 20.3045C36.1989 23.9251 34.8651 27.4024 32.5566 30.2153C30.0776 33.236 26.6278 35.3037 22.7952 36.0661C18.9625 36.8284 14.9841 36.2383 11.5378 34.3962C8.09151 32.5541 5.39053 29.574 3.89511 25.9638C2.39968 22.3535 2.20234 18.3364 3.33669 14.5969C4.47104 10.8574 6.86692 7.62699 10.1161 5.45597C13.3652 3.28495 17.2667 2.30769 21.1556 2.69072C24.777 3.0474 28.1789 4.56311 30.8611 7.00124C31.1899 7.30018 31.6982 7.30178 32.0125 6.98753V6.98753C32.3267 6.67327 32.3279 6.16235 32.0003 5.86205C29.0538 3.16131 25.3053 1.48226 21.3133 1.08908C17.0539 0.669562 12.7807 1.73993 9.22195 4.11781C5.6632 6.49569 3.03904 10.034 1.7966 14.1297C0.554164 18.2255 0.770316 22.6254 2.40823 26.5796C4.04614 30.5339 7.00447 33.7979 10.7792 35.8155C14.5538 37.8332 18.9113 38.4795 23.1092 37.6445C27.307 36.8095 31.0854 34.5448 33.8007 31.2363Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
