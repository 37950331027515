import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { OptionEdit } from "./OptionEdit";
import { OptionAdd } from "./OptionAdd";
import { OptionsTable } from "features/administrator/tables/vehicles/OptionsTable";

export const OptionsPage: FC<{ vehicleCategoryId: number }> = ({
  vehicleCategoryId,
}) => {
  return (
    <div className="px-[5px]">
      <Routes>
        <Route
          path="/add"
          element={<OptionAdd vehicleCategoryId={vehicleCategoryId} />}
        />
        <Route path="/:id" element={<OptionEdit />} />
        <Route
          path="/"
          element={<OptionsTable vehicleCategoryId={vehicleCategoryId} />}
        />
      </Routes>
    </div>
  );
};
