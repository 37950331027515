import { FC } from "react";
import { SvgCaretUp } from "../../../../assets/icons/SvgCaretUp";
import { timeFormatter } from "../../../../common/utils/helpers";
import { Column } from "react-table";
import { DropdownTable } from "../../../../common/components/Table/DropdownTable";

export const AdminWorkTable: FC = () => {
  return <DropdownTable data={data} columns={columns} />;
};

const columns: Column<object>[] = [
  {
    Header: "Дата",
    accessor: "date",
    Cell: ({ row }: any) => (
      <>
        {row.values.date &&
          timeFormatter({ time: row.values.date, mode: "dd-Mth-yyyy" })}
      </>
    ),
  },
  {
    Header: "Кол-во",
    accessor: "amount",
  },
  {
    Header: "Вид ТС",
    accessor: "appealTo",
    Cell: ({ row }: any) =>
      row.canExpand && !row.values.date ? (
        <button
          className="flex h-full w-full items-center whitespace-nowrap text-left"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 10}px`,
            },
          })}
        >
          {row.values.appealTo.name} ({row.values.appealTo.amount})
          {row.isExpanded ? (
            <SvgCaretUp className="ml-[5px] h-[7px] w-[7px]" />
          ) : (
            <SvgCaretUp className="ml-[5px] h-[7px] w-[7px] rotate-180" />
          )}
        </button>
      ) : (
        <span
          className="whitespace-nowrap"
          style={{ paddingLeft: `${row.depth * 10}px` }}
        >
          {row.values.appealTo.name} ({row.values.appealTo.amount})
        </span>
      ),
  },
  {
    Header: "Сред. время рещения",
    accessor: "solutionTime",
    Cell: ({ row }: any) => <span>{row.values.solutionTime} часа</span>,
  },
  {
    Header: "Кол-во часов",
    accessor: "hoursAmount",
  },
  {
    id: "expander",
    Cell: ({ row }: any) => {
      return row.canExpand && row.values.date ? (
        <button
          className="w-full pr-[7px]"
          {...row.getToggleRowExpandedProps({
            style: { paddingLeft: `${row.depth * 2}rem` },
          })}
        >
          {row.isExpanded ? (
            <SvgCaretUp className="ml-auto h-[15px] w-[15px]" />
          ) : (
            <SvgCaretUp className="ml-auto h-[15px] w-[15px] rotate-180" />
          )}
        </button>
      ) : null;
    },
  },
];

interface IAdminWorkData {
  date?: Date;
  amount: number;
  appealTo: {
    name: string;
    amount: number;
  };
  solutionTime: number;
  hoursAmount: number;
  subRows?: IAdminWorkData[];
}

const data: IAdminWorkData[] = [
  {
    date: new Date(),
    amount: 500,
    appealTo: {
      name: "Команда",
      amount: 30,
    },
    solutionTime: 2,
    hoursAmount: 1000,
    subRows: [
      {
        amount: 100,
        appealTo: {
          name: "Бухгалтерия",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 200,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Фоменкова Анастасия",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
          {
            amount: 50,
            appealTo: {
              name: "Ирина Олеговна",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 50,
          },
        ],
      },
      {
        amount: 200,
        appealTo: {
          name: "Техподдержка",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 100,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Алексей Семёнов",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
      {
        amount: 300,
        appealTo: {
          name: "Споры",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 700,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Андрей Майоров",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
    ],
  },
  {
    date: new Date(),
    amount: 500,
    appealTo: {
      name: "Команда",
      amount: 30,
    },
    solutionTime: 2,
    hoursAmount: 1000,
    subRows: [
      {
        amount: 100,
        appealTo: {
          name: "Бухгалтерия",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 200,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Фоменкова Анастасия",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
          {
            amount: 50,
            appealTo: {
              name: "Ирина Олеговна",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 50,
          },
        ],
      },
      {
        amount: 200,
        appealTo: {
          name: "Техподдержка",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 100,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Алексей Семёнов",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
      {
        amount: 300,
        appealTo: {
          name: "Споры",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 700,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Андрей Майоров",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
    ],
  },
  {
    date: new Date(),
    amount: 500,
    appealTo: {
      name: "Команда",
      amount: 30,
    },
    solutionTime: 2,
    hoursAmount: 1000,
    subRows: [
      {
        amount: 100,
        appealTo: {
          name: "Бухгалтерия",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 200,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Фоменкова Анастасия",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
          {
            amount: 50,
            appealTo: {
              name: "Ирина Олеговна",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 50,
          },
        ],
      },
      {
        amount: 200,
        appealTo: {
          name: "Техподдержка",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 100,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Алексей Семёнов",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
      {
        amount: 300,
        appealTo: {
          name: "Споры",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 700,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Андрей Майоров",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
    ],
  },
  {
    date: new Date(),
    amount: 500,
    appealTo: {
      name: "Команда",
      amount: 30,
    },
    solutionTime: 2,
    hoursAmount: 1000,
    subRows: [
      {
        amount: 100,
        appealTo: {
          name: "Бухгалтерия",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 200,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Фоменкова Анастасия",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
          {
            amount: 50,
            appealTo: {
              name: "Ирина Олеговна",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 50,
          },
        ],
      },
      {
        amount: 200,
        appealTo: {
          name: "Техподдержка",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 100,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Алексей Семёнов",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
      {
        amount: 300,
        appealTo: {
          name: "Споры",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 700,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Андрей Майоров",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
    ],
  },
  {
    date: new Date(),
    amount: 500,
    appealTo: {
      name: "Команда",
      amount: 30,
    },
    solutionTime: 2,
    hoursAmount: 1000,
    subRows: [
      {
        amount: 100,
        appealTo: {
          name: "Бухгалтерия",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 200,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Фоменкова Анастасия",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
          {
            amount: 50,
            appealTo: {
              name: "Ирина Олеговна",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 50,
          },
        ],
      },
      {
        amount: 200,
        appealTo: {
          name: "Техподдержка",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 100,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Алексей Семёнов",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
      {
        amount: 300,
        appealTo: {
          name: "Споры",
          amount: 10,
        },
        solutionTime: 2,
        hoursAmount: 700,
        subRows: [
          {
            amount: 50,
            appealTo: {
              name: "Андрей Майоров",
              amount: 5,
            },
            solutionTime: 2,
            hoursAmount: 150,
          },
        ],
      },
    ],
  },
];
