import React, { FC, memo } from "react";
import { AxisOptions, Chart } from "react-charts";
import { timeFormatter } from "../../utils/helpers";

export type DailyReportType = { date: string; value: number };

export type ReportType = {
  label: string;
  data: DailyReportType[];
};

const LineChart: FC<{ chartData: { date: string; value: number }[] }> = ({
  chartData,
}) => {
  const data: ReportType[] = [
    {
      label: "Order count",
      data: chartData,
    },
  ];

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof data)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) =>
        timeFormatter({
          time: new Date(datum.date),
          mode: "dd-MM-yyyy",
        }) as unknown as Date,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof data)[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.value,
        showDatumElements: true,
        elementType: "line",
        min: 0,
      },
    ],
    []
  );

  return (
    <div className="h-[260px]">
      <Chart
        options={{
          data,
          primaryAxis,
          tooltip: false,
          secondaryAxes,
          getSeriesStyle: (series) => ({
            color: "#FFA321",
          }),
          getDatumStyle: (datum, status) =>
            ({
              circle: {
                r: 4,
              },
            } as any),
        }}
      />
    </div>
  );
};

export const CustomLineChart = memo(LineChart);
