import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ISearchState {
  searchText: string;
  userType: "Контрагент" | "Агент";
  activeSearchItemId?: number;
}

const initialState: ISearchState = {
  searchText: "",
  userType: "Контрагент",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setUserType(state, action: PayloadAction<"Контрагент" | "Агент">) {
      state.userType = action.payload;
    },
    setAvtiveSearchItemId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeSearchItemId === action.payload) {
        state.activeSearchItemId = undefined;
      } else state.activeSearchItemId = action.payload;
    },
  },
});

export const { setSearchText, setUserType, setAvtiveSearchItemId } =
  searchSlice.actions;

export const searchReducer = searchSlice.reducer;
