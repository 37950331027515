import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { SvgPlus } from "assets/icons/SvgPlus";
import { SvgSignOut } from "assets/icons/SvgSignOut";
import { ProfileButton } from "common/components/Button/ProfileButton";
import { logout } from "features/user/userSlice";
import {
  useGetUserAccountsQuery,
  useUpdateUserMutation,
} from "services/userApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { IUserProfileDropdownProps } from "common/models/components/user/IUserProfileDropdownProps";

export const UserProfileDropdown: FC<IUserProfileDropdownProps> = ({
  userAvatar,
  userId,
  currentAccountId,
  fullName,
  handleClose,
}) => {
  const {
    data: userAccounts,
    isLoading: isUserAccountsLoading,
    isFetching: isUserAccountsFetching,
    error: userAccountsError,
  } = useGetUserAccountsQuery();
  const [
    updateUser,
    { isLoading: isUserUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateUserMutation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isUpdateSuccess) {
      handleClose();
    }
  }, [isUpdateSuccess]);

  const handleProfileOpen = () => {
    navigate("/profile");
    handleClose();
  };

  const handleCompaniesOpen = () => {
    navigate("/companies");
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const activeAccounts = userAccounts?.filter(
    (account) => account.invite?.confirmed_at || !account.invite
  );

  return (
    <div
      className="absolute right-0 top-[calc(100%+7px)] z-30 w-[340px] rounded bg-white p-[15px] shadow-lg"
      onClick={(e) => e.stopPropagation()}
    >
      <ProfileButton
        className="mb-[25px] w-full"
        onClick={handleProfileOpen}
        mode="short"
        subname="Личный аккаунт"
        name={fullName}
        primary
        avatarurl={userAvatar}
      />
      {userAccounts?.length !== 0 && (
        <div className="mb-[25px]">
          <h5 className="mb-[15px] text-base font-semibold">Компании</h5>
          <QueryLayout
            isLoading={isUserAccountsLoading}
            isFetching={isUserAccountsFetching}
            error={userAccountsError}
          >
            <ul className="grid gap-y-[15px]">
              {activeAccounts?.map((account) => (
                <ProfileButton
                  key={account.id}
                  onClick={() => {
                    updateUser({ user_id: userId, account_id: account.id });
                  }}
                  disabled={
                    account.id === currentAccountId || isUserUpdateLoading
                  }
                  name={account.company?.name || "Частное лицо"}
                  mode="short"
                  subname={`${account.role.name} ${
                    account.company?.category.name
                      ? `(${account.company?.category.name.toLocaleLowerCase()})`
                      : ""
                  }`}
                  balance={account.company?.balance}
                  avatarurl={
                    account.company
                      ? account.company?.logotype?.path
                      : undefined || !account.company
                      ? account.user?.avatar?.path
                      : undefined
                  }
                />
              ))}
            </ul>
          </QueryLayout>
        </div>
      )}

      <div className="grid gap-y-[12px]">
        <button
          className="flex w-full items-center justify-between rounded border border-transparent bg-lightWhiteGray py-[15px] px-[20px] text-left text-base font-medium text-gray
            transition hover:border-primary focus:border-primary active:text-primary"
          onClick={handleCompaniesOpen}
        >
          Добавить компанию
          <SvgPlus className="h-[20px] w-[20px]" />
        </button>
        <button
          className="flex w-full items-center justify-between rounded border border-transparent bg-lightWhiteGray py-[15px] px-[20px] text-left text-base font-medium text-gray
            transition hover:border-primary focus:border-primary active:text-primary"
          onClick={handleLogout}
        >
          Выйти
          <SvgSignOut className="h-[20px] w-[20px]" />
        </button>
      </div>
    </div>
  );
};
