import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Switch } from "../../../common/components/Checkbox/Switch";
import { QueryLayout } from "../../../common/components/Layout/QueryLayout";
import { List } from "../../../common/components/List/List";
import { useGetEmployeeRolePermissionsQuery } from "../../../services/employeeApi";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { IEmployeePermissionsListProps } from "common/models/components/employee/IEmployeePermissionsListProps";

export const EmployeePermissionsList: FC<IEmployeePermissionsListProps> = ({
  roleId,
  readOnly,
  companyCategoryId,
}) => {
  const {
    data: permissions,
    isLoading: isPermissionsLoading,
    isFetching: isPermissionsFetching,
    error: permissionError,
  } = useGetEmployeeRolePermissionsQuery({ roleId, companyCategoryId });

  const { register, control } = useFormContext<{
    permissions: string[];
  }>();

  const currentPermissions = useWatch({
    control,
    name: "permissions",
  });

  return (
    <QueryLayout
      isLoading={isPermissionsLoading}
      isFetching={isPermissionsFetching}
      error={permissionError}
    >
      {permissions?.length ? (
        <List className="mb-[30px] gap-[15px]">
          {permissions.map((permission, i) => (
            <li key={permission.id}>
              <label className="flex items-center justify-between text-sm font-semibold">
                {permission.name}
                <Switch
                  {...register(`permissions.${i}`)}
                  disabled={readOnly}
                  value={permission.id}
                  checked={!!currentPermissions.includes(String(permission.id))}
                />
              </label>
            </li>
          ))}
        </List>
      ) : (
        <NotFoundTitle className="mb-[30px]">Прав не найдено</NotFoundTitle>
      )}
    </QueryLayout>
  );
};
