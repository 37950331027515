import { IIndividual } from "common/models/user/IIndividual";
import { api } from "../api";
import { ICustomerCompany } from "common/models/user/ICustomerCompany";
import { IImplementer } from "common/models/user/IImplementer";

const adminUserApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIndividualCustomers: build.query<
      IIndividual[],
      Undefinable<{ date_from?: string; date_to?: string; query?: string }>
    >({
      query(params) {
        return {
          url: `/admin/clients/users`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { users: IIndividual[] }) => result.users,
    }),
    getCompanyCustomers: build.query<
      ICustomerCompany[],
      { date_from?: string; date_to?: string; query?: string }
    >({
      query(params) {
        return {
          url: `/admin/clients/companies`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { companies: ICustomerCompany[] }) =>
        result.companies,
    }),
    getWorkForHimSelfs: build.query<
      IImplementer[],
      { date_from?: string; date_to?: string; query?: string }
    >({
      query(params) {
        return {
          url: `/admin/executors/works_for_himself`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { accounts: IImplementer[] }) =>
        result.accounts,
    }),
    getDrivers: build.query<
      IImplementer[],
      { date_from?: string; date_to?: string; query?: string }
    >({
      query(params) {
        return {
          url: `/admin/executors/drivers`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { accounts: IImplementer[] }) =>
        result.accounts,
    }),
    getManagers: build.query<
      IImplementer[],
      { date_from?: string; date_to?: string; query?: string }
    >({
      query(params) {
        return {
          url: `/admin/executors/managers`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { accounts: IImplementer[] }) =>
        result.accounts,
    }),
    getImplementerCompanies: build.query<
      IImplementer[],
      { date_from?: string; date_to?: string; query?: string }
    >({
      query(params) {
        return {
          url: `/admin/executors/companies`,
          method: "GET",
          params,
        };
      },
      transformResponse: (result: { accounts: IImplementer[] }) =>
        result.accounts,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyCustomersQuery,
  useGetIndividualCustomersQuery,
  useGetDriversQuery,
  useGetManagersQuery,
  useGetWorkForHimSelfsQuery,
  useGetImplementerCompaniesQuery,
} = adminUserApi;
