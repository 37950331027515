import { FC } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { Actions } from "../../../../common/components/Table/Actions";

export const CounterAgentTable: FC = () => {
  return <Table data={data} columns={columns} />;
};

const columns: Column<ICounterAgentData>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Название",
    accessor: "name",
  },
  {
    Header: "Описание",
    accessor: "desc",
    Cell: ({ row }: any) => (
      <p className="text-overflow max-h-[30px]">{row.values.desc}</p>
    ),
  },
  {
    accessor: "action",
    Header: () => (
      <span className="ml-auto block w-fit pr-[8px]">Действия</span>
    ),
    Cell: ({ row }: any) => <div>В работе</div>, //<Actions id={row.original.id} onDelete={useDeleteKmuModelMutation} />,
  },
];

interface ICounterAgentData {
  id: number;
  name: string;
  desc: string;
  action?: any;
}

const data: ICounterAgentData[] = [
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
  {
    id: 112,
    name: "КонтрАгент",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae possimus non harum illum amet error repudiandae laborum id iusto molestias, consequatur consequuntur tempore fugit maxime cum minus culpa voluptas. Sit.",
  },
];
