import { IOriginalInputProps } from "common/models/components/input/IInputProps";
import { FC, memo } from "react";

export const RadioButton: FC<IOriginalInputProps> = memo((props) => {
  const userClasses = props.className || "";

  return (
    <input
      {...props}
      className={`relative h-[16px] w-[16px] cursor-pointer appearance-none rounded-[50%] border-[2px] border-primary bg-lightWhiteGray bg-[length:13px_10px] bg-center bg-no-repeat outline-none transition after:pointer-events-none after:absolute
      after:right-[2px]  after:left-[2px]  after:bottom-[2px]  after:top-[2px]  after:h-[8px]  after:w-[8px]  after:rounded-[50%]  after:duration-300  after:ease-in-out  after:content-['']  checked:after:bg-primary  focus:outline-none
      ${userClasses}`}
      type="radio"
    />
  );
});
