import { FC, memo } from "react";
import { SvgStar } from "../../../assets/icons/SvgStar";
import { Avatar } from "../Avatar/Avatar";
import { IProfileCardProps } from "common/models/components/profile/IProfileCardProps";

export const ProfileCard: FC<IProfileCardProps> = memo((props) => {
  const userClasses = props.className || "";
  return (
    <div {...props} className={`flex ${userClasses}`}>
      <div className="relative mr-[12px] flex items-center">
        <Avatar src={props.avatarurl} alt="user" primary={props.primary} />
      </div>
      <div className="w-full min-w-[190px]">
        <h4 className="mb-[5px] text-left text-base font-semibold leading-4 text-lightGray">
          {props.name}
        </h4>
        <div className="flex items-end justify-between leading-4">
          {props.isShortMode ? (
            <div className="flex items-center text-sm font-semibold text-primary">
              {props.subname}
            </div>
          ) : (
            <>
              <div className="flex items-center text-sm font-semibold text-primary">
                <SvgStar className="mr-[5px] h-[10px] w-[10px] fill-primary" />
                {props.rating}
              </div>
              {props.balance !== undefined && (
                <div className="text-md font-semibold">
                  {`${props.balance} руб.`}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});
