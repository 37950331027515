import { IInputColorPalette } from "common/models/components/input/IInputColorPalit";
import { IInputProps } from "common/models/components/input/IInputProps";
import { FC, forwardRef } from "react";

export const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>(
  ({ type = "text", className = "", placeholder, ...props }, ref) => {
    const userClasses = className || "";

    const { valid, invalid } = props;

    let currentColorPalette = "";

    if (valid) {
      currentColorPalette = colorPalette.valid;
    } else if (invalid) {
      currentColorPalette = colorPalette.invalid;
    } else {
      currentColorPalette = colorPalette.normal;
    }

    return (
      <div className="w-full">
        {props.label && (
          <span
            className={`mb-[12px] block ${
              invalid ? "text-red" : "text-gray"
            } text-sm font-semibold`}
          >
            {props.label}
          </span>
        )}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          {...props}
          className={`flex items-center justify-center rounded border-[2px] 
            bg-lightWhiteGray px-[20px] py-[15px] text-[13px] leading-[16px]
            outline-none transition placeholder:text-[13px]
            ${currentColorPalette}
            ${userClasses}
          `}
        />
        {props.invalidMessage && (
          <span className={`mt-[5px] block text-sm text-red`}>
            {props.invalidMessage}
          </span>
        )}
      </div>
    );
  }
);

const colorPalette: IInputColorPalette = {
  normal: "border-lightWhiteGray text-black placeholder:text-lightGray",
  invalid: "border-red text-red placeholder:text-red",
  valid: "border-primary text-primary placeholder:text-primary",
};
