import { FC, memo, useMemo } from "react";
import { timeFormatter } from "../../utils/helpers";
import { Avatar } from "../Avatar/Avatar";
import { IDetailedUserProfileCardProps } from "common/models/components/profile/IDetailedUserProfileCardProps";

export const DetailedUserProfileCard: FC<IDetailedUserProfileCardProps> = memo(
  (props) => {
    const userClasses = props.className || "";

    const registrationDate = useMemo(
      () =>
        timeFormatter({
          time: props.registrationDate,
          mode: "dd-MM-yyyy",
        }),
      [props.registrationDate]
    );

    return (
      <div {...props} className={`flex ${userClasses}`}>
        <div className="relative mr-[12px] flex">
          <Avatar src={props.avatarurl} alt="user" primary={props.primary} />
        </div>
        <div className="grid w-full gap-[3px]">
          <div className="flex items-end justify-between leading-4">
            <h4 className="text-left text-sm font-semibold leading-4 text-lightGray">
              {props.name}
            </h4>
            <span className="font-mdeium text-sm text-gray">
              # {props.userId}
            </span>
          </div>
          <div className="flex items-end justify-between leading-4">
            <div className="flex items-center text-xs font-semibold text-primary">
              {props.status}
            </div>
            {props.bonuses !== 0 && props.bonuses && (
              <div className="text-base font-semibold">
                <span className="mr-[3px]">{props.bonuses}</span>
                бонусов
              </div>
            )}
          </div>
          <div className="flex items-end justify-between text-xs leading-4 text-lightGray">
            Зарегистрирован
            <time>{registrationDate}</time>
          </div>
          {props.completeOrdersNumber && (
            <div className="flex items-end justify-between text-xs leading-4 text-lightGray">
              Завершённых заказов
              <div className="flex h-[15px] w-[15px] items-center justify-center rounded-[50%] bg-primary text-xxs font-medium text-white">
                <span className="mt-[2px]">{props.completeOrdersNumber}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
