import { ITabsProps } from "common/models/components/ui/ITabsProps";
import { FC, memo } from "react";

export const Tabs: FC<ITabsProps> = memo(
  ({ tabs, value, onTabClick, ...props }) => {
    const userClasses = props.className || "";

    return (
      <div
        {...props}
        className={`flex overflow-x-hidden rounded ${userClasses}`}
      >
        {tabs.map((tab, index) => (
          <button
            key={tab.value}
            className={`flex-1 border border-transparent py-[5px] px-[15px] text-sm font-semibold ${
              value === tab.value ? "bg-primary text-white" : "bg-whiteGray"
            }
        transition hover:border-primary active:bg-primary active:text-white
        ${
          index === 0
            ? "rounded-l"
            : index === tabs.length - 1
            ? "rounded-r"
            : ""
        }
        `}
            type="button"
            onClick={() => onTabClick(tab.value)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  }
);
