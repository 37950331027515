import { IApiMessage } from "../../common/models/api/IApiMessage";
import { IVehicleCategory } from "../../common/models/vehicles/IVehicleCategory";
import { IVehicle } from "../../common/models/vehicles/IVehicle";
import { api } from "../api";
import { IVehicleOption } from "../../common/models/vehicles/IVehicleOption";
import { IVehicleType } from "../../common/models/vehicles/IVehicleType";
import { IDriver } from "../../common/models/order/IDriver";
import { IVehicleInputs } from "common/models/api/IVehicleInputs";

const vehiclesApi = api
  .enhanceEndpoints({ addTagTypes: ["Vehicle"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllVehicles: build.query<
        IVehicle[],
        {
          vehicleTypeId?: number;
          query?: string;
          isVerified?: boolean;
        }
      >({
        query({ vehicleTypeId, query, isVerified }) {
          return {
            url: `/vehicles`,
            params: {
              vehicle_type_id: vehicleTypeId,
              is_verified: isVerified !== undefined ? +isVerified : undefined,
              query,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Vehicle", id } as const)),
                { type: "Vehicle", id: "LIST" },
              ]
            : [{ type: "Vehicle", id: "LIST" }],
        transformResponse: (result: { vehicles: IVehicle[] }) =>
          result.vehicles,
      }),
      getCompanyVehicles: build.query<
        IVehicle[],
        {
          companyId: number;
          vehicleTypeId?: number;
          query?: string;
          isVerified?: boolean;
        }
      >({
        query({ vehicleTypeId, companyId, query, isVerified }) {
          return {
            url: `/companies/${companyId}/vehicles`,
            params: {
              vehicle_type_id: vehicleTypeId,
              is_verified: isVerified !== undefined ? +isVerified : undefined,
              query,
            },
          };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Vehicle", id } as const)),
                { type: "Vehicle", id: "LIST" },
                "Company",
              ]
            : [{ type: "Vehicle", id: "LIST" }, { type: "Company" }],
        transformResponse: (result: { vehicles: IVehicle[] }) =>
          result.vehicles,
      }),
      getCompanyVehicleById: build.query<
        IVehicle,
        { companyId: number; vehicleId: number }
      >({
        query({ companyId, vehicleId }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}`,
          };
        },
        providesTags: (result) =>
          result ? [{ type: "Vehicle", id: result.id }] : [],
        transformResponse: (result: { vehicle: IVehicle }) => result.vehicle,
      }),
      getVehicleById: build.query<IVehicle, { vehicleId: number }>({
        query({ vehicleId }) {
          return {
            url: `/vehicles/${vehicleId}`,
          };
        },
        providesTags: (result) =>
          result ? [{ type: "Vehicle", id: result.id }] : [],
        transformResponse: (result: { vehicle: IVehicle }) => result.vehicle,
      }),
      getVehicleDrivers: build.query<
        IDriver[],
        { companyId: number; vehicleId: number; query?: string }
      >({
        query({ companyId, vehicleId, query }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}/drivers`,
            params: { query },
          };
        },
        providesTags: (result, error, args) => [
          { type: "Vehicle", id: "LIST" },
          "Company",
        ],
        transformResponse: (result: { drivers: IDriver[] }) => result.drivers,
      }),
      createVehicle: build.mutation<IVehicle, IVehicleInputs>({
        query({ company_id, ...body }) {
          return {
            url: `/companies/${company_id}/vehicles`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: "Vehicle", id: "LIST" }] : [],
        transformResponse: (result: { vehicle: IVehicle }) => result.vehicle,
      }),
      verifyVehicle: build.mutation<
        IVehicle,
        { vehicleId: number; companyId: number }
      >({
        query({ vehicleId, companyId }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}/verify`,
            method: "POST",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Vehicle", id: arg.vehicleId }] : [],
        transformResponse: (result: { vehicle: IVehicle }) => result.vehicle,
      }),
      setVehicleDrivers: build.mutation<
        IDriver[],
        { employees: number[]; companyId: number; vehicleId: number }
      >({
        query({ companyId, vehicleId, ...body }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}/drivers`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Vehicle", id: arg.vehicleId }] : [],
        transformResponse: (result: { drivers: IDriver[] }) => result.drivers,
      }),
      deleteVehicleDrivers: build.mutation<
        IDriver[],
        { employees: number[]; companyId: number; vehicleId: number }
      >({
        query({ companyId, vehicleId, ...body }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}/drivers`,
            method: "DELETE",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Vehicle", id: arg.vehicleId }] : [],
        transformResponse: (result: { drivers: IDriver[] }) => result.drivers,
      }),
      deleteVehicle: build.mutation<
        IApiMessage,
        { companyId: number; vehicleId: number }
      >({
        query({ companyId, vehicleId }) {
          return {
            url: `/companies/${companyId}/vehicles/${vehicleId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Vehicle", id: arg.vehicleId }] : [],
      }),
      updateVehicle: build.mutation<IVehicle, IVehicleInputs>({
        query({ company_id, vehicle_id, ...body }) {
          return {
            url: `/companies/${company_id}/vehicles/${vehicle_id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (result, error, arg) =>
          !error?.status ? [{ type: "Vehicle", id: arg.vehicle_id }] : [],
        transformResponse: (result: { vehicle: IVehicle }) => result.vehicle,
      }),
      getVehicleCategories: build.query<IVehicleCategory[], void>({
        query() {
          return {
            url: `/vehicle_categories`,
          };
        },
        transformResponse: (result: { items: IVehicleCategory[] }) =>
          result.items,
      }),
      getVehicleParameters: build.query<
        { vehicleTypes: IVehicleType[]; options: IVehicleOption[] },
        number
      >({
        query(categoryId) {
          return {
            url: `/vehicle_parameters`,
            params: { vehicle_category_id: categoryId },
          };
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useUpdateVehicleMutation,
  useVerifyVehicleMutation,
  useGetAllVehiclesQuery,
  useGetVehicleDriversQuery,
  useGetVehicleParametersQuery,
  useGetVehicleByIdQuery,
  useGetCompanyVehicleByIdQuery,
  useCreateVehicleMutation,
  useGetVehicleCategoriesQuery,
  useGetCompanyVehiclesQuery,
  useSetVehicleDriversMutation,
  useDeleteVehicleDriversMutation,
  useDeleteVehicleMutation,
} = vehiclesApi;
