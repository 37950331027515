import { FC } from "react";
import { Input } from "../../../../../common/components/Input/Input";
import { useFormContext } from "react-hook-form";

export const ChassisMarkFormInputs: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{
    name: string;
  }>();

  return (
    <Input
      {...register(`name`, { required: true, minLength: 2 })}
      className="w-full"
      label="Название *"
      invalid={!!errors.name}
      placeholder="Название"
    />
  );
};
