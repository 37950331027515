import { FC } from "react";
import {
  getQueryDateParam,
  timeFormatter,
} from "../../../../common/utils/helpers";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import manipulatorImage from "../../../../assets/images/manipulatorIcon.png";
import { SvgAndroid } from "../../../../assets/icons/SvgAndroid";
import { SvgApple } from "../../../../assets/icons/SvgApple";
import { SvgWeb } from "../../../../assets/icons/SvgWeb";
import { SelectColumnFilter } from "../../../../common/components/Table/SelectColumnFilter";
import { UserPopup } from "../../popups/UserPopup";
import { VehiclePopup } from "../../popups/VehiclePopup";
import { useGetOrdersVisorQuery } from "services/admin/adminOrdersApi";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { IOrder } from "common/models/order/IOrder";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const OrderVisorTable: FC<{
  dateFrom?: string;
  dateTo?: string;
}> = ({ dateFrom, dateTo }) => {
  const {
    data: orders,
    isLoading,
    isFetching,
    error,
  } = useGetOrdersVisorQuery({
    date_from: getQueryDateParam(dateFrom),
    date_to: getQueryDateParam(dateTo),
  });

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {orders?.length ? (
        <Table data={orders} columns={columns} isLoading={isFetching} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

// const PlatformType: FC<{ type: string }> = ({ type }) => {
//   switch (type) {
//     case "Android":
//       return (
//         <div className="flex items-center whitespace-nowrap">
//           <SvgAndroid className="mr-[5px] h-[16px] w-[16px]" />
//           {type}
//         </div>
//       );
//     case "iOS":
//       return (
//         <div className="flex items-center whitespace-nowrap">
//           <SvgApple className="mr-[5px] h-[16px] w-[16px]" />
//           {type}
//         </div>
//       );
//     case "Web":
//       return (
//         <div className="flex items-center whitespace-nowrap">
//           <SvgWeb className="mr-[5px] h-[16px] w-[16px]" />
//           {type}
//         </div>
//       );
//     default:
//       return <div className="flex items-center whitespace-nowrap">{type}</div>;
//   }
// };

const columns: Column<IOrder>[] = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Вид техники",
    accessor: "vehicle_category",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: ({ row }: any) => (
      <span className="flex">
        <img
          className="mr-[10px]"
          width={20}
          height={20}
          src={row.original.vehicle_category.icon.path}
          alt="Manipulator"
        />
        {row.original.vehicle_category.name}
      </span>
    ),
  },
  {
    Header: "Тариф",
    accessor: "vehicle_type",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: VehiclePopup,
  },
  {
    Header: "Время заказа",
    accessor: "created_at",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: ({ row }: any) => (
      <span>
        {timeFormatter({
          time: new Date(row.original.started_at),
          mode: "dd-MM-yyyy",
        })}
      </span>
    ),
  },
  {
    Header: "Цена, руб.",
    accessor: "price",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
  },
  // {
  //   Header: "Форма оплаты",
  //   accessor: "paymentType",
  //   Filter: (filter) => <SelectColumnFilter {...filter} />,
  // },
  // {
  //   Header: "Дистанция",
  //   accessor: "km",
  //   Filter: (filter) => <SelectColumnFilter {...filter} />,
  // },
  {
    Header: "Длительность заявки, ч.",
    id: "requestTime",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: ({ row }: any) => (
      <span>
        {new Date(+new Date() - +new Date(row.original.started_at)).getHours()}
      </span>
    ),
  },
  // {
  //   Header: "География",
  //   accessor: "geo",
  //   Filter: (filter) => <SelectColumnFilter {...filter} />,
  // },
  // {
  //   Header: "Платформа",
  //   accessor: "platformType",
  //   Filter: (filter) => <SelectColumnFilter {...filter} />,
  //   Cell: ({ row }: any) => <PlatformType type={row.values.platformType} />,
  // },
  {
    Header: "Заказчик",
    accessor: "account",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: ({ row: { original } }) => <UserPopup customer={original.account} />,
  },
  {
    Header: "Исполнитель",
    accessor: "executor_company",
    Filter: (filter) => <SelectColumnFilter {...filter} />,
    Cell: ({ row: { original } }) => (
      <UserPopup performer={original.executor_company} />
    ),
  },
];
