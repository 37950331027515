import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TransactionsState {
  activeAppealId?: number;
  isAddingAppeal: boolean;
  searchText: string;
  appealStatus: number;
}

const initialState: TransactionsState = {
  isAddingAppeal: false,
  searchText: "",
  appealStatus: 0,
};

const appealSlice = createSlice({
  name: "customer_appeal",
  initialState,
  reducers: {
    setActiveAppealId(state, action: PayloadAction<Undefinable<number>>) {
      if (state.activeAppealId === action.payload)
        state.activeAppealId = undefined;
      else state.activeAppealId = action.payload;
      state.isAddingAppeal = false;
    },
    setIsAddingAppeal(state, action: PayloadAction<boolean>) {
      state.isAddingAppeal = action.payload;
      state.activeAppealId = undefined;
    },
    setAppealsSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setAppealStatus(state, action: PayloadAction<number>) {
      state.appealStatus = action.payload;
    },
  },
});

export const {
  setActiveAppealId,
  setIsAddingAppeal,
  setAppealsSearchText,
  setAppealStatus,
} = appealSlice.actions;

export const appealReducer = appealSlice.reducer;
