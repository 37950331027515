import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TransactionsState {
  transactionStatus: number;
  activeTransactionId?: number;
}

const initialState: TransactionsState = { transactionStatus: 0 };

const transactionsSlice = createSlice({
  name: "customer_transactions",
  initialState,
  reducers: {
    setActiveTransactionId(state, action: PayloadAction<number>) {
      if (state.activeTransactionId === action.payload)
        state.activeTransactionId = undefined;
      else state.activeTransactionId = action.payload;
    },
    setTransactionStatus(state, action: PayloadAction<number>) {
      state.transactionStatus = action.payload;
    },
  },
});

export const { setActiveTransactionId, setTransactionStatus } =
  transactionsSlice.actions;

export const transactionReducer = transactionsSlice.reducer;
