import { FC, memo } from "react";
import { QueryLayout } from "../Layout/QueryLayout";
import {
  useGetCompanyEmployeesQuery,
  useGetEmployeeRolesQuery,
} from "services/employeeApi";
import { WideIconButton } from "../Button/WideIconButton";
import { SvgPlus } from "assets/icons/SvgPlus";
import { NotFoundTitle } from "../Title/NotFoundTitle";
import { List } from "../List/List";
import { CustomInput } from "../Input/CustomInput";
import { EmployeeListItem } from "./EmployeeListItem";
import { Input } from "../Input/Input";
import { IEmployeeListProps } from "common/models/components/employee/IEmployeeListProps";

export const EmployeeList: FC<IEmployeeListProps> = memo(
  ({
    onItemClick,
    onSearch,
    onAdd,
    applicationId,
    companyId,
    searchText,
    activeItemId,
  }) => {
    const {
      data: employees,
      isLoading: isEmployeesLoading,
      isFetching: isEmployeesFetching,
      error: employeesError,
    } = useGetCompanyEmployeesQuery(
      {
        companyId: companyId || -1,
        query: searchText.trim().length >= 3 ? searchText : undefined,
      },
      { skip: !companyId }
    );
    const {
      data: roles,
      isLoading: isRolesLoading,
      error: rolesError,
    } = useGetEmployeeRolesQuery({ applicationId });

    const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value);
    };

    const currentEmployees = roles
      ?.map(
        (role) =>
          employees?.some((employee) => employee.role.id === role.id) && (
            <List
              key={role.id}
              className={`mb-[15px] ${
                isEmployeesFetching ? "animate-pulse" : ""
              }`}
              label={role.name}
            >
              {employees
                .filter((employee) => employee.role.id === role.id)
                .map((employee) => (
                  <li key={employee.id}>
                    <CustomInput
                      type="radio"
                      className="py-[10px]"
                      checked={employee.id === activeItemId}
                      onClick={() => onItemClick(employee.id)}
                      innerelement={
                        <EmployeeListItem
                          name={
                            employee.user?.first_name
                              ? `${employee.user.last_name} ${employee.user.first_name}`
                              : `${employee.invite?.phone}`
                          }
                          rating={employee.user?.rate}
                          role={employee.role.name}
                        />
                      }
                    />
                  </li>
                ))}
            </List>
          )
      )
      .filter((a) => a);

    return (
      <>
        <div className="flex items-center justify-between">
          <h4 className="mb-[12px] text-md font-semibold">Сотрудники</h4>
          {!!employees?.length && (
            <button
              className="text-primary outline-none transition hover:text-black focus:text-black active:text-primary"
              onClick={onAdd}
            >
              <SvgPlus className="h-[20px] w-[20px]" />
            </button>
          )}
        </div>
        <div className="sticky top-0">
          <Input
            className="mb-[15px] mt-[5px] w-full"
            placeholder="Введите минимум 3 символа"
            value={searchText}
            onChange={handleOnSearch}
          />
        </div>
        <QueryLayout
          isLoading={isEmployeesLoading || isRolesLoading}
          error={employeesError || rolesError}
        >
          {!currentEmployees?.length && !searchText ? (
            <WideIconButton
              className="w-full text-sm font-medium text-gray"
              icon={<SvgPlus className="h-[16px] w-[16px] text-primary" />}
              onClick={onAdd}
            >
              У вас ещё ни одного сотрудника
            </WideIconButton>
          ) : (
            !currentEmployees?.length && <NotFoundTitle />
          )}
          {!!currentEmployees?.length && currentEmployees}
        </QueryLayout>
      </>
    );
  }
);
