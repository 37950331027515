import { FC, useState } from "react";
import { DetailedUserProfileCard } from "../../../common/components/Profile/DetailedUserProfileCard";
import { SvgUserCircle } from "../../../assets/icons/SvgUserCircle";
import { SvgSuitcase } from "../../../assets/icons/SvgSuitcase";
import { SvgTruck } from "../../../assets/icons/SvgTruck";
import { IButtonProps } from "common/models/components/ui/IButtonProps";
import { IAccount } from "common/models/user/IAccount";
import { ICompany } from "common/models/companies/ICompany";
import { CompanyProfileCard } from "common/components/Profile/CompanyProfileCard";

interface IUserTypeButton extends IButtonProps {
  userType: number;
}

const UserTypeButton: FC<IUserTypeButton> = ({ userType, ...props }) => {
  switch (userType) {
    case 1:
      return (
        <button
          {...props}
          className="flex items-center whitespace-nowrap text-primary"
        >
          <SvgUserCircle className="mr-[5px] h-[16px] w-[16px]" />
          Клиент
        </button>
      );
    case 2:
      return (
        <button
          {...props}
          className="flex items-center whitespace-nowrap text-primary"
        >
          <SvgSuitcase className="mr-[5px] h-[16px] w-[16px]" />
          Компания
        </button>
      );
    default:
      return (
        <button
          {...props}
          className="flex items-center whitespace-nowrap text-primary"
        >
          {userType}
        </button>
      );
  }
};

export const UserPopup: FC<{ customer?: IAccount; performer?: ICompany }> = ({
  customer,
  performer,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      {isOpen && (
        <div className="absolute top-[20px] right-0 z-10 rounded bg-white p-[10px] shadow-lg">
          {customer && (
            <DetailedUserProfileCard
              className="w-[200px]"
              userId={customer.id}
              status="Старт"
              name={`${customer?.user?.last_name} ${
                customer?.user?.first_name
              } ${customer?.user?.middle_name || ""}`}
              registrationDate={new Date()}
              completeOrdersNumber={10}
            />
          )}
          {performer && <CompanyProfileCard company={performer} />}
        </div>
      )}
      <UserTypeButton
        onClick={() => setIsOpen((prev) => !prev)}
        userType={customer ? 1 : 2}
      />
    </div>
  );
};
