import { FC, memo } from "react";
import { SvgSpinner } from "../../../assets/icons/SvgSpinner";
import { ISvgProps } from "common/models/components/ui/ISvgProps";

export const Spinner: FC<ISvgProps> = memo((props) => {
  return (
    <div className="bg-transparent p-3">
      <SvgSpinner
        {...props}
        className={`m-auto h-[100px] w-[100px] animate-spin p-1 ${
          props.className || ""
        }`}
      />
    </div>
  );
});
