import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface VehiclesState {
  activeVehicle: {
    id?: number;
    categoryId?: number;
  };
  isAddingVehicle: boolean;
  searchText: string;
}

const initialState: VehiclesState = {
  activeVehicle: {
    id: undefined,
    categoryId: undefined,
  },
  isAddingVehicle: false,
  searchText: "",
};

const vehicleSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setActiveVehicle(
      state,
      action: PayloadAction<{
        id?: number;
        categoryId?: number;
      }>
    ) {
      if (state.activeVehicle.id === action.payload.id) {
        state.activeVehicle = {
          id: undefined,
          categoryId: undefined,
        };
      } else {
        state.activeVehicle = {
          id: action.payload.id,
          categoryId: action.payload.categoryId,
        };
      }

      state.isAddingVehicle = false;
    },
    setVehicleSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setIsAddingVehicle(state, action: PayloadAction<boolean>) {
      state.isAddingVehicle = action.payload;
      state.activeVehicle = {
        id: undefined,
        categoryId: undefined,
      };
    },
  },
});

export const { setVehicleSearchText, setActiveVehicle, setIsAddingVehicle } =
  vehicleSlice.actions;

export const vehicleReducer = vehicleSlice.reducer;
