import { FC, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IImage } from "common/models/commonModels/IImage";
import { useGlobalModalContext } from "../Modal/GlobalModal";
import { MODAL_TYPES } from "common/utils/consts";
import { ISliderProps } from "common/models/components/ui/ISliderProps";

const testImages: IImage[] = [
  {
    id: 1,
    filename: "testImages",
    path: "https://images.hdqwalls.com/wallpapers/yellow-lamborghini-4k-qb.jpg",
  },
  {
    id: 2,
    filename: "testImages",
    path: "https://w0.peakpx.com/wallpaper/675/336/HD-wallpaper-light-yellow-windows-11-logo-windows-11.jpg",
  },
  {
    id: 3,
    filename: "testImages",
    path: "https://i.pinimg.com/736x/31/4d/8e/314d8e3c5cca243140f1cbdb8e77fa24.jpg",
  },
  {
    id: 4,
    filename: "testImages",
    path: "https://i.pinimg.com/originals/25/3a/8e/253a8edcc1ad8feff29615572e76e1e1.jpg",
  },
];

export const Slider: FC<ISliderProps> = memo(
  ({ expandable, images, ...props }) => {
    const userClasses = props.className || "";

    const { showModal } = useGlobalModalContext();

    const openImagesSliderModal = (
      images: IImage[] | string[],
      activeImage: number
    ) => {
      showModal(MODAL_TYPES.IMAGES_SLIDER_MODAL, {
        images,
        activeImage,
      });
    };

    return (
      <div className="grid">
        <div {...props} className={`overflow-hidden ${userClasses}`}>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            centeredSlides={true}
          >
            {images.map((image: IImage | string, i: number) => (
              <SwiperSlide
                key={`image_${i}`}
                className="m-auto h-[250px] overflow-hidden rounded"
              >
                <button
                  className="w-full"
                  type="button"
                  onClick={() =>
                    expandable ? openImagesSliderModal(images, i) : undefined
                  }
                >
                  <div className="absolute top-[13px] right-[13px] flex h-[20px] w-[20px] items-center justify-center rounded bg-lightWhiteGray text-xxs">
                    {i + 1}
                  </div>
                  <img
                    className="h-[250px] w-[350px] rounded object-cover"
                    src={typeof image === "object" ? image?.path : image}
                    alt="Manipulator"
                  />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }
);
