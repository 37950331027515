import { IApiMessage } from "./../common/models/api/IApiMessage";
import { ILoginInputs } from "./../common/models/api/ILoginInputs";
import { IUser } from "common/models/user/IUser";
import { api } from "./api";
import { SOCKET_EVENT } from "common/utils/consts";
import Pusher from "pusher-js";

const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendCode: build.mutation<IApiMessage, ILoginInputs>({
      query(data) {
        return {
          url: "/auth/login",
          method: "POST",
          body: data,
        };
      },
      async onCacheEntryAdded(arg, { cacheEntryRemoved, cacheDataLoaded }) {
        await cacheDataLoaded;
        localStorage.setItem("code_sended_time", new Date().toISOString());
        await cacheEntryRemoved;
      },
    }),
    loginUser: build.mutation<IUser, ILoginInputs>({
      query(data) {
        return {
          url: "/auth/login",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? ["User"] : []),
      transformResponse: (result: { user: IUser }) => result.user,
    }),
    authUser: build.query<IUser, void>({
      query() {
        return {
          url: "/auth/user",
        };
      },
      providesTags: [{ type: "Auth" }],
      // async onCacheEntryAdded(
      //   arg,
      //   { cacheEntryRemoved, cacheDataLoaded, updateCachedData }
      // ) {
      //   const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
      //     cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
      //     authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
      //     auth: {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     },
      //   });
      //   await cacheDataLoaded;
      //   const channel = pusher.subscribe(
      //     `private-users.${localStorage.getItem("token")}`
      //   );
      //   channel.bind(SOCKET_EVENT.USER_UPDATED, (data: { user: IUser }) => {
      //     console.log(
      //       `private-users.${localStorage.getItem("token")}`,
      //       data.user
      //     );
      //     // updateCachedData((draft) => data.user);
      //   });
      //   await cacheEntryRemoved;
      //   pusher.unsubscribe(`private-users.${localStorage.getItem("token")}`);
      // },
      transformResponse: (result: { user: IUser }) => result.user,
    }),
  }),
  overrideExisting: false,
});

export const { useSendCodeMutation, useLoginUserMutation, useAuthUserQuery } =
  authApi;
