import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Route, Routes } from "react-router-dom";
import { CounterAgentTable } from "../../tables/counterAgent/CounterAgentTable";
import { CounterAgentAdd } from "./CounterAgentAdd";
import { CounterAgentEdit } from "./CounterAgentEdit";

export const CounterAgentPage: FC = () => {
  const filters = useAppSelector((state) => state.administrator.orders.filters);

  const dispatch = useAppDispatch();

  return (
    <div className="px-[5px]">
      <Routes>
        <Route path="/" element={<CounterAgentTable />} />
        <Route path="/add" element={<CounterAgentAdd />} />
        <Route path="/:id" element={<CounterAgentEdit />} />
      </Routes>
    </div>
  );
};
