import { FilterProps } from "react-table";
import { useMemo } from "react";

interface IColumnFilter<T extends object> extends FilterProps<T> {
  options?: { value: any; label: string }[];
}

export function SelectColumnFilter({
  options,
  column: { filterValue, setFilter, preFilteredRows, id },
}: IColumnFilter<any>) {
  return (
    <select
      className="mt-[10px] w-full rounded bg-lightWhiteGray p-[5px] font-normal"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="0">-</option>
      {options?.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
