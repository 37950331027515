import { FC, useEffect, useMemo } from "react";
import { SvgCaretUp } from "../../../../assets/icons/SvgCaretUp";
import {
  getQueryDateParam,
  getTableData,
  timeFormatter,
} from "../../../../common/utils/helpers";
import { Column } from "react-table";
import { DropdownTable } from "../../../../common/components/Table/DropdownTable";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import { useGetFinishedOrdersQuery } from "services/admin/adminOrdersApi";
import { IFinishedOrder } from "common/models/order/IFinishedOrder";
import { useAppDispatch } from "app/hooks";
import { setChartData } from "features/administrator/orders/ordersSlice";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const CompletedOrderTable: FC<{
  dateFrom?: string;
  dateTo?: string;
}> = ({ dateFrom, dateTo }) => {
  const {
    data: orders,
    isLoading,
    isFetching,
    error,
  } = useGetFinishedOrdersQuery({
    date_from: getQueryDateParam(dateFrom),
    date_to: getQueryDateParam(dateTo),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orders) {
      const unicDates = [...new Set(orders.map((order) => order.date))];
      const chartData = unicDates.map((date) => ({
        date: date,
        value: orders.reduce(
          (curr, acc) => (acc.date === date ? (curr += +acc.price) : curr),
          0
        ),
      }));
      dispatch(setChartData(chartData));
    }
  }, [orders]);

  const finishedOrders: Undefinable<IFinishedOrderTableData[]> = useMemo(() => {
    if (orders) {
      return getTableData<IFinishedOrder, IFinishedOrderTableData>(
        orders,
        ["date", "vehicle_category.name", "vehicle_type.name"],
        ["count", "price", "hours"],
        {
          date: "",
          count: 0,
          hours: 0,
          vehicle_type: {
            name: "Техника",
            id: 1,
          },
          vehicle_category: {
            name: "Техника",
            id: 1,
          },
          price: 0,
          subRows: [],
        }
      );
    }
  }, [orders]);

  return (
    <QueryLayout
      isLoading={isLoading}
      error={error}
      skeleton={<SkeletonTable rowCount={6} colCount={columns.length} />}
    >
      {finishedOrders?.length ? (
        <DropdownTable
          data={finishedOrders}
          columns={columns}
          isLoading={isFetching}
        />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};

interface IFinishedOrderTableData extends IFinishedOrder {
  subRows?: IFinishedOrderTableData[];
}

const columns: Column<IFinishedOrderTableData>[] = [
  {
    Header: "Дата",
    accessor: "date",
    Cell: ({ row }: any) => (
      <>
        {row.original.date &&
          timeFormatter({
            time: new Date(row.original.date),
            mode: "dd-Mth-yyyy",
          })}
      </>
    ),
  },
  {
    Header: "Кол-во",
    accessor: "count",
  },
  {
    Header: "Вид ТС",
    accessor: "vehicle_category",
    Cell: ({ row }: any) => {
      return row.canExpand && !row.original.date ? (
        <button
          className="flex h-full w-full items-center whitespace-nowrap text-left"
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 10}px`,
            },
          })}
        >
          {row.original.vehicle_category} ({row.original.count})
          {row.isExpanded ? (
            <SvgCaretUp className="ml-[5px] h-[7px] w-[7px]" />
          ) : (
            <SvgCaretUp className="ml-[5px] h-[7px] w-[7px] rotate-180" />
          )}
        </button>
      ) : (
        <span
          className="whitespace-nowrap"
          style={{ paddingLeft: `${row.depth * 10}px` }}
        >
          {row.original.vehicle_type.length
            ? typeof row.original.vehicle_type === "string"
              ? row.original.vehicle_type
              : row.original.vehicle_category.name
            : typeof row.original.vehicle_category === "string"
            ? row.original.vehicle_category
            : row.original.vehicle_category.name}
          ({row.original.count})
        </span>
      );
    },
  },
  {
    Header: "Сумма, руб",
    accessor: "price",
  },
  {
    Header: "Кол-во часов",
    accessor: "hours",
  },
  {
    id: "expander",
    Cell: ({ row }: any) => {
      return row.canExpand && row.original.date ? (
        <button
          className="w-full pr-[7px]"
          {...row.getToggleRowExpandedProps({
            style: { paddingLeft: `${row.depth * 2}rem` },
          })}
        >
          {row.isExpanded ? (
            <SvgCaretUp className="ml-auto h-[15px] w-[15px]" />
          ) : (
            <SvgCaretUp className="ml-auto h-[15px] w-[15px] rotate-180" />
          )}
        </button>
      ) : null;
    },
  },
];
