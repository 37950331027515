import { useForm } from "react-hook-form";
import { MODAL_TYPES } from "../../utils/consts";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { useGlobalModalContext } from "./GlobalModal";
import { TextArea } from "../TextArea/TextArea";
import { getErrorMessage } from "../../utils/helpers";
import { useCreateReportMutation } from "../../../services/appApi";

export const ReportModal = () => {
  const [createReport, { isLoading: isReportSending }] =
    useCreateReportMutation();

  const { showModal, hideModal } = useGlobalModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ description: string; comment: string }>();

  const handleModalToggle = () => {
    hideModal();
  };

  const onSubmit = handleSubmit(async (data) => {
    await createReport(data)
      .unwrap()
      .then(() => {
        handleModalToggle();
        showModal(MODAL_TYPES.SUCCESS_MODAL, {
          title: "Отчёт успешно отправлен",
        });
      })
      .catch((err) => {
        handleModalToggle();
        showModal(MODAL_TYPES.ERROR_MODAL, {
          title: getErrorMessage(err)?.data.message,
          subTitle: "Ошибка при отправке отчёта, повторите попытку позже",
        });
      });
  });

  return (
    <div className="min-w-[325px] p-[25px] text-center rounded bg-white">
      <div className="mb-[25px] text-lg font-medium">Отправка разработчику</div>
      <p className="m-auto mb-[15px] text-sm font-semibold text-lightGray">
        Укажите тему и оставьте подробный комменатрий
      </p>
      <form className="grid gap-[15px] text-left" onSubmit={onSubmit}>
        <div>
          <Input
            {...register("description", { required: true })}
            className="w-full"
            invalid={!!errors.description}
            label="Описание *"
            placeholder="Описание"
            type="text"
          />
        </div>
        <div>
          <TextArea
            {...register("comment", { required: true })}
            className="h-[150px] w-full resize-none"
            invalid={!!errors.comment}
            label="Комментарий *"
            placeholder="Комментарий"
          />
        </div>
        <div className="mt-[10px] grid grid-cols-2 gap-[15px]">
          <Button
            type="button"
            className="text-base font-medium tracking-wider"
            mode="darkBorder"
            onClick={handleModalToggle}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            className="text-base font-medium tracking-wider"
            disabled={isReportSending}
          >
            Отправить
          </Button>
        </div>
      </form>
    </div>
  );
};
