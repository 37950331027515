import { FC, useMemo, useState } from "react";
import { Column } from "react-table";
import { Table } from "../../../../common/components/Table/Table";
import { SelectColumnFilter } from "../../../../common/components/Table/SelectColumnFilter";
import { Actions } from "../../../../common/components/Table/Actions";
import { useGetVehicleCategoriesQuery } from "services/vehicle/vehiclesApi";
import { IVehicleType } from "common/models/vehicles/IVehicleType";
import { QueryLayout } from "common/components/Layout/QueryLayout";
import { SkeletonTable } from "common/components/Table/SkeletonTable";
import {
  useDeleteVehicleTypeMutation,
  useGetVehicleTypesQuery,
} from "services/vehicle/vehicleTypesApi";
import { NotFoundTitle } from "common/components/Title/NotFoundTitle";

export const TariffsTable: FC = () => {
  const [selectedCategory, setSelectedCategory] =
    useState<Undefinable<number>>();

  const { data: categories } = useGetVehicleCategoriesQuery();

  const {
    data: types,
    isLoading: isTypesLoading,
    isFetching: isTypesFetching,
    error: typesError,
  } = useGetVehicleTypesQuery(
    selectedCategory ? [selectedCategory] : undefined
  );

  const categoryOptions = categories?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const columns: Column<IVehicleType>[] = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Тип техники",
        accessor: "category",
        Cell: ({ row }: any) => <div>{row.original.category.name}</div>,
        Filter: (filter) => (
          <SelectColumnFilter {...filter} options={categoryOptions} />
        ),
        filter: (rows, columnIds, filterValue) => {
          if (filterValue !== "0") {
            setSelectedCategory(filterValue);
          } else {
            setSelectedCategory(undefined);
          }
          return rows;
        },
      },
      {
        Header: "Название",
        accessor: "name",
      },
      {
        id: "actions",
        Header: (header) => (
          <span {...header} className="ml-auto block w-fit pr-[8px]">
            Действия
          </span>
        ),
        Cell: ({ row }: any) => (
          <Actions
            id={row.original.id}
            onDelete={useDeleteVehicleTypeMutation}
          />
        ),
      },
    ],
    [categories]
  );

  return (
    <QueryLayout
      skeleton={<SkeletonTable colCount={columns.length} rowCount={6} />}
      isLoading={isTypesLoading}
      error={typesError}
    >
      {types?.length ? (
        <Table data={types} columns={columns} isLoading={isTypesFetching} />
      ) : (
        <NotFoundTitle />
      )}
    </QueryLayout>
  );
};
